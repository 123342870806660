import { Button, Typography, FormControl, TextField, MenuItem, Tooltip, Autocomplete, CircularProgress } from '@mui/material';
import { useState, useEffect } from 'react';
import { useApi } from 'Service/axios';
import SearchIcon from '@mui/icons-material/Search';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';
import { BoxContainer, BoxHeader, ButtonsBox, FieldBox, FormBox } from './style';
import FilterDrawer from './Drawer';
import { toast } from 'react-toastify';
import CancelDialog from './CancelDialog';

const FormBaixa = () => {
    // ============================= INSTÂNCIAS =============================
    const api = useApi();
    // ============================= STATES =============================
    const [baixa, setBaixa] = useState({
        itemId: '',
        unidade: '',
        data: new Date().toISOString().split('T')[0],
        quantidade: 1,
        disponivel: '',
        almoxarifado: '',
        localizacao: '',
        pacienteId: '',
        centroId: '',
        loteId: '',
        observacao: '',
        medicoId: ''
    });
    const [open, setOpen] = useState(false);
    const [cancelDialogOpen, setCancelDialogOpen] = useState(false);
    const [isDelete, setIsDelete] = useState(false);
    const [hasLote, setHasLote] = useState(false);
    const [lotesItem, setLotesItem] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loadingPromise, setLoadingPromise] = useState(false);
    const [dependency, setDependency] = useState({
        itens: [],
        almoxarifados: [],
        localizacoes: [],
        unidades: [],
        saidas: [],
        pacientes: [],
        centroCusto: [],
        medicos: []
    });
    const [filtredLocations, setFiltredLocations] = useState([]);
    const [isEditing, setIsEditing] = useState(false);

    // ============================= FUNÇÕES =============================

    const handleChangeLocations = (almoxarifadoID) => {
        const filtredData = dependency.localizacoes.filter((i) => i.almoxarifado_id === almoxarifadoID);
        setFiltredLocations(filtredData);
    };

    const handleGetLotes = async (data) => {
        try {
            const lotes = await api.getLoteByItemId(data);
            setLotesItem(lotes.data);
            return lotes;
        } catch (error) {
            toast.error(`Erro: ${error.message}`);
        }
    };

    const handleChangeItem = async (newValue) => {
        const selectedItem = dependency.itens.find((item) => item.id === newValue.id);
        if (selectedItem.lote != '0') {
            setHasLote(true);
        } else {
            setHasLote(false);
        }
        handleChangeLocations(selectedItem.almoxarifado.id);
        const lotes = await handleGetLotes(selectedItem);
        setBaixa({
            ...baixa,
            itemId: selectedItem.id,
            unidade: selectedItem.unidade.id,
            almoxarifado: selectedItem.almoxarifado.id,
            localizacao: selectedItem.localizacao.id,
            disponivel: lotes?.data[0]?.quantidade_disponivel || ''
        });
    };

    const handleChangeField = async (field, value) => {
        if (field === 'localizacao') {
            const baixaPayload = { ...baixa };
            const payload = { ...baixaPayload, id: baixaPayload.itemId, almoxarifado_id: baixaPayload.almoxarifado, localizacao_id: value };
            const lotes = await handleGetLotes(payload);
            setBaixa({
                ...baixa,
                [field]: value,
                disponivel: lotes?.data[0]?.quantidade_disponivel || 0
            });
            return;
        }
        if (field === 'pacienteId') {
            const medico = await api.getMedicoPorId(value?.id_medico);
            setBaixa({
                ...baixa,
                medicoId: medico?.data?.usuario?.id,
                [field]: value?.id || ''
            });
            return;
        }
        setBaixa({ ...baixa, [field]: value });
    };

    const resetState = () => {
        setBaixa({
            itemId: '',
            unidade: '',
            data: new Date().toISOString().split('T')[0],
            quantidade: 1,
            almoxarifado: '',
            localizacao: '',
            observacao: '',
            disponivel: '',
            medicoId: ''
        });
    };

    const handleCheckDialog = (baixa) => {
        if (baixa.id) {
            setIsDelete(true);
            setCancelDialogOpen(true);
        } else {
            setCancelDialogOpen(true);
        }
    };

    const handleCloseDialog = () => {
        setCancelDialogOpen(false);
    };

    const handleCheckValues = (params) => {
        // tirar os campos não obrigatórios
        // ou montar objeto apenas com os campos necessários com os nomes que precisarem ser mudados
        // ex: itemId => item ou selectedItem => item
        const filtredObject = {
            item: params.itemId,
            unidade: params.unidade,
            data: params.data,
            quantidade: params.quantidade,
            almoxarifado: params.almoxarifado,
            localizacao: params.localizacao,
            centroDeCusto: params.centroId
        };

        let errorArr = [];
        Object.keys(filtredObject).forEach((field) => {
            if (filtredObject[field] === '' || filtredObject[field] === null) {
                let key = field.replace(field[0], field[0].toUpperCase());
                toast.error(`O campo ${key} deve ser informado`);
                errorArr.push(field);
            }
            return errorArr;
        });

        if (errorArr.length > 0) {
            return false;
        } else {
            return true;
        }
    };

    const clearLoading = () => {
        setTimeout(() => {
            setLoading(false);
        }, 1000);
    };

    // === SUBMITS / API CALLS ===

    const getDependency = async () => {
        try {
            const [item, unidade, almoxarifado, localizacao, pacientes, centroCusto, medicos] = await Promise.all([
                api.getAllItems(),
                api.getAllUnidade(),
                api.getAlmoxarifados(),
                api.getAllLocalizacoes(),
                api.getAllProntuariosIds(),
                api.getAllCentroCusto(),
                api.getAllMedicos()
            ]);

            const itensSemKit = item.data.filter((item) => !item.kit_itens);

            setDependency({
                itens: itensSemKit,
                almoxarifados: almoxarifado.data,
                localizacoes: localizacao.data,
                unidades: unidade.data,
                pacientes: pacientes.data,
                centroCusto: centroCusto.data,
                medicos: medicos.data
            });
            setLoadingPromise(true);
        } catch (error) {
            toast.error(`Erro ao bucar dados: ${error.message}`);
        }
    };

    const handelConfirmDialog = async () => {
        if (isDelete) {
            try {
                await api.deleteSaida(baixa.id);
                getDependency();
                resetState();
                setCancelDialogOpen(false);
                toast.success('Saida excluída com sucesso');
            } catch (error) {
                toast.error(`Erro: ${error.response.data.message}`);
                setCancelDialogOpen(false);
            }
        } else {
            resetState();
            setCancelDialogOpen(false);
        }
    };

    const handleCreateBaixa = async (e, baixa) => {
        try {
            e.preventDefault();
            setLoading(true);
            // caso tenha baixa.id é update
            // baixa.id === id da saida no banco
            // baixa.itemId === id do item da saida
            if (handleCheckValues(baixa)) {
                if (parseInt(baixa.quantidade) === 0) {
                    return toast.error('A quantidade deve ser maior que 0!');
                }
                if (baixa.id) {
                    await api.updateSaida(baixa.id, baixa);
                    resetState();
                    getDependency();
                    clearLoading();
                    toast.success('Baixa atualizada com sucesso');
                } else {
                    await api.createSaida(baixa);
                    resetState();
                    getDependency();
                    clearLoading();
                    toast.success('Baixa cadastrada com sucesso');
                }
            }

            clearLoading();
        } catch (error) {
            clearLoading();
            toast.error(`Erro: ${error.response.data.message}`);
        }
    };

    useEffect(() => {
        getDependency();
    }, []);

    return (
        <>
            {loadingPromise ? (
                <BoxContainer>
                    {/* COMPONENT */}
                    <FilterDrawer
                        open={open}
                        setOpen={setOpen}
                        itens={dependency.itens}
                        saidas={dependency.saidas}
                        setBaixa={setBaixa}
                        handleChangeLocations={handleChangeLocations}
                        handleGetLotes={handleGetLotes}
                        setHasLote={setHasLote}
                        setIsEditing={setIsEditing}
                    />
                    {/* DIALOG CANCELAR / EXCLUIR  */}
                    <CancelDialog
                        open={cancelDialogOpen}
                        onClose={handleCloseDialog}
                        handelConfirmDialog={handelConfirmDialog}
                        isDelete={isDelete}
                    />
                    {/* FORM */}
                    <BoxHeader>
                        <Typography sx={{ fontSize: '1.125rem', fontWeight: '500', color: '#121926' }}>Inserir nova baixa</Typography>
                        <Tooltip title="Filtrar baixa">
                            <Button variant="contained" color="warning" onClick={() => setOpen(true)}>
                                <SearchIcon />
                            </Button>
                        </Tooltip>
                    </BoxHeader>
                    <FormBox>
                        <FieldBox>
                            <FormControl fullWidth>
                                <Autocomplete
                                    options={dependency.itens}
                                    getOptionLabel={(item) => `${item.id} - ${item.descricao} - ${!item.apelido ? '' : item.apelido}`}
                                    value={dependency.itens.find((item) => item.id === baixa.itemId) || null}
                                    onChange={(e, newValue) => handleChangeItem(newValue)}
                                    renderInput={(params) => <TextField {...params} label="Item" />}
                                />
                            </FormControl>
                            <FormControl fullWidth>
                                <TextField
                                    select
                                    label="Unidade"
                                    disabled
                                    value={baixa.unidade}
                                    onChange={(e) => handleChangeField('unidade', e.target.value)}
                                >
                                    {dependency.unidades.map((unidade) => (
                                        <MenuItem key={unidade.id} value={unidade.id}>
                                            {unidade.descricao}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </FormControl>
                        </FieldBox>
                        <FieldBox>
                            <FormControl fullWidth>
                                <TextField type="date" label="Data" value={baixa.data} disabled InputLabelProps={{ shrink: true }} />
                            </FormControl>
                            <FormControl fullWidth>
                                <TextField
                                    type="number"
                                    label="Quantidade"
                                    required
                                    value={baixa?.quantidade}
                                    onChange={(e) => handleChangeField('quantidade', e.target.value)}
                                />
                            </FormControl>
                            {!hasLote && (
                                <FormControl fullWidth>
                                    <TextField
                                        type="number"
                                        label="Disponivel"
                                        value={baixa.disponivel}
                                        disabled
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </FormControl>
                            )}
                        </FieldBox>
                        <FieldBox>
                            <FormControl fullWidth>
                                <TextField
                                    select
                                    label="Almoxarifado"
                                    required
                                    value={baixa.almoxarifado}
                                    onChange={(e) => {
                                        handleChangeField('almoxarifado', e.target.value), handleChangeLocations(e.target.value);
                                    }}
                                >
                                    {dependency.almoxarifados
                                        .filter((almoxarifado) => !isEditing || almoxarifado.id == baixa.almoxarifado)
                                        .map((almoxarifado) => (
                                            <MenuItem key={almoxarifado.id} value={almoxarifado.id}>
                                                {almoxarifado.descricao}
                                            </MenuItem>
                                        ))}
                                </TextField>
                            </FormControl>
                            <FormControl fullWidth>
                                <TextField
                                    select
                                    label="Localização"
                                    value={baixa.localizacao}
                                    required
                                    onChange={(e) => {
                                        handleChangeField('localizacao', e.target.value);
                                    }}
                                >
                                    {filtredLocations
                                        .filter((localizacao) => !isEditing || localizacao.id === baixa.localizacao)
                                        .map((localizacao) => (
                                            <MenuItem key={localizacao.id} value={localizacao.id}>
                                                {localizacao.descricao}
                                            </MenuItem>
                                        ))}
                                </TextField>
                            </FormControl>
                        </FieldBox>
                        <FieldBox>
                            <FormControl fullWidth>
                                <Autocomplete
                                    options={dependency.pacientes}
                                    getOptionLabel={(paciente) => `${paciente.id} - ${paciente.paciente.nome_completo}`}
                                    value={dependency.pacientes.find((paciente) => paciente.id === baixa.pacienteId) || null}
                                    onChange={(e, newValue) => handleChangeField('pacienteId', newValue)}
                                    renderInput={(params) => <TextField {...params} label="Ficha" />}
                                />
                            </FormControl>
                            <FormControl fullWidth>
                                <TextField
                                    select
                                    label="Centro de custo"
                                    value={baixa.centroId}
                                    onChange={(e) => handleChangeField('centroId', e.target.value)}
                                >
                                    {dependency.centroCusto.map((centro) => (
                                        <MenuItem key={centro.id} value={centro.id}>
                                            {centro.descricao}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </FormControl>
                        </FieldBox>
                        {hasLote && (
                            <FieldBox>
                                <FormControl fullWidth>
                                    <Autocomplete
                                        options={dependency.medicos}
                                        getOptionLabel={(item) => `${item.id} - ${item.nome}`}
                                        value={dependency.medicos.find((item) => item.id === baixa.medicoId) || null}
                                        onChange={(e, newValue) => {
                                            if (newValue !== null && newValue?.id) {
                                                handleChangeField('medicoId', newValue.id);
                                            }
                                        }}
                                        renderInput={(params) => <TextField {...params} label="Medico" />}
                                    />
                                </FormControl>
                                <FormControl fullWidth>
                                    <TextField
                                        select
                                        label="Lote"
                                        required
                                        value={baixa.loteId}
                                        onChange={(e) => handleChangeField('loteId', e.target.value)}
                                    >
                                        {lotesItem.length > 0 ? (
                                            lotesItem
                                                .filter((lote) => parseInt(lote.quantidade_disponivel) > 0)
                                                .filter((lote) => !isEditing || lote.lote === baixa.loteId)
                                                .map((lote) => (
                                                    <MenuItem key={lote.lote} value={lote.lote}>
                                                        {`${lote.lote} = ${lote.quantidade_disponivel}`}
                                                    </MenuItem>
                                                ))
                                        ) : (
                                            <MenuItem>Sem lotes disponíveis para esta localização e almoxarifado</MenuItem>
                                        )}
                                    </TextField>
                                </FormControl>
                            </FieldBox>
                        )}
                        <FieldBox>
                            <FormControl fullWidth>
                                <TextField
                                    label="Observação"
                                    multiline
                                    minRows={5}
                                    value={baixa.observacao}
                                    onChange={(e) => handleChangeField('observacao', e.target.value)}
                                    InputLabelProps={{ shrink: true }}
                                />
                            </FormControl>
                        </FieldBox>
                    </FormBox>
                    <ButtonsBox>
                        <Tooltip title="Salvar baixa">
                            <Button variant="contained" color="success" disabled={loading} onClick={(e) => handleCreateBaixa(e, baixa)}>
                                <SaveIcon />
                            </Button>
                        </Tooltip>
                        <Tooltip title={baixa.id ? 'Excluir baixa' : 'Cancelar cadastro de baixa'}>
                            <span>
                                <Button
                                    variant="contained"
                                    color="error"
                                    disabled={baixa.itemId === ''}
                                    onClick={() => handleCheckDialog(baixa)}
                                >
                                    <DeleteIcon />
                                </Button>
                            </span>
                        </Tooltip>
                    </ButtonsBox>
                </BoxContainer>
            ) : (
                <>
                    <CircularProgress />
                </>
            )}
        </>
    );
};

export default FormBaixa;
