import { Box, Grid, IconButton, Tooltip } from '@mui/material';
import { gridSpacing } from 'store/constant';
import MainCard from 'ui-component/cards/MainCard';
import { DataGrid } from '@mui/x-data-grid';
import InventoryIcon from '@mui/icons-material/Inventory';
import { useApi } from 'Service/axios';
import { useEffect, useState } from 'react';
import { formatarData } from 'utils/formatarData';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router';

export function CustomerServiceExamesRaioxConsult() {
    const api = useApi();
    const navigate = useNavigate();
    const [rows, setRows] = useState([]);

    const columns = [
        { field: 'id', headerName: 'F.A', flex: 0.5 },
        { field: 'patient', headerName: 'Paciente', flex: 1 },
        { field: 'age', headerName: 'Idade', flex: 0.5 },
        { field: 'date', headerName: 'Data', flex: 1 },
        { field: 'hour', headerName: 'Hora', flex: 0.5 },
        { field: 'situation', headerName: 'Situação', flex: 1 },
        {
            field: 'actions',
            headerName: 'Ações',
            flex: 1,
            renderCell: (params) => (
                <Tooltip title="Detalhes">
                    <IconButton onClick={() => navigateToDetails(params.row)}>
                        <InventoryIcon />
                    </IconButton>
                </Tooltip>
            )
        }
    ];

    function navigateToDetails(data) {
        navigate(`/main/atendimento/raio-x/consulta/${data.id}/${data.patient}`);
    }

    async function getAllProntuario() {
        try {
            const { data } = await api.getAllClosedExamesRaiox();

            const row = data.map((item) => ({
                id: item?.prontuario?.id,
                patient: item?.prontuario?.paciente.nome_completo,
                age: item?.prontuario?.paciente.idade + ' anos',
                date: formatarData(item?.prontuario?.data_atendimento),
                hour: item?.prontuario?.hora_atendimento,
                situation: item?.exames_raio_x?.finalizado ? 'FINALIZADO' : 'AGUARDANDO',
                createdAt: item?.createdAt
            }));
            setRows(row);
        } catch (error) {
            toast.error(error?.reponse?.data?.message ?? error?.message);
        }
    }

    useEffect(() => {
        getAllProntuario();
    }, []);

    return (
        <MainCard title="CONSULTA RAIO-X">
            <Grid spacing={gridSpacing} sx={{ position: 'relative' }}>
                <Box style={{ width: '100%' }}>
                    <DataGrid rows={rows} columns={columns} hideFooter={true} />
                </Box>
            </Grid>
        </MainCard>
    );
}
