import { TableCell, styled } from '@mui/material';
import { Box, Typography, Button } from '@mui/material';

// ===================HEADER===================
export const HeaderBox = styled(Box)({
    display: 'flex',
    paddingLeft: '1em',
    marginRight: 'auto',
    gap: '1.5em',
    borderBottom: '1px solid #000'
});

export const HeaderInfo = styled(Typography)({
    fontSize: '.87em'
});

export const InfoBox = styled(Box)({
    display: 'flex',
    justifyContent: 'space-between'
});
