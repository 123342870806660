import { Button, Typography, Box, InputLabel, FormControl, OutlinedInput } from '@mui/material';
import InputMask from 'react-input-mask';
import { formatDate } from 'utils/formatDate';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import { MainBox, FormBox, Label, Input, SecondaryBox, SecondaryFormBox } from './style';
import { width } from '@mui/system';
import formater from 'utils/DateDDMMYYY';

const ModalProntuarioStyled = ({ paciente }) => {
    const [isCampoHabilitado, setCampoHabilitado] = useState(false);
    return (
        <fieldset style={{ minWidth: '40%', borderRadius: '7%', borderColor: '#c9a86f', padding: '1em' }}>
            <legend style={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="h2" sx={{ color: '#c9a86f' }}>
                    Resumo
                </Typography>
            </legend>

            <MainBox>
                <FormBox>
                    <Label htmlFor="nome">Nome Completo</Label>
                    <Input type="text" id="nome" value={paciente.nome_completo} disabled />
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Box sx={{ width: '45%' }}>
                            <Label htmlFor="nomeSocial">Nome Social</Label>
                            <Input type="text" id="nomeSocial" value={paciente.nome_social} disabled />
                        </Box>
                        <Box sx={{ width: '45%' }}>
                            <Label htmlFor="data">Data de Nascimento</Label>
                            <Input type="text" id="data" value={formater(paciente?.data_nascimento)} disabled />
                        </Box>
                    </Box>
                    <div
                        style={{
                            display: 'flex',
                            gap: '1em',
                            minWidth: '100%'
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                width: '45%'
                            }}
                        >
                            <Label htmlFor="cpf">CPF</Label>
                            <InputMask mask="999.999.999-99" disabled={!isCampoHabilitado} value={paciente.cpf}>
                                {(inputProps) => <Input id="cpf" type="text" name="cpf" disabled inputProps={inputProps} />}
                            </InputMask>
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                marginLeft: 'auto',
                                width: '45%'
                            }}
                        >
                            <Label htmlFor="rg">RG</Label>
                            <InputMask mask="99.999.999-9" disabled={!isCampoHabilitado} value={paciente.rg}>
                                {(inputProps) => <Input id="rg" type="text" name="rg" disabled inputProps={inputProps} />}
                            </InputMask>
                        </div>
                    </div>
                    <Label htmlFor="sus">Cartão SUS</Label>
                    <InputMask mask="999.9999.9999.9999" disabled={!isCampoHabilitado} value={paciente.cartao_sus}>
                        {(inputProps) => <Input id="sus" type="text" name="sus" disabled inputProps={inputProps} />}
                    </InputMask>
                </FormBox>

                <SecondaryBox>
                    <SecondaryFormBox>
                        <Label htmlFor="logradouro">Tipo Logradouro</Label>
                        <Input label="Tipo Logradouro" type="text" id="logradouro" value={paciente.tipo_logradouro} disabled />
                    </SecondaryFormBox>

                    <SecondaryFormBox>
                        <Label htmlFor="endereco">Endereço</Label>
                        <Input label="Endereço" type="text" id="endereco" value={paciente.endereco} disabled />
                    </SecondaryFormBox>
                </SecondaryBox>
                <SecondaryBox>
                    <SecondaryFormBox>
                        <Label htmlFor="bairro">Bairro</Label>
                        <Input label="Bairro" type="text" id="bairro" value={paciente.bairro} disabled />
                    </SecondaryFormBox>

                    <SecondaryFormBox>
                        <Label htmlFor="numero">Número</Label>
                        <Input label="Número" type="text" id="numero" value={paciente.numero} disabled />
                    </SecondaryFormBox>
                </SecondaryBox>
            </MainBox>
        </fieldset>
    );
};

export default ModalProntuarioStyled;
