import { useNavigate } from 'react-router-dom';
import MainCard from 'ui-component/cards/MainCard';
import { Button, Grid, Box, Typography } from '@mui/material';
import { gridSpacing } from 'store/constant';
import { useState } from 'react';
import { useEffect } from 'react';
import { useApi } from 'Service/axios';
import { TableBox } from './style';
import { toast } from 'react-toastify';
import { DataGrid } from '@mui/x-data-grid';
import ActionButtonsTabela from 'components/Estoque/Entrada/ActionButtonTabela';

const EntradaEstoque = () => {
    // ============================= INSTÂNCIAS =============================
    const api = useApi();
    const navigate = useNavigate();
    // ============================= STATES =============================
    const [allEntrada, setAllEntrada] = useState([]);
    const columns = [
        { field: 'id', headerName: 'ID', flex: 0.5 },
        { field: 'numero_documento', headerName: 'N° Nota', flex: 2 },
        { field: 'fornecedor', headerName: 'Fornecedor', flex: 2, valueGetter: (params) => params.row.fornecedor.nome_fantasia },
        {
            field: 'acao',
            headerName: 'AÇÕES',
            flex: 1,
            sortable: false,
            renderCell: (params) => <ActionButtonsTabela row={params.row} getEntradas={getEntradas} />
        }
    ];
    // ============================= FUNÇÕES =============================

    const handleSelectionModelChange = (newSelection) => {
        setSelectedItems(newSelection);
    };

    const handleNavigate = () => {
        navigate('/main/estoque/entrada/cadastro');
    };

    const getEntradas = async () => {
        try {
            const response = await api.getAllEntradasEstoques();
            setAllEntrada(response.data);
        } catch (error) {
            toast.error(error.response.data.mensagem);
        }
    };

    useEffect(() => {
        getEntradas();
    }, []);

    return (
        <MainCard>
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginBottom: '2em'
                }}
            >
                <Typography sx={{ fontSize: '1.125rem', fontWeight: '500' }}>ENTRADA</Typography>
                <Button variant="contained" size="medium" onClick={handleNavigate} sx={{ height: '40px' }}>
                    Adicionar
                </Button>
            </Box>
            <Grid container spacing={gridSpacing}>
                <TableBox>
                    <DataGrid rows={allEntrada} columns={columns} onSelectionModelChange={() => handleSelectionModelChange(params.id)} />
                </TableBox>
            </Grid>
        </MainCard>
    );
};

export default EntradaEstoque;
