import { Box, Typography, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper } from '@mui/material';
import {
    ExameInfoBox,
    HalfBox,
    HeaderBox,
    HeaderBoxSecondary,
    HeaderInfoBox,
    InfoBox,
    InfoBoxMedium,
    InfoBoxMediumEnd,
    TableBox
} from './style';
import { useEffect, useState, useContext } from 'react';
import { FormBox } from './style';
import { useApi } from 'Service/axios';
import { toast } from 'react-toastify';
import { ConjuntoLaudoContext } from 'context/LaudoContext';
import formateDate from 'utils/formatDate-1';
import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';

const ConjuntoLaudos = () => {
    // ============================= INSTÂNCIAS =============================
    const api = useApi();
    const { laudoPayload } = useContext(ConjuntoLaudoContext);
    // ============================= STATES =============================
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const styles = {
        page: {
            backgroundColor: '#fff',
            fontSize: 10,
            fontWeight: 'normal'
        },
        header: {
            padding: 10,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between'
        },
        image: {
            width: 200,
            backgroundSize: 'cover'
        },
        text1: {
            fontSize: '12px'
        },
        box: {
            border: '1px solid #ddd',
            padding: 10,
            margin: 2,
            borderRadius: 5
        },
        between: {
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row',
            justifyContent: 'space-between'
        },
        labelArea: {
            backgroundColor: '#ccc',
            padding: '8px',
            borderRadius: 5
        },
        table: { margin: 10 },
        row: { flexDirection: 'row', borderBottom: '1 solid #000' },
        cell: { flexGrow: 1, padding: 5 }
    };
    // ============================= FUNÇÕES =============================

    const formatarData = (data) => {
        const dataFormatada = new Date(data);
        const dataFormatadaString = dataFormatada.toLocaleDateString();
        const horaFormatadaString = dataFormatada.toLocaleTimeString();
        return dataFormatadaString + ' ' + horaFormatadaString;
    };
    const getLaudoData = async (data) => {
        try {
            const response = await api.getResultsForLaudos(data);

            const agruparDados = response.data.map((el) => {
                const resultadoCampoEspecifico = el.resultado_campo_especifico;
                const data = formatarData(el?.medico?.data_execucao);

                // Use o método reduce para agrupar os campos por categoria
                const camposAgrupados = resultadoCampoEspecifico.reduce((agrupados, item) => {
                    const categoria = item.campos_especifico.categoria;

                    // Se a categoria já existir no objeto agrupados, adicione o item a ela
                    if (agrupados[categoria]) {
                        agrupados[categoria].push(item);
                    } else {
                        // Se a categoria ainda não existir, crie um novo array com o item
                        agrupados[categoria] = [item];
                    }

                    return agrupados;
                }, {});
                for (const categoria in camposAgrupados) {
                    camposAgrupados[categoria].sort((a, b) => a.id - b.id);
                }
                return {
                    ...el,
                    data,
                    camposAgrupados
                };
            });

            const parsedData = laudoPayload.map(async (item) => {
                let observacao = await api.getObservation(
                    item.prontuarioId,
                    item.especial ? null : item.exameId,
                    item.especial ? item.exameId : null,
                    item.idExameProntuario
                );
                return observacao;
            });

            agruparDados.map((item) => ({ ...item, parsedData }));

            setData(response.data);

            setTimeout(() => {
                setLoading(false);
            }, 1000);
        } catch (error) {
            toast.error(`Erro ao buscar dados: ${error.message}`);
        }
    };

    if (!loading && data !== null) {
        window.print();
    }

    useEffect(() => {
        getLaudoData(laudoPayload);
    }, []);

    return (
        <>
            {data !== null && (
                <FormBox>
                    <Box>
                        <Box sx={{ padding: '.8em', display: 'flex', justifyContent: 'space-between' }}>
                            <InfoBox>
                                <Typography variant="h5">Paciente:</Typography>
                                <Typography>{data[0]?.paciente?.paciente?.nome_completo}</Typography>
                            </InfoBox>
                            <InfoBox>
                                <Typography variant="h5">Data Nascimento:</Typography>
                                <Typography>{formateDate(data[0]?.paciente?.paciente?.data_nascimento)}</Typography>
                            </InfoBox>
                            <InfoBox>
                                <Typography variant="h5">Data Entrada:</Typography>
                                <Typography>{formatarData(data[0]?.paciente?.data_atendimento)}</Typography>
                            </InfoBox>
                            <InfoBox>
                                <Typography variant="h5">F.A:</Typography>
                                <Typography>{data[0]?.resultado_campo_especifico[0]?.id_prontuario}</Typography>
                            </InfoBox>
                        </Box>
                        <TableBox>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center" sx={{ fontSize: '.83em' }}>
                                                <b>EXAME</b>
                                            </TableCell>
                                            <TableCell align="center" sx={{ fontSize: '.83em' }}>
                                                <b>CAMPO</b>
                                            </TableCell>
                                            <TableCell align="center" sx={{ fontSize: '.83em' }}>
                                                <b>RESULTADO</b>
                                            </TableCell>
                                            <TableCell align="center" sx={{ fontSize: '.83em' }}>
                                                <b>UNIDADE</b>
                                            </TableCell>
                                            <TableCell align="center" sx={{ fontSize: '.83em' }}>
                                                <b>VALORES REFERÊNCIA</b>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {data.map((data) => (
                                            <>
                                                {/* DADOS */}
                                                <TableRow sx={{ padding: '0' }}>
                                                    <TableCell
                                                        align="center"
                                                        sx={{
                                                            maxWidth: 100,
                                                            display: 'flex',
                                                            alignItems: 'baseline',
                                                            justifyContent: 'center',
                                                            textAlign: 'center',
                                                            fontSize: '.9em',
                                                            border: 'none',
                                                            padding: '4px',
                                                            fontWeight: 'bold',
                                                            textOverflow: 'ellipsis'
                                                        }}
                                                    >
                                                        {data?.exame?.nome}
                                                    </TableCell>
                                                    <TableCell align="center" sx={{ border: 'none', padding: '4px' }}>
                                                        {data?.resultado_campo_especifico.map((item) => (
                                                            <Box>
                                                                <Typography sx={{ fontSize: '.7em', fontWeight: 'bold' }}>
                                                                    {item?.campos_especifico?.campo_nome}
                                                                </Typography>
                                                            </Box>
                                                        ))}
                                                    </TableCell>
                                                    <TableCell align="center" sx={{ border: 'none', padding: '4px' }}>
                                                        {data?.resultado_campo_especifico.map((item) => (
                                                            <Box>
                                                                {data?.exame?.nome?.includes('HEMOGRAMA') &&
                                                                item?.campos_especifico?.calculo ? (
                                                                    <Typography sx={{ fontSize: '.7em', fontWeight: 'bold' }}>
                                                                        {item.porcentagem}% - {item.valor}
                                                                    </Typography>
                                                                ) : (
                                                                    <Typography sx={{ fontSize: '.7em', fontWeight: 'bold' }}>
                                                                        {item.valor}
                                                                    </Typography>
                                                                )}
                                                            </Box>
                                                        ))}
                                                    </TableCell>
                                                    <TableCell align="center" sx={{ border: 'none', padding: '4px' }}>
                                                        {data?.resultado_campo_especifico.map((item) => (
                                                            <Box>
                                                                <Typography sx={{ fontSize: '.7em', fontWeight: 'bold' }}>
                                                                    {item?.campos_especifico?.unidade || '-'}
                                                                </Typography>
                                                            </Box>
                                                        ))}
                                                    </TableCell>
                                                    <TableCell align="center" sx={{ border: 'none', padding: '4px' }}>
                                                        {data?.resultado_campo_especifico.map((item) => (
                                                            <Box>
                                                                <Typography sx={{ fontSize: '.7em', fontWeight: 'bold' }}>
                                                                    {item?.campos_especifico?.valores}
                                                                </Typography>
                                                            </Box>
                                                        ))}
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow sx={{ padding: '0', borderBottom: 'none' }}>
                                                    <TableCell
                                                        align="center"
                                                        sx={{
                                                            fontSize: '.6em',
                                                            border: 'none',
                                                            padding: '4px',
                                                            display: 'flex',
                                                            flexDirection: 'column'
                                                        }}
                                                    >
                                                        <span> MATERIAL: {data?.exame?.material}</span>
                                                        <span>MÉTODO: {data?.exame?.metodo}</span>
                                                    </TableCell>
                                                </TableRow>
                                                {/* OBSERVAÇÃO */}
                                                <TableRow sx={{ padding: '0' }}>
                                                    <TableCell align="center" sx={{ fontSize: '.8em', padding: '4px' }}>
                                                        Observação: {data?.observacao?.observation ?? ''}
                                                    </TableCell>
                                                    <TableCell align="center" sx={{ fontSize: '.7em', padding: '4px' }}></TableCell>
                                                    <TableCell align="center" sx={{ fontSize: '.7em', padding: '4px' }}></TableCell>
                                                    <TableCell align="center" sx={{ fontSize: '.7em', padding: '4px' }}></TableCell>
                                                    <TableCell align="center" sx={{ fontSize: '.7em', padding: '4px' }}>
                                                        {formatarData(data?.medico?.data_execucao)}
                                                    </TableCell>
                                                </TableRow>
                                            </>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </TableBox>
                    </Box>
                    <Box
                        sx={{
                            width: '30%',
                            borderTop: '1px solid #000',
                            display: 'flex',
                            justifyContent: 'center',
                            marginLeft: 'auto',
                            marginTop: '6em'
                        }}
                    >
                        <Typography variant="h4">Responsável</Typography>
                    </Box>
                </FormBox>
            )}
        </>
    );
};

export default ConjuntoLaudos;
