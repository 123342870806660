import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useApi } from 'Service/axios';
import React from 'react';
import { toast } from 'react-toastify';

const DialogInativar = ({ open, handleClose, atendimentoId, setChamadaAcionada }) => {
    const api = useApi();
    const handleInativar = async (id) => {
        try {
            const response = await api.updateStatusProntuarioAusente(id);
            if (response.status == 200) {
                setChamadaAcionada(true);
                handleClose();
                toast.success('Prontuário inativado com sucesso');
            }
        } catch (error) {
            toast.error(`Erro inesperado ao inativar prontuário: ${error.message}`);
        }
    };

    return (
        <Dialog open={open} handleClose={handleClose}>
            <DialogTitle>
                <Typography variant="h4">Atenção</Typography>
            </DialogTitle>
            <DialogContent>
                <Typography>Ao continuar o atendimento será inativado e retirado da fila</Typography>
            </DialogContent>
            <DialogActions>
                <Box sx={{ display: 'flex', gap: '1rem' }}>
                    <Button variant="contained" color="error" onClick={handleClose}>
                        Voltar
                    </Button>
                    <Button variant="contained" onClick={() => handleInativar(atendimentoId)}>
                        Confirmar
                    </Button>
                </Box>
            </DialogActions>
        </Dialog>
    );
};

export default DialogInativar;
