import { Box, Grid, IconButton, Tooltip } from '@mui/material';
import { gridSpacing } from 'store/constant';
import MainCard from 'ui-component/cards/MainCard';
import { DataGrid } from '@mui/x-data-grid';
import InventoryIcon from '@mui/icons-material/Inventory';
import { useEffect, useState } from 'react';
import { useApi } from 'Service/axios';
import { formatarData } from 'utils/formatarData';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';

export function ConsultServicesLaboratory() {
    const api = useApi();
    const navigate = useNavigate();
    const [rows, setRows] = useState([]);

    const columns = [
        { field: 'id', headerName: 'F.A', flex: 0.5 },
        { field: 'patient', headerName: 'Paciente', flex: 1 },
        { field: 'age', headerName: 'Idade', flex: 0.5 },
        { field: 'date', headerName: 'Data', flex: 1 },
        { field: 'hour', headerName: 'Hora', flex: 0.5 },
        {
            field: 'actions',
            headerName: 'Ações',
            flex: 1,
            renderCell: (params) => (
                <Tooltip title="Detalhes">
                    <IconButton
                        onClick={() => {
                            navigateToExamDetails(params.row.id, params.row.patient);
                        }}
                    >
                        <InventoryIcon />
                    </IconButton>
                </Tooltip>
            )
        }
    ];

    function navigateToExamDetails(id, nome) {
        navigate(`/main/laboratorio/controle/atendimentos/consulta/${id}/${nome}`);
    }

    async function getAllProntuario() {
        try {
            const { data } = await api.getAllClosedExamsLab();

            const row = data.map((item) => ({
                id: item?.prontuario_id,
                patient: item?.exame_prontuario?.prontuario?.paciente.nome_completo,
                age: item?.exame_prontuario?.prontuario?.paciente.idade + ' anos',
                date: formatarData(item?.createdAt.split('T')[0]),
                hour: item?.createdAt.split('T')[1].split('.')[0],
                situation: item?.exame_prontuario?.finalizado ? 'LAUDADO' : 'AGUARDANDO'
            }));
            setRows(row);
        } catch (error) {
            toast.error(error?.response?.data?.message ?? error?.message);
        }
    }

    useEffect(() => {
        if (rows.length === 0) {
            getAllProntuario();
        }
    }, []);

    return (
        <MainCard title="CONSULTAR ATENDIMENTOS">
            <Grid spacing={gridSpacing} sx={{ position: 'relative' }}>
                <Box sx={{ width: '100%' }}>
                    <DataGrid rows={rows} columns={columns} hideFooter={true} />
                </Box>
            </Grid>
        </MainCard>
    );
}
