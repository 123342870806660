import MainCard from 'ui-component/cards/MainCard';
import { Grid } from '@mui/material';
import { gridSpacing } from 'store/constant';
import { useParams } from 'react-router-dom';
import { Box } from '@mui/system';
import FormEditExame from 'components/Laboratório/Exames/FormEdit';

const EditarExame = () => {
    // ============================= INSTÂNCIAS =============================
    const { id } = useParams();

    return (
        <MainCard title="Editar Exame">
            <Grid container spacing={gridSpacing}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        gap: '1rem',
                        width: '100%',
                        padding: '1.6em',
                        marginTop: '1rem'
                    }}
                >
                    <FormEditExame idExame={id} />
                </Box>
            </Grid>
        </MainCard>
    );
};

export default EditarExame;
