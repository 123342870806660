import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { HeaderArea, RegisterArea, TriagemArea } from './styles';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 900,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 2,
    borderRadius: 2
};

export function AnalysesModal({ open, setOpen }) {
    const handleClose = () => setOpen(false);

    return (
        <div>
            <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box sx={style}>
                    <HeaderArea>
                        <h2>Raio X - FA: 86754 - WELLINGTON MARQUES MENDONÇA</h2>
                        <Box>
                            <p>
                                PRONTUÁRIO: <b>7852</b>
                            </p>
                            <p>
                                FICHA DE ATENDIMENTO: <b>58782</b>
                            </p>
                            <p>
                                DATA: <b>23/04/2024</b>
                            </p>
                            <p>
                                IDADE: <b>38 ANOS</b>
                            </p>
                        </Box>
                    </HeaderArea>

                    <TriagemArea>
                        <Box className="title-triagem">
                            <p>ESCUTA INICIAL</p>
                            <p>
                                RESPONSÁVEL: <b>FERBABDA SULVA</b>
                            </p>
                        </Box>

                        <Box className="patient-data">
                            <p>
                                HORA: <b>17:11</b>
                            </p>
                            <p>
                                P.A: <b>120 X 80</b>
                            </p>
                            <p>
                                F.C: <b>79</b>
                            </p>
                            <p>
                                F.R: <b>18</b>
                            </p>
                            <p>
                                DEXTRO: <b>0</b>
                            </p>
                        </Box>
                        <Box className="patient-data">
                            <p>
                                PESO: <b>84,6</b>
                            </p>
                            <p>
                                ALTURA: <b>1.72</b>
                            </p>
                            <p>
                                IMC: <b>29,62</b>
                            </p>
                            <p>
                                TEMPERATURA: <b>36,3</b>
                            </p>
                            <p>
                                SATURAÇÃO: <b>95%</b>
                            </p>
                        </Box>

                        <Box className="footer-triagem">
                            <p>
                                MEDICAÇÃO EM USO: <b>NEGA</b>
                            </p>
                            <p>
                                ALERGIA: <b>NEGA</b>
                            </p>
                        </Box>
                    </TriagemArea>

                    <RegisterArea>
                        <Box className="item-area">
                            <h3>FICHA MÉDICA</h3>
                            <p>
                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Commodi omnis autem ab doloremque odit totam,
                                repellat molestiae vitae natus! Ullam quae ex fugiat illo, saepe repudiandae amet. Consequuntur, fugit
                                voluptatem.
                            </p>
                        </Box>

                        <Box className="item-area">
                            <h3>CONDUTA MÉDICA</h3>
                            <p>
                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Commodi omnis autem ab doloremque odit totam,
                                repellat molestiae vitae natus! Ullam quae ex fugiat illo, saepe repudiandae amet. Consequuntur, fugit
                                voluptatem.
                            </p>
                        </Box>

                        <Box className="hypothesi-area">
                            <h3>hipótese diagnóstica</h3>
                            <Box>
                                <span>PRINCIPAL</span>
                                <span>SECUNDÁRIA</span>
                            </Box>
                            <Box>
                                <span>HD 3</span>
                                <span>HD 4</span>
                            </Box>
                        </Box>
                    </RegisterArea>
                </Box>
            </Modal>
        </div>
    );
}
