import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { Box } from '@mui/system';

const DialogEstornoTotal = ({ open, handleCloseDialog, handleEstornoTotal }) => {
    return (
        <Dialog open={open}>
            <DialogTitle>Atenção</DialogTitle>
            <DialogContent>
                <Typography>Com a conclusão do estorno total, o saldo de todos os itens será restabelecido no estoque</Typography>
            </DialogContent>
            <DialogActions>
                <Box sx={{ display: 'flex', gap: '1rem' }}>
                    <Button variant="contained" color="error" onClick={handleCloseDialog}>
                        Cancelar
                    </Button>
                    <Button variant="contained" onClick={handleEstornoTotal}>
                        Confirmar
                    </Button>
                </Box>
            </DialogActions>
        </Dialog>
    );
};

export default DialogEstornoTotal;
