import MainCard from 'ui-component/cards/MainCard';
import { Grid, TablePagination, Box } from '@mui/material';
import { gridSpacing } from 'store/constant';
import { useApi } from 'Service/axios';
import { useEffect, useState } from 'react';
import { MainBox, TableBox } from '../style';
import TabelaTriagem from 'components/Laboratório/TabelaTriagem';
import TempoMedio from 'components/Triagem/Cards/TempoMedio';
import TempoEspera from 'components/Triagem/Cards/TempoEspera';
import Aguardando from 'components/Triagem/Cards/Aguardando';
import { useContext } from 'react';
import { ChamadaContext } from 'context/ChamadasContext';

const TriagemLab = () => {
    const { atendimentos, quantidadeAguardando } = useContext(ChamadaContext);
    // ============================= STATES =============================
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [waitTime, setWaitTime] = useState('');
    const [averageWaitTime, setAverageWaitTime] = useState('');

    // ============================= FUNÇÕES =============================

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    // CALCULO DE TEMPO DE ESPERA
    useEffect(() => {
        if (atendimentos.length > 0) {
            const calculateWaitTime = () => {
                const recordDate = new Date(atendimentos[0]?.data_atendimento);
                const currentDate = new Date();
                const timeDifference = currentDate.getTime() - recordDate.getTime();
                const waitTimeInSeconds = Math.floor(timeDifference / 1000);

                const hours = Math.floor(waitTimeInSeconds / 3600);
                const minutes = Math.floor((waitTimeInSeconds % 3600) / 60);
                const seconds = waitTimeInSeconds % 60;

                setWaitTime(`${hours}h ${minutes}m ${seconds}s`);
            };

            const intervalId = setInterval(calculateWaitTime, 1000);

            return () => clearInterval(intervalId);
        }
    }, [atendimentos]);

    // CALCULO DE TEMPO MEDIO
    useEffect(() => {
        if (atendimentos.length > 0) {
            const calculateAverageWaitTime = () => {
                const currentDate = new Date();
                const totalWaitTimeInSeconds = atendimentos.reduce((accumulator, record) => {
                    const recordDate = new Date(record.data_atendimento);
                    const timeDifference = currentDate.getTime() - recordDate.getTime();
                    return accumulator + Math.floor(timeDifference / 1000);
                }, 0);

                const averageWaitTimeInSeconds = totalWaitTimeInSeconds / atendimentos.length;
                const hours = Math.floor(averageWaitTimeInSeconds / 3600);
                const minutes = Math.floor((averageWaitTimeInSeconds % 3600) / 60);
                const seconds = Math.floor(averageWaitTimeInSeconds % 60);

                setAverageWaitTime(`${hours}h ${minutes}m ${seconds}s`);
            };

            const intervalId = setInterval(calculateAverageWaitTime, 1000);

            return () => clearInterval(intervalId);
        }
    }, [atendimentos]);

    return (
        <MainCard title="ESCUTA INICIAL">
            <Grid container spacing={gridSpacing}>
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        alignSelf: 'flex-end',
                        justifyContent: 'flex-end',
                        gap: '1rem',
                        marginTop: '1rem'
                    }}
                >
                    <TempoMedio tempoMedio={averageWaitTime} />
                    <TempoEspera tempoEspera={waitTime} />
                    <Aguardando aguardandoQTD={quantidadeAguardando} />
                </Box>
                <MainBox>
                    <TableBox>
                        <TabelaTriagem allFichas={atendimentos} page={page} rowsPerPage={rowsPerPage} />
                        <TablePagination
                            rowsPerPageOptions={[10, 25, 100]}
                            component="div"
                            count={atendimentos.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </TableBox>
                </MainBox>
            </Grid>
        </MainCard>
    );
};

export default TriagemLab;
