import { Button, Grid, IconButton, Tooltip } from '@mui/material';
import { IconEdit, IconTrash } from '@tabler/icons';
import { gridSpacing } from 'store/constant';
import MainCard from 'ui-component/cards/MainCard';
import { InsertRooms } from './components/InsertRooms';
import { Box } from '@mui/system';
import { useApi } from 'Service/axios';
import { useEffect } from 'react';
import { useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';

export function Rooms() {
    const api = useApi();
    const [rooms, setRooms] = useState([]);
    const [selectedRoom, setSelectedRoom] = useState(null);
    const [openInsertRoom, setOpenInsertRoom] = useState(false);

    const columns = [
        { field: 'id', headerName: 'ID', flex: 0.5 },
        { field: 'descricao', headerName: 'Descrição', flex: 1 },
        {
            field: 'actions',
            headerName: 'Ações',
            flex: 0.3,
            renderCell: (params) => (
                <>
                    <Tooltip title="Editar sala">
                        <IconButton
                            onClick={() => {
                                setSelectedRoom(params.row);
                                setOpenInsertRoom(true);
                            }}
                        >
                            <IconEdit />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Deletar sala">
                        <IconButton onClick={() => deleteRoom(params.row.id)}>
                            <IconTrash />
                        </IconButton>
                    </Tooltip>
                </>
            )
        }
    ];

    async function getAllRooms() {
        try {
            const { data } = await api.getRooms();
            console.log('🚀 ~ getAllRooms ~ data:', data);
            setRooms(data);
        } catch (error) {
            console.log('🚀 ~ getAllRooms ~ error:', error);
        }
    }

    async function deleteRoom(id) {
        try {
            await api.deleteRoom(id);

            getAllRooms();
        } catch (error) {
            console.log('🚀 ~ deleteRoom ~ error:', error);
        }
    }

    useEffect(() => {
        getAllRooms();
    }, []);

    return (
        <MainCard title="SALAS DE ESPERA">
            <Grid spacing={gridSpacing} sx={{ position: 'relative' }}>
                <InsertRooms getAllRooms={getAllRooms} selectedRoom={selectedRoom} open={openInsertRoom} setOpen={setOpenInsertRoom} />
                <Box sx={{ position: 'absolute', top: '-90px', right: '0' }}>
                    <Button variant="contained" onClick={() => setOpenInsertRoom(true)}>
                        Criar Sala
                    </Button>
                </Box>
                {rooms.length > 0 && (
                    <Box sx={{ marginTop: '1rem' }}>
                        <DataGrid rows={rooms} columns={columns} hideFooter />
                    </Box>
                )}
            </Grid>
        </MainCard>
    );
}
