import { useApi } from 'Service/axios';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Tooltip } from '@mui/material';
import { useState } from 'react';
import { Box } from '@mui/system';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { toast } from 'react-toastify';
import EditCentroCusto from '../EditCentroCusto';

const ActionButtons = ({ row, getAllCentroCusto }) => {
    // ===== INSTANCIAS =====
    const api = useApi();
    // ===== STATES =====
    const [openDialog, setOpenDialog] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    // ===== FUNÇÕES =====

    const handleClose = () => {
        setOpenEdit(false);
    };

    const handleDeleteEntrada = async (centroId) => {
        try {
            await api.deleteCentroCusto(centroId);
            await getAllCentroCusto();
            setOpenDialog(false);
            toast.success('Centro deletado com sucesso');
        } catch (error) {
            toast.error(error.response.data.message);
        }
    };

    return (
        <Box>
            <EditCentroCusto openEdit={openEdit} handleClose={handleClose} getAllCentroCusto={getAllCentroCusto} centro={row} />
            <Dialog open={openDialog}>
                <DialogTitle>Atenção</DialogTitle>
                <DialogContent>Tem certeza que deseja excluir este centro de custo?</DialogContent>
                <DialogActions>
                    <Button color="error" onClick={() => setOpenDialog(false)}>
                        Cancelar
                    </Button>
                    <Button variant="contained" onClick={() => handleDeleteEntrada(row.id)}>
                        Confirmar
                    </Button>
                </DialogActions>
            </Dialog>
            <Tooltip title="Editar centro">
                <Button onClick={() => setOpenEdit(true)}>
                    <EditIcon />
                </Button>
            </Tooltip>
            <Tooltip title="Remover centro">
                <Button onClick={() => setOpenDialog(true)}>
                    <DeleteIcon />
                </Button>
            </Tooltip>
        </Box>
    );
};

export default ActionButtons;
