import { Box, Button, Checkbox, Grid, IconButton, Menu, Tooltip } from '@mui/material';
import { gridSpacing } from 'store/constant';
import MainCard from 'ui-component/cards/MainCard';
import { DataGrid } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { useApi } from 'Service/axios';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import { formatarData } from 'utils/formatarData';
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';
import PrintIcon from '@mui/icons-material/Print';
import { PDFDownloadLink } from '@react-pdf/renderer';
import ConjuntoLaudosPDF from 'views/Relatorios/PDF/ConjuntoLaudos';
import { useContext } from 'react';
import { ConjuntoLaudoContext } from 'context/LaudoContext';

export function ConsultExamsLab() {
    const { id, nome } = useParams();
    const api = useApi();
    const [rows, setRows] = useState([]);
    const { laudoPayload, setLaudoPayload, setFichaBancadaPayload } = useContext(ConjuntoLaudoContext);
    const [checked, setChecked] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const [data, setData] = useState(null);
    const columns = [
        {
            field: 'check',
            headerName: 'SEL',
            flex: 0.2,
            renderCell: (params) => (
                <Checkbox
                    checked={checked.some((checkedItem) => checkedItem.id === params.row.id)}
                    onChange={(event) => handleChange(event, params.row)}
                    inputProps={{ 'aria-label': 'controlled' }}
                />
            )
        },
        { field: 'id', headerName: 'ID', flex: 0.2 },
        { field: 'exame', headerName: 'EXAME', flex: 1 },
        { field: 'date', headerName: 'DATA', flex: 1 },
        { field: 'hour', headerName: 'HORA', flex: 1 },
        {
            field: 'actions',
            headerName: 'AÇÕES',
            flex: 1,
            renderCell: (params) => (
                <Tooltip title="Impressão única">
                    <IconButton onClick={() => handlePrintResult(params.row)}>
                        <PrintIcon />
                    </IconButton>
                </Tooltip>
            )
        }
    ];

    function handleChange(event, item) {
        if (event.target.checked) {
            setChecked((prevState) => [...prevState, item]);
        } else {
            setChecked((prevState) => prevState.filter((checkedItem) => checkedItem.id !== item.id));
        }
    }

    async function handleLaudoHoraUnica(laudoUnico) {
        try {
            const payload = checked.map((item) => ({
                idExameProntuario: item.id,
                exameId: item?.especial ? null : item?.exameId,
                exameEspecialId: item?.especial ? item?.exameId : null,
                prontuarioId: item?.prontuarioId,
                especial: item?.especial
            }));
            await api.getHoraUnicaLaudo(laudoUnico ? laudoUnico : payload);
            await buildLaudoData();
        } catch (error) {
            toast.error(error?.response?.data?.message ?? error?.message);
        }
    }

    async function getLaudoData(laudoPayload) {
        try {
            const response = await api.getResultsForLaudos(laudoPayload);

            const agruparDados = response.data.map((el) => {
                const resultadoCampoEspecifico = el.resultado_campo_especifico;
                const data = formatarData(el?.medico?.data_execucao);

                // Use o método reduce para agrupar os campos por categoria
                const camposAgrupados = resultadoCampoEspecifico.reduce((agrupados, item) => {
                    const categoria = item.campos_especifico.categoria;

                    // Se a categoria já existir no objeto agrupados, adicione o item a ela
                    if (agrupados[categoria]) {
                        agrupados[categoria].push(item);
                    } else {
                        // Se a categoria ainda não existir, crie um novo array com o item
                        agrupados[categoria] = [item];
                    }

                    return agrupados;
                }, {});
                for (const categoria in camposAgrupados) {
                    camposAgrupados[categoria].sort((a, b) => a.id - b.id);
                }
                return {
                    ...el,
                    data,
                    camposAgrupados
                };
            });

            const ordenarExames = (a, b) => {
                const aNome = a.exame.nome.toLowerCase();
                const bNome = b.exame.nome.toLowerCase();

                if (aNome.includes('hemograma') || aNome.includes('urina')) {
                    return -1;
                } else if (bNome.includes('hemograma') || bNome.includes('urina')) {
                    return 1;
                }

                return 0;
            };

            response.data.sort(ordenarExames);

            const parsedData = laudoPayload.map(async (item) => {
                let observacao = await api.getObservation(
                    item.prontuarioId,
                    item.especial ? null : item.exameId,
                    item.especial ? item.exameId : null,
                    item.idExameProntuario
                );
                return observacao;
            });

            agruparDados.map((item) => ({ ...item, parsedData }));

            setData(response.data);
        } catch (error) {
            toast.error(`Erro ao buscar dados: ${error.message}`);
        }
    }

    async function buildLaudoData() {
        try {
            const dataSource = checked.length > 0 ? checked : rows;

            const payload = dataSource.map((item) => ({
                idExameProntuario: item.id,
                exameId: item?.especial ? null : item?.exameId,
                exameEspecialId: item?.especial ? item?.exameId : null,
                prontuarioId: item?.prontuarioId,
                especial: item?.especial
            }));

            setLaudoPayload(payload);
            await getLaudoData(payload);
        } catch (error) {
            toast.error(error?.response?.data?.message ?? error?.message);
        }
    }

    function handleClick(event) {
        setAnchorEl(event.currentTarget);
    }

    function handleClose() {
        setAnchorEl(null);
    }

    async function handlePrintResult(data) {
        await handleLaudoHoraUnica([
            {
                idExameProntuario: data.id,
                exameId: !data?.especial ? data?.exameId : null,
                exameEspecialId: data?.especial ? data?.exameId : null,
                prontuarioId: data.prontuarioId,
                especial: data?.especial
            }
        ]);

        window.open(`/laboratorio/resultado/${data.prontuarioId}/${data.exameId}/${data.id}`);
    }
    async function getAllItens(id) {
        try {
            const { data } = await api.getAllClosedExamsLabByProntuarioId(id);
            const row = data.map((item) => ({
                id: item?.exame_prontuario?.id,
                prontuarioId: item?.exame_prontuario?.prontuario?.id,
                pacienteId: item?.exame_prontuario?.prontuario?.paciente?.id,
                exame: item?.exame_prontuario?.exame?.nome ?? item?.exame_prontuario?.exame_especiai?.nome,
                exameId: item?.exame_prontuario?.exame_especiai?.id ?? item?.exame_prontuario?.exame?.id,
                date: formatarData(item?.createdAt.split('T')[0]),
                hour: item?.createdAt.split('T')[1].split('.')[0],
                status: item?.exame_prontuario?.finalizado ? 'FINALIZADO' : 'AGUARDANDO',
                especial: item?.exame_prontuario?.exame_especiai ? true : false,
                tipo: item?.exame_prontuario?.exame_especiai ? 'especial' : 'normal'
            }));

            setRows(row);
        } catch (error) {
            toast.error(error?.response?.data?.message ?? error?.message);
        }
    }

    useEffect(() => {
        getAllItens(id);
    }, []);

    return (
        <MainCard title={`FA ${id} - ${nome}`}>
            <Grid spacing={gridSpacing} sx={{ position: 'relative' }}>
                <Box sx={{ width: '100%' }}>
                    <DataGrid rows={rows} columns={columns} hideFooter={true} />
                </Box>
                <Box sx={{ width: '100%', display: 'flex', gap: '1rem', justifyContent: 'flex-end', marginTop: '1rem' }}>
                    <Tooltip title="Impimir Exames">
                        <Button
                            variant="contained"
                            onClick={(e) => {
                                handleClick(e), handleLaudoHoraUnica();
                            }}
                        >
                            <SimCardDownloadIcon />
                        </Button>
                    </Tooltip>
                    <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button'
                        }}
                    >
                        <Button>
                            <PDFDownloadLink document={<ConjuntoLaudosPDF data={data} />} fileName="Laudo.pdf">
                                {({ blob, url, loading, error }) => (loading ? 'Carregando' : 'Gerar PDF')}
                            </PDFDownloadLink>
                        </Button>
                    </Menu>
                </Box>
            </Grid>
        </MainCard>
    );
}
