import { Button, Divider, Modal, TextField, Tooltip, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { useState } from 'react';
import formater from 'utils/DateDDMMYYY';
import PrintIcon from '@mui/icons-material/Print';
import { toast } from 'react-toastify';
import { useApi } from 'Service/axios';
import PdfAtestado from './component/PdfAtestado';
import { PDFViewer } from '@react-pdf/renderer';
import { useParams } from 'react-router';
import { DoctorsSignatureConfirmationModal } from 'components/DoctorsSignatureConfirmationModal';

export function Atestado({ open, handleClose, paciente, data, setTriggerReload }) {
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 900,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 2,
        borderRadius: 2
    };
    const api = useApi();
    const [diasAtestado, setDiasAtestado] = useState(null);
    const [openModalPdf, setOpenModalPdf] = useState(false);
    const [dataPdf, setDataPdf] = useState(null);
    const [observation, setObservation] = useState('');
    const { prontuarioId } = useParams();
    const [assinaturaId, setAssinaturaId] = useState(null);
    async function navigateToPrint(nome, nascimento, data, dias) {
        setDataPdf({ nome, nascimento, data, dias, observation });
        try {
            await api.createAtestado({
                dias: dias,
                pacienteId: paciente.id,
                prontuarioId: prontuarioId,
                observacao: observation,
                assinatura_id: assinaturaId
            });
            setTriggerReload(true);
            setDiasAtestado(null);

            setOpenModalPdf(true);
            setObservation('');
            setDiasAtestado(null);
        } catch (error) {
            return toast.error('Erro ao gerar atestado');
        }
    }

    const stylePdf = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 1100,
        bgcolor: 'background.paper',
        boxShadow: 24,
        height: '90vh'
    };

    return (
        <Box>
            <Modal open={open}>
                <Box sx={style}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography variant="h3">
                            <b>ATESTADO - {paciente?.nome_completo}</b>
                        </Typography>
                        <TextField
                            type="number"
                            label="Dias atestado"
                            value={diasAtestado}
                            onChange={(e) => setDiasAtestado(e.target.value)}
                        />
                    </Box>
                    <Box sx={{ border: '1px solid #ccc', borderRadius: '4px', marginTop: '1rem', padding: '1rem' }}>
                        <Typography variant="h2" sx={{ textAlign: 'center' }}>
                            ATESTADO MÉDICO
                        </Typography>
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '.4rem', marginTop: '1rem' }}>
                            <Typography variant="h4">DADOS DO PACIENTE</Typography>
                            <Typography variant="h5">NOME: {paciente?.nome_completo}</Typography>
                            <Typography variant="h5">NASCIMENTO: {formater(paciente?.data_nascimento)}</Typography>
                            <Typography variant="h5">ATENDIMENTO: {data}</Typography>
                        </Box>
                        <Divider sx={{ width: '100%', margin: '2rem 0 2rem 0' }} />
                        <Box sx={{ textAlign: 'center' }}>
                            ATESTO PARA FINS DE TRABALHO QUE O(A) PACIENTE ACIMA ESTEVE EM CONSULTA MÉDICA NO DIA DE HOJE E NECESSITA
                            PERMANECER AFASTADO(A) DE SUAS ATIVIDADES LABORATIVAS PELO PERÍODO DE
                            {diasAtestado > 0 ? ` ${diasAtestado}` : ` " #### "`} DIAS A CONTAR DESTA DATA.
                        </Box>
                        <Box>
                            <TextField
                                value={observation}
                                onChange={(e) => setObservation(e.target.value)}
                                label="Observação adicional"
                                variant="standard"
                                fullWidth
                                sx={{ marginTop: '10px' }}
                            />
                        </Box>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: '1rem', marginTop: '1rem' }}>
                        <Button
                            variant="contained"
                            color="error"
                            onClick={() => {
                                setDiasAtestado(null);
                                handleClose();
                            }}
                        >
                            CANCELAR
                        </Button>

                        <DoctorsSignatureConfirmationModal
                            buttonText="Imprimir"
                            confirmFunction={() =>
                                navigateToPrint(paciente?.nome_completo, formater(paciente?.data_nascimento), data, diasAtestado)
                            }
                            setAssinaturaId={setAssinaturaId}
                        />

                        <Modal
                            open={openModalPdf}
                            onClose={() => {
                                setOpenModalPdf(false);
                                handleClose();
                            }}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >
                            <Box sx={stylePdf}>
                                <PDFViewer width={'100%'} height={'100%'}>
                                    <PdfAtestado data={dataPdf} />
                                </PDFViewer>
                            </Box>
                        </Modal>
                    </Box>
                </Box>
            </Modal>
        </Box>
    );
}
