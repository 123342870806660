import { TableCell, styled } from '@mui/material';
import { Box, Typography, Button } from '@mui/material';

// components
export const HeaderBox = styled(Box)({
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '1em 1em 0em 1em',
    marginLeft: '.7em'
});

export const TableBox = styled(Box)({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '1em',
    padding: '2em',
    height: '580px'
});

export const OrderButton = styled(TableCell)({
    cursor: 'pointer',
    transition: 'all .15s ease',

    b: {
        marginRight: '1em'
    },
    '&:hover': {
        backgroundColor: 'rgba(39, 157, 245, 0.52)'
    }
});

export const OrderNameButton = styled(TableCell)({
    cursor: 'pointer',
    transition: 'all .15s ease',

    b: {
        marginRight: '1em'
    },
    '&:hover': {
        backgroundColor: 'rgba(39, 157, 245, 0.52)'
    }
});
