import { Button, FormControl, FormControlLabel, Modal, Radio, RadioGroup, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import { useApi } from 'Service/axios';

const MedicalFinalizationModal = ({ open, setOpenFinalizationModal, prontuarioId }) => {
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 900,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 2,
        borderRadius: 2
    };
    const navigate = useNavigate();
    const api = useApi();
    const [value, setValue] = useState('');
    const [observation, setObservation] = useState('');

    function handleChange(event) {
        setValue(event.target.value);
    }

    function handleClose() {
        setValue('');
        setOpenFinalizationModal(false);
    }

    // FUNÇÃO RESPONSÁVEL POR MAPEAR O VALUE RECEBIDO COMO STRING DO INPUT RADIO E MONTAR O PAYLOAD TRUE/FALSE
    function getTypeFinalization(value, observacao) {
        const defaultPayload = {
            alta: false,
            retorno_medicacao_exame: false,
            alta_medicacao_procedimento_cuidado: false,
            encaminhar_internacao: false,
            observacao: observacao
        };

        const valueMapping = {
            alta: 'alta',
            retorno_medicacao_exame: 'retorno_medicacao_exame',
            alta_medicacao_procedimento_cuidado: 'alta_medicacao_procedimento_cuidado',
            encaminhar_internacao: 'encaminhar_internacao'
        };

        if (valueMapping[value]) {
            return {
                ...defaultPayload,
                [valueMapping[value]]: true
            };
        }

        return defaultPayload;
    }

    async function submitFinalization(prontuarioId, value, observacao) {
        try {
            // Extrair o payload true/false
            const payload = getTypeFinalization(value, observacao);
            await api.finalizarConduta(prontuarioId, payload);
            handleClose();
            navigate('/main/consultorio/atendimento');
            toast.success('Sucesso ao finalizar conduta');
        } catch (error) {
            toast.error(error?.response?.data?.message ?? error?.message);
        }
    }

    return (
        <Box>
            <Modal open={open}>
                <Box sx={style}>
                    <Typography variant="h2" sx={{ textAlign: 'center' }}>
                        TIPO DE FINALIZAÇÃO DA CONDUTA
                    </Typography>
                    <Box
                        sx={{
                            padding: '10px',
                            marginTop: '20px',
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '.7rem',
                            borderRadius: '7px',
                            border: '1px solid #ccc'
                        }}
                    >
                        <FormControl>
                            <RadioGroup value={value} onChange={handleChange}>
                                <FormControlLabel value="alta" control={<Radio />} label="ALTA" />
                                <FormControlLabel
                                    value="retorno_medicacao_exame"
                                    control={<Radio />}
                                    label="RETORNO APÓS MEDICAÇÃO/EXAME"
                                />
                                <FormControlLabel
                                    value="alta_medicacao_procedimento_cuidado"
                                    control={<Radio />}
                                    label="ALTA APÓS MEDICAÇÃO/PROCEDIMENTO/CUIDADO"
                                />
                                <FormControlLabel value="encaminhar_internacao" control={<Radio />} label="ENCAMINHAR PARA INTERNAÇÃO" />
                            </RadioGroup>
                        </FormControl>
                        <TextField
                            multiline
                            label="Observação"
                            minRows={4}
                            value={observation}
                            onChange={(e) => setObservation(e.target.value)}
                        />
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: '1rem', marginTop: '1rem' }}>
                        <Button variant="contained" color="error" onClick={handleClose}>
                            Cancelar
                        </Button>
                        <Button variant="contained" onClick={() => submitFinalization(prontuarioId, value, observation)}>
                            Confirmar
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </Box>
    );
};

export default MedicalFinalizationModal;
