import styled from '@emotion/styled';
import { Box } from '@mui/material';

export const InputArea = styled.form`
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;
    margin-top: 10px;
`;
