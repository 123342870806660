import { useRoutes } from 'react-router-dom';

// routes
import MainRoutes from './MainRoutes';
import LoginRoutes from './LoginRoute';
import RegisterRoute from './RegisterRoute';
import FirstLoginRoutes from './FirstLogin';
import RelatoriosRoutes from './RelatoriosRoutes';
import LaboratorioRoutes from './LaboratiorioRoutes';
import TriagemRoutes from './TriagemRoutes';
import ChamadasRoutes from './ChamadasRoutes';
import NursingChamadas from './NurginChamadas';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
    return useRoutes([
        MainRoutes,
        LoginRoutes,
        FirstLoginRoutes,
        RegisterRoute,
        RelatoriosRoutes,
        LaboratorioRoutes,
        TriagemRoutes,
        ChamadasRoutes,
        NursingChamadas
    ]);
}
