import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useApi } from 'Service/axios';
import SantaCasaLogo from '../../../../assets/images/Logos/logo2.png';

const RelatorioEstoqueGeral = () => {
    // ---- INSTÂNCIAS ----
    const { value, id } = useParams();

    const api = useApi();
    // ---- STATES ----
    const [movSumarizado, setMovSumarizado] = useState([]);
    const [loading, setLoading] = useState(true);
    // ---- FUNÇÕES ----

    const getMovSumarizado = async (id) => {
        try {
            /*  const apiFunctions = {
                todos: api.getMovSumarizados,
                psicotropicos: api.getPsicotropicos,
                abertos: api.getAbertos
            };

            const apiFunction = apiFunctions[id] || api.getMovSumarizadosByProductId; */
            if (value === 'todos') {
                let response = await api.getProdutoAlmoxarifadoById(id);
                setMovSumarizado(response.data);
            }
            if (value === 'psicotropicos') {
                let response = await api.getProdutoPsicotropico(id, 1);

                setMovSumarizado(response.data);
            }
            if (value === 'abertos') {
                let response = await api.getProdutoPsicotropico(id, 0);

                setMovSumarizado(response.data);
            }
            /*  
            else {
                
            }
 */
            setTimeout(() => {
                setLoading(false);
            }, 1000);
        } catch (error) {
            toast.error(`Erro ao buscar dados: ${error.message}`);
        }
    };

    if (!loading) window.print();

    useEffect(() => {
        getMovSumarizado(id);
    }, [id]);

    return (
        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            <Typography variant="h3">RELATÓRIO DE ESTOQUE GERAL</Typography>
            <Typography>FILTRO: {id}</Typography>
            <Box sx={{ width: '100%' }}>
                <TableContainer component={Paper}>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <b>CÓDIGO</b>
                                </TableCell>
                                <TableCell>
                                    <b>DESCRIÇÃO</b>
                                </TableCell>
                                <TableCell>
                                    <b>UN</b>
                                </TableCell>
                                <TableCell>
                                    <b>ALM</b>
                                </TableCell>
                                <TableCell>
                                    <b>LOC</b>
                                </TableCell>
                                <TableCell>
                                    <b>LOTE</b>
                                </TableCell>
                                <TableCell>
                                    <b>QUANTIDADE</b>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {movSumarizado.map((element, index) => {
                                return (
                                    element.quantidade_disponivel > 0 && (
                                        <TableRow key={index}>
                                            <TableCell sx={{ fontSize: '.87em', lineHeight: '.5em' }}>{element.item_id}</TableCell>
                                            <TableCell sx={{ fontSize: '.87em', lineHeight: '.5em' }}>{element.item.descricao}</TableCell>
                                            <TableCell sx={{ fontSize: '.87em', lineHeight: '.5em' }}>
                                                {element.item.unidade.descricao}
                                            </TableCell>
                                            <TableCell sx={{ fontSize: '.87em', lineHeight: '.5em' }}>
                                                {element.almoxarifado.descricao}
                                            </TableCell>
                                            <TableCell sx={{ fontSize: '.87em', lineHeight: '.5em' }}>
                                                {element.localizacao.descricao}
                                            </TableCell>
                                            <TableCell sx={{ fontSize: '.87em', lineHeight: '.5em' }}>
                                                {element.lote === '0' ? 'N/A' : element.lote}
                                            </TableCell>
                                            <TableCell sx={{ fontSize: '.87em', lineHeight: '.5em' }}>
                                                {element.quantidade_disponivel}
                                            </TableCell>
                                        </TableRow>
                                    )
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        padding: '.5em',
                        justifyContent: 'center',
                        alignItems: 'center',
                        borderTop: '1px solid #000'
                    }}
                >
                    <img src={SantaCasaLogo} alt="Santa Casa Logotipo" style={{ width: '90px' }} />
                    <Box>
                        <Typography>SANTA CASA DE MISERICÓRDIA DE JOANÓPOLIS</Typography>
                        <Typography>RUA FRANCISCO WOLHERS N°57 - CENTRO - JOANÓPOLIS - SP</Typography>
                        <Typography>CEP: 12980-000 - TELEFONE: (11) 4539-9333</Typography>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default RelatorioEstoqueGeral;
