import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { TextField } from '@mui/material';
import { useApi } from 'Service/axios';
import { useState } from 'react';
import { useEffect } from 'react';
import { toast } from 'react-toastify';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: 1
};

export function EditRayX({ open, setOpen, infos, get }) {
    const handleClose = () => setOpen(false);

    const [observation, setObservation] = useState('');
    const api = useApi();

    useEffect(() => {
        setObservation(infos?.observacao);
    }, [infos]);

    async function handleSubmit(e) {
        e.preventDefault();
        const toastId = toast.loading('Salvando...');
        const payload = {
            prontuarioId: infos?.prontuario?.id,
            tipoRaioX: infos?.item_conduta?.tipos_raio_x?.id,
            usuarioId: infos?.usuario?.id,
            observacao: observation
        };
        try {
            await api.updateConductRayx(infos.id, payload);
            toast.update(toastId, { render: 'Salvo com sucesso!', type: 'success', isLoading: false, autoClose: 2000 });
            get();
            setOpen(false);
        } catch (error) {
            toast.update(toastId, { render: 'Erro ao salvar!', type: 'error', isLoading: false, autoClose: 2000 });
        }
    }

    return (
        <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <Box sx={style}>
                <h3 style={{ textAlign: 'center' }}>Editar Raio-x</h3>
                <Button variant="contained" sx={{ margin: '15px 0' }}>
                    {infos.item_conduta?.tipos_raio_x?.descricao}
                </Button>
                <form onSubmit={handleSubmit}>
                    <TextField
                        label="Observação"
                        fullWidth
                        value={observation}
                        multiline
                        rows={4}
                        onChange={(e) => setObservation(e.target.value)}
                    />
                    <Button variant="contained" type="submit" fullWidth sx={{ marginTop: '20px' }}>
                        Editar
                    </Button>
                </form>
            </Box>
        </Modal>
    );
}
