import { element } from 'prop-types';
import ChamadasPainel from 'views/Chamadas/Painel';
import ChamadasPainel2 from 'views/Chamadas/Painel/painelChamadas2';
import DoctorPainel from 'views/DoctorsOffice/Painel';
import NursingPainel from 'views/enfermagem/Painel';
import FarmaciaPainel from 'views/Estoque/Painel';
import LabPainel from 'views/Laboratorio/Painel';
import ProcedimentoPainel from 'views/procedimentos/Painel';
import RayxPainel from 'views/X-ray/Painel';

const { default: ChamadasLayout } = require('layout/ChamadasLayout');

const ChamadasRoutes = {
    path: '/chamadas',
    element: <ChamadasLayout />,
    children: [
        { path: '/chamadas/painel', element: <ChamadasPainel /> },
        { path: '/chamadas/painel2', element: <ChamadasPainel2 /> },
        {
            path: '/chamadas/consultorio/painel',
            element: <DoctorPainel />
        },
        {
            path: '/chamadas/atendimento/raio-x/painel',
            element: <RayxPainel />
        },
        {
            path: '/chamadas/procedimentos/painel',
            element: <ProcedimentoPainel />
        },
        {
            path: '/chamadas/farmacia/painel',
            element: <FarmaciaPainel />
        },
        {
            path: '/chamadas/laboratorio/painel',
            element: <LabPainel />
        }
    ]
};

export default ChamadasRoutes;
