import { formatarData } from 'utils/formatarData';
import { FormBox, Input, Label, MainBox } from './style';
import InputMask from 'react-input-mask';
import { Box } from '@mui/system';
import { useState } from 'react';
import { TextField, Typography } from '@mui/material';

const ResumoFichaLab = ({ data }) => {
    // ============================= STATES =============================
    const [isCampoHabilitado, setIsCampoHabilitado] = useState(false);
    return (
        <fieldset style={{ minWidth: '40%', borderRadius: '7%', borderColor: '#c9a86f', padding: '1em' }}>
            <legend style={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="h2" sx={{ color: '#c9a86f' }}>
                    Resumo
                </Typography>
            </legend>

            <MainBox>
                <FormBox>
                    <Label htmlFor="nome">Nome Completo</Label>
                    <Input type="text" id="nome" value={data.paciente.nome_completo} disabled />
                    <Label htmlFor="nome">Nome Social</Label>
                    <Input type="text" id="nome" value={data.paciente.nome_social} disabled />
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Box sx={{ width: '45%' }}>
                            <Label htmlFor="nome">Número Paciente</Label>
                            <Input type="text" id="nome" value={data.paciente.id} disabled />
                        </Box>
                        <Box sx={{ width: '45%' }}>
                            <Label htmlFor="nomeSocial">Número Atendimento</Label>
                            <Input type="text" id="nomeSocial" value={data.id} disabled />
                        </Box>
                    </Box>
                    <Box>
                        <Label htmlFor="dataNasc">Data de Nascimento</Label>
                        <Input type="text" id="dataNasc" value={formatarData(data.paciente.data_nascimento)} disabled />
                    </Box>
                    <Label htmlFor="sus">Cartão SUS</Label>
                    <InputMask mask="999.9999.9999.9999" disabled={!isCampoHabilitado} value={data.paciente.cartao_sus}>
                        {(inputProps) => <Input id="sus" type="text" name="sus" disabled inputProps={inputProps} />}
                    </InputMask>
                    <Label htmlFor="sus">Convenio</Label>
                    <TextField value={data?.paciente?.convenio?.descricao} disabled fullWidth />
                </FormBox>
            </MainBox>
        </fieldset>
    );
};

export default ResumoFichaLab;
