import PainelTriagem from 'views/PainelTriagem';

const { default: TriagemLayout } = require('layout/TriagemLayout');

const TriagemRoutes = {
    path: '/escuta',
    element: <TriagemLayout />,
    children: [
        {
            path: '/escuta/painel',
            element: <PainelTriagem />
        }
    ]
};

export default TriagemRoutes;
