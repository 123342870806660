import MainCard from 'ui-component/cards/MainCard';
import { Grid, TablePagination, Box, Typography } from '@mui/material';
import { gridSpacing } from 'store/constant';
import { useApi } from 'Service/axios';
import { useEffect, useState } from 'react';
import { MainBox, TableBox } from '../style';
import TabelaTriagem from 'components/Laboratório/TabelaTriagem';
import TempoMedio from 'components/Triagem/Cards/TempoMedio';
import TempoEspera from 'components/Triagem/Cards/TempoEspera';
import Aguardando from 'components/Triagem/Cards/Aguardando';
import TabelaTriagemConsulta from 'components/Laboratório/TabelaTriagem copy';

const ConsultaLab = () => {
    const api = useApi();
    // ============================= STATES =============================
    const [allFichas, setAllFichas] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [selectedFicha, setSelectedFicha] = useState(null);

    // ============================= FUNÇÕES =============================

    const getFichaTriagem = async () => {
        const { data } = await api.getTriagemConsulta();

        setAllFichas(data);
        setQuantidadeAguardando(data.length);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    useEffect(() => {
        getFichaTriagem();
    }, []);

    return (
        <MainCard>
            <Box
                title="CONSULTA"
                sx={{
                    marginBottom: '15px',
                    display: 'flex',

                    gap: '30px',
                    paddingTop: '20px',
                    paddingBottom: '20px',
                    width: '98%',
                    borderBottom: '1px solid #ccc'
                }}
            >
                <Typography variant="h4" fontSize={19} fontWeight={500} paddingTop={'10px'} marginRight={'48%'}>
                    Consulta
                </Typography>
            </Box>
            <Grid container spacing={gridSpacing}>
                <MainBox>
                    <TableBox>
                        <TabelaTriagemConsulta
                            allFichas={allFichas}
                            setAllFichas={setAllFichas}
                            page={page}
                            rowsPerPage={rowsPerPage}
                            selectedFicha={selectedFicha}
                        />
                        <TablePagination
                            rowsPerPageOptions={[25, 50, 100]}
                            component="div"
                            count={allFichas.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </TableBox>
                </MainBox>
            </Grid>
        </MainCard>
    );
};

export default ConsultaLab;
