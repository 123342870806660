import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { IconPill, IconPlus } from '@tabler/icons';
import { Divider, Fade, FormControlLabel, Switch, TextField } from '@mui/material';
import { useMemo } from 'react';
import { useCallback } from 'react';
import { useApi } from 'Service/axios';
import { PDFViewer } from '@react-pdf/renderer';
import PdfReceita from './PdfReceita';
import { toast } from 'react-toastify';
import { DoctorsSignatureConfirmationModal } from 'components/DoctorsSignatureConfirmationModal';
import { useParams } from 'react-router';
import { TramRounded } from '@mui/icons-material';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 900,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: 2,
    overflow: 'auto'
};

export default function MedicalPrescription({ paciente, triagem, setTriggerReload }) {
    const { prontuarioId } = useParams();
    const api = useApi();
    const repId = localStorage.getItem('id');
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [prescription, setPrescription] = React.useState([{ description: '' }]);
    const [specialControl, setSpecialControl] = React.useState(false);
    const [openModalPdf, setOpenModalPdf] = React.useState(false);
    const [pdfData, setPdfData] = React.useState({});
    const [assinaturaId, setAssinaturaId] = React.useState(null);

    const addPrescription = useCallback(() => {
        setPrescription((prev) => [...prev, { description: '' }]);
    }, []);

    async function handlePrescription() {
        const prescriptionArray = prescription.map((item) => item.description);
        const dataToSave = {
            prescricao: prescriptionArray,
            controle_especial: specialControl,
            responsavel_id: repId,
            paciente_id: paciente?.id,
            prontuario_id: prontuarioId,
            assinatura_id: assinaturaId
        };
        setPdfData({ ...dataToSave, paciente, triagem });

        const toastId = toast.loading('Salvando receita...');
        try {
            await api.createReceita(dataToSave);
            setOpenModalPdf(true);
            toast.update(toastId, {
                render: 'Receita salva com sucesso!',
                type: toast.TYPE.SUCCESS,
                isLoading: false,
                autoClose: 3000
            });
            setTriggerReload(true);

            setPrescription([{ description: '' }]);
        } catch (error) {
            toast.update(toastId, {
                render: 'Erro ao salvar receita.',
                type: toast.TYPE.ERROR,
                isLoading: false,
                autoClose: 3000
            });
            console.log('🚀 ~ handlePrescription ~ error:', error);
        }
    }

    const stylePdf = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 1100,
        bgcolor: 'background.paper',
        boxShadow: 24,
        height: '90vh'
    };

    const getCurrentDateTime = () => {
        const now = new Date();
        const date = now.toLocaleDateString('pt-BR'); // Formato DD/MM/YYYY
        const time = now.toLocaleTimeString('pt-BR', { hour: '2-digit', minute: '2-digit' }); // Formato HH:MM
        return { date, time };
    };

    const { date, time } = getCurrentDateTime();

    return (
        <div>
            <Button onClick={handleOpen} variant="contained">
                <IconPill />
            </Button>
            <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box sx={style}>
                    <h3 style={{ textAlign: 'center' }}>RECEITA MÉDICA</h3>

                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Box>
                            <p>{paciente?.nome_completo}</p>
                            <p>{`DATA/HORA DE ATENDIMENTO: ${date} ${time}`}</p>
                            <p>
                                {`
                          ${paciente?.endereco} 
                          ${paciente?.numero ? ', ' + paciente.numero : ''}  
                          ${paciente?.bairro ? ', ' + paciente.bairro : ''}  
                          ${paciente?.cidade ? ', ' + paciente.cidade : ''} 
                          ${paciente?.estado ? ', ' + paciente.estado : ''} 
                          ${paciente?.cep ? ', ' + paciente.cep : ''}
                          `}
                            </p>
                        </Box>
                        <Box>
                            <FormControlLabel
                                control={<Switch onChange={(e) => setSpecialControl(e.target.checked)} />}
                                label="Controle especial"
                            />
                        </Box>
                    </Box>
                    {prescription.map((item, index) => (
                        <Box sx={{ display: 'flex', gap: '12px', alignItems: 'center', marginTop: '10px' }}>
                            <TextField
                                variant="standard"
                                fullWidth
                                label="PRESCREVA"
                                value={item.description}
                                onChange={(e) => {
                                    const newPrescriptions = [...prescription];
                                    newPrescriptions[index].description = e.target.value;
                                    setPrescription(newPrescriptions);
                                }}
                            />
                            <Button variant="outlined" onClick={addPrescription} sx={{ marginBottom: '-10px' }}>
                                <IconPlus />
                            </Button>
                        </Box>
                    ))}

                    <Modal
                        open={openModalPdf}
                        onClose={() => {
                            setOpenModalPdf(false);
                            handleClose();
                        }}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={stylePdf}>
                            <PDFViewer width={'100%'} height={'100%'}>
                                <PdfReceita data={pdfData} />
                            </PDFViewer>
                        </Box>
                    </Modal>
                    <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                        <DoctorsSignatureConfirmationModal
                            buttonText="Prescrever"
                            confirmFunction={handlePrescription}
                            setAssinaturaId={setAssinaturaId}
                        />
                    </Box>
                </Box>
            </Modal>
        </div>
    );
}
