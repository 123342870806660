import { useParams, Link } from 'react-router-dom';
import MainCard from 'ui-component/cards/MainCard';
import { Button, Grid, Box, TablePagination, Autocomplete, Tooltip, TextField } from '@mui/material';
import { gridSpacing } from 'store/constant';
import { useState } from 'react';
import { useEffect } from 'react';
import { useApi } from 'Service/axios';
import { HeaderBox, TableBox } from './style';
import AlmoxarifadoTabela from 'components/Estoque/Almoxarifado/Tabela';
import DrawerCreate from 'components/Estoque/Almoxarifado/DrawerCreate';
import DrawerEditAlmoxarifado from 'components/Estoque/Almoxarifado/DrawerEdit';

const Almoxarifado = () => {
    // ============================= INSTÂNCIAS =============================
    const api = useApi();
    // ============================= STATES =============================
    const [allAlmoxarifados, setAllAlmoxarifados] = useState([]);
    const [selectedAlmoxarifado, setSelectedAlmoxarifado] = useState(null);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [open, setOpen] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [almoxarifadoId, setAlmoxarifadoId] = useState(null);
    const [almoxarifado, setAlmoxarifado] = useState(null);
    // ============================= FUNÇÕES =============================

    const handleClickOpenEdit = (e, id, item) => {
        e.preventDefault();
        setAlmoxarifadoId(id);
        setAlmoxarifado(item);
        setOpenEdit(true);
    };

    const handleCloseEdit = () => {
        setOpenEdit(false);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const getAlmoxarifados = async () => {
        const response = await api.getAlmoxarifados();
        setAllAlmoxarifados(response.data);
    };

    useEffect(() => {
        getAlmoxarifados();
    }, []);

    return (
        <MainCard title="ALMOXARIFADO">
            <Grid container spacing={gridSpacing}>
                <DrawerCreate open={open} handleClose={handleClose} getAlmoxarifados={getAlmoxarifados} />
                <DrawerEditAlmoxarifado
                    open={openEdit}
                    handleClose={handleCloseEdit}
                    getAlmoxarifados={getAlmoxarifados}
                    item={almoxarifado}
                />
                <HeaderBox>
                    <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={allAlmoxarifados}
                        getOptionLabel={(almoxarifado) => almoxarifado.descricao}
                        value={selectedAlmoxarifado}
                        onChange={(event, newValue) => {
                            setSelectedAlmoxarifado(newValue);
                        }}
                        sx={{ width: 300 }}
                        renderInput={(params) => <TextField {...params} label="Pesquisar Almoxarifado" />}
                    />
                    <Button variant="contained" size="medium" onClick={() => setOpen(true)} sx={{ height: '40px' }}>
                        Adicionar
                    </Button>
                </HeaderBox>

                <TableBox>
                    <AlmoxarifadoTabela
                        allAlmoxarifados={allAlmoxarifados}
                        setAllAlmoxarifados={setAllAlmoxarifados}
                        selectedAlmoxarifado={selectedAlmoxarifado}
                        page={page}
                        rowsPerPage={rowsPerPage}
                        getAlmoxarifados={getAlmoxarifados}
                        handleClickOpenEdit={handleClickOpenEdit}
                    />
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={allAlmoxarifados.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </TableBox>
            </Grid>
        </MainCard>
    );
};

export default Almoxarifado;
