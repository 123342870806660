import MainCard from 'ui-component/cards/MainCard';
import { Grid, Box, CircularProgress } from '@mui/material';
import { gridSpacing } from 'store/constant';
import FormBaixa from 'components/Estoque/Movimentos/Baixa';

const BaixaManual = () => {
    return (
        <MainCard title="BAIXA MANUAL">
            <Grid container spacing={gridSpacing}>
                <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                    <FormBaixa />
                </Box>
            </Grid>
        </MainCard>
    );
};

export default BaixaManual;
