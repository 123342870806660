import MainCard from 'ui-component/cards/MainCard';
import { Grid } from '@mui/material';
import { gridSpacing } from 'store/constant';
import { Box } from '@mui/system';
import FormNovoExame from 'components/Laboratório/Exames/FormCreate';

const NovoExame = () => {
    return (
        <MainCard title="Novo Exame">
            <Grid container spacing={gridSpacing}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        gap: '1rem',
                        width: '100%',
                        padding: '1.6em',
                        marginTop: '1rem'
                    }}
                >
                    <FormNovoExame />
                </Box>
            </Grid>
        </MainCard>
    );
};

export default NovoExame;
