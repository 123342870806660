const formater = (date) => {
    const dataObj = new Date(date);
    let dia = dataObj.getDate() + 1;
    const mes = dataObj.getMonth() + 1;
    const ano = dataObj.getFullYear();
    const diaFormatado = dia === 32 ? (dia = '01') : dia < 10 ? '0' + dia : dia;
    const mesFormatado = mes < 10 ? '0' + mes : mes;
    return (date = `${diaFormatado}/${mesFormatado}/${ano}`);
};

module.exports = formater;
