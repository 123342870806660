import { lazy } from 'react';

import Register from 'views/pages/authentication/authentication3/Register3';

const RegisterRoute = {
    path: '/register',
    element: <Register />
};

export default RegisterRoute;
