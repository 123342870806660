// api
import { useApi } from 'Service/axios';
// react
import { useState, useEffect } from 'react';

// router
import { useNavigate } from 'react-router';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const useAuth = () => {
    const navigate = useNavigate();
    const api = useApi();

    const [authenticated, setAuthenticated] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [loggedUser, setLoggedUser] = useState([]);

    const registerUser = async (user) => {
        try {
            const response = await api.createUser(user);
            if (response.status === 201) {
                navigate('/main/edituser');
                toast.success('Usuário cadastrado com sucesso!');
            }
        } catch (error) {
            setErrorMessage(error);
            toast.error(`Erro: ${error.response.data.mensagem}`);
        }
    };

    const login = async (values) => {
        try {
            const response = await api.signin(values);
            const data = response.data;

            setLoggedUser(data);
            await authUser(data);
            toast.success('Bem vindo(a)');
        } catch (error) {
            toast.error(`Erro: ${error.response.data.mensagem}`);
            setErrorMessage(error);
        }
    };

    const logout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('role');
        localStorage.removeItem('nome');
        localStorage.removeItem('id');
        navigate('/');
    };

    //* Salva o dados no localStorage
    const authUser = async (data) => {
        setAuthenticated(true);
        localStorage.setItem('token', JSON.stringify(data.token));
        localStorage.setItem('role', data?.usuario?.role);
        localStorage.setItem('id', data?.usuario?.id);
        localStorage.setItem('nome', data?.usuario?.nome);
        localStorage.setItem('login_expired', data?.login_24_hours);
        localStorage.setItem('userInfos', JSON.stringify(data));
        navigate('/main');
        window.location.reload();
    };

    const checkJWT = async (id) => {
        try {
            const response = await api.getUserById(id);
            return response;
        } catch (error) {
            logout();
        }
    };

    return { authenticated, errorMessage, loggedUser, login, registerUser, logout };
};

export default useAuth;
