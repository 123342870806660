import { useParams, Link } from 'react-router-dom';
import MainCard from 'ui-component/cards/MainCard';
import { Button, Grid, Box, TablePagination, Autocomplete, Tooltip, TextField } from '@mui/material';
import { gridSpacing } from 'store/constant';
import { useState } from 'react';
import { useEffect } from 'react';
import { useApi } from 'Service/axios';
import { HeaderBox, TableBox } from './style';
import { toast } from 'react-toastify';
import UnidadeTabela from 'components/Estoque/Unidade/Tabela';
import DrawerCreateUnidade from 'components/Estoque/Unidade/DrawerCreate';
import DrawerEditUnidade from 'components/Estoque/Unidade/DrawerEdit';

const Unidade = () => {
    // ============================= INSTÂNCIAS =============================
    const api = useApi();
    // ============================= STATES =============================
    const [allUnidade, setAllUnidade] = useState([]);
    const [selectedUnidade, setSelectedUnidade] = useState(null);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [open, setOpen] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [unidade, setUnidade] = useState(null);
    // ============================= FUNÇÕES =============================

    const handleClickOpenEdit = (e, item) => {
        e.preventDefault();
        setUnidade(item);
        setOpenEdit(true);
    };

    const handleCloseEdit = () => {
        setOpenEdit(false);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const getUnidades = async () => {
        try {
            const response = await api.getAllUnidade();
            setAllUnidade(response.data);
        } catch (error) {
            toast.error(error.response.data.mensagem);
        }
    };

    useEffect(() => {
        getUnidades();
    }, []);

    return (
        <MainCard title="UNIDADE">
            <Grid container spacing={gridSpacing}>
                {/* DRAWERS */}
                <DrawerCreateUnidade open={open} handleClose={handleClose} getUnidades={getUnidades} />
                <DrawerEditUnidade open={openEdit} handleClose={handleCloseEdit} getUnidades={getUnidades} item={unidade} />
                <HeaderBox>
                    <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={allUnidade}
                        getOptionLabel={(unidade) => unidade.descricao}
                        value={selectedUnidade}
                        onChange={(event, newValue) => {
                            setSelectedUnidade(newValue);
                        }}
                        sx={{ width: 300 }}
                        renderInput={(params) => <TextField {...params} label="Pesquisar Unidade" />}
                    />
                    <Button variant="contained" size="medium" onClick={() => setOpen(true)} sx={{ height: '40px' }}>
                        Adicionar
                    </Button>
                </HeaderBox>

                <TableBox>
                    <UnidadeTabela
                        allUnidade={allUnidade}
                        setAllUnidade={setAllUnidade}
                        selectedUnidade={selectedUnidade}
                        page={page}
                        rowsPerPage={rowsPerPage}
                        getUnidades={getUnidades}
                        handleClickOpenEdit={handleClickOpenEdit}
                    />
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={allUnidade.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </TableBox>
            </Grid>
        </MainCard>
    );
};

export default Unidade;
