import React from 'react';
import { useApi } from 'Service/axios';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Tooltip } from '@mui/material';
import { useState } from 'react';
import { Box } from '@mui/system';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

const ActionButtonsDeleteMedicacao = ({ row, getEntradas, setItens }) => {
    /////////////////////////////// INSTANCIAS /////////////////////////
    const api = useApi();
    /////////////////////////////// STATES /////////////////////////
    const [openDialog, setOpenDialog] = useState(false);
    /////////////////////////////// FUNÇÕES /////////////////////////
    const handleDeleteEntrada = (entradaId) => {
        const novoArrayEntradas = getEntradas.filter((entrada) => entrada.id !== entradaId);
        setItens(novoArrayEntradas); // Assumindo que você tem uma função 'setEntradas' para atualizar o estado
        setOpenDialog(false);
        toast.success('Entrada deletada com sucesso');
    };

    return (
        <Box>
            {/* <Dialog open={openDialog}>
                <DialogTitle>Atenção</DialogTitle>
                <DialogContent>Tem certeza que deseja excluir essa entrada?</DialogContent>
                <DialogActions>
                    <Button color="error" onClick={() => setOpenDialog(false)}>
                        não
                    </Button>
                    <Button variant="contained" onClick={() => handleDeleteEntrada(row.id)}>
                        sim
                    </Button>
                </DialogActions>
            </Dialog> */}

            <Tooltip title="Remover Entrada">
                <Button disabled>
                    <DeleteIcon />
                </Button>
            </Tooltip>
        </Box>
    );
};

export default ActionButtonsDeleteMedicacao;
