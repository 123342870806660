import MainCard from 'ui-component/cards/MainCard';
import {
    Grid,
    TextField,
    Box,
    Divider,
    Typography,
    Tooltip,
    Button,
    TableContainer,
    Paper,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody
} from '@mui/material';
import { gridSpacing } from 'store/constant';
import { useApi } from 'Service/axios';
import CalculateIcon from '@mui/icons-material/Calculate';
import { useEffect, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import CreateMedicacaoTriagem from '../CreateMedicacaoUso';
import CreateAlergia from '../CreateAlergia';
import { useNavigate, useParams } from 'react-router-dom';
import ActionButtonsDeleteAlergia from '../CreateAlergia/ActionButtonsDeleteAlergia';
import ActionButtonsDeleteMedicacao from '../CreateMedicacaoUso/ActionButtonsDeleteMedicacao';
import MonitorHeartIcon from '@mui/icons-material/MonitorHeart';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { IconLungs, IconArrowAutofitHeight, IconThermometer, IconPercentage } from '@tabler/icons';
import VaccinesIcon from '@mui/icons-material/Vaccines';
import MonitorWeightIcon from '@mui/icons-material/MonitorWeight';
import InputMask from 'react-input-mask';

import PrintIcon from '@mui/icons-material/Print';
import { toast } from 'react-toastify';

const initialState = {
    itens: []
};

const FichaTriagemConsulta = () => {
    const api = useApi();
    const { ficha } = useParams();
    const navigate = useNavigate();

    const [id, setId] = useState(null);
    const [produntuarioId, setProduntuarioId] = useState(null);
    const [paciente, setPaciente] = useState(null);

    const getFichaById = async (ficha) => {
        try {
            const { data } = await api.getTriagemConsultaById(ficha);
            const { medicacao, triagem } = data;

            setId(triagem?.prontuario?.id_paciente);
            setProduntuarioId(triagem?.prontuario?.id);

            // GET paciente
            const response = await api.getPacienteById(triagem?.prontuario?.id_paciente);
            setPaciente(response.data);

            const med = medicacao?.medicacao_em_uso?.map((item, index) => ({
                id: index,
                medicacao: item
            }));

            setItensMedicacao(med);

            const alerg = medicacao?.alergia?.map((item, index) => ({
                id: index,
                alergia: item
            }));

            setItensAlergia(alerg);

            setItensValue({
                id_prontuario: '',
                pa_sis: triagem && triagem?.pa_1,
                pa_dia: triagem && triagem?.pa_2,
                fc: triagem && triagem?.fc,
                fr: triagem && triagem?.fr,
                dextro: triagem && triagem?.dextro,
                peso: triagem && triagem?.peso,
                altura: triagem && triagem?.altura,
                imc: triagem && triagem?.imc,
                temperatura: triagem && triagem?.temperatura,
                saturacao: triagem && triagem?.saturacao,
                medicacao_em_uso: '',
                alergia: '',
                queixa: triagem && triagem?.queixa
            });
        } catch (error) {
            toast.error(error?.response?.data?.message ?? error?.message);
        }
    };

    // ============================= STATES =============================

    const [itensMedicacao, setItensMedicacao] = useState([]);
    const [itensValue, setItensValue] = useState({
        id_prontuario: '',
        pa_sis: '',
        pa_dia: '',
        fc: '',
        fr: '',
        dextro: '',
        peso: '',
        altura: '',
        imc: '',
        temperatura: '',
        saturacao: '',
        medicacao_em_uso: '',
        alergia: '',
        queixa: ''
    });
    const [campos, setCampos] = useState([initialState]);
    const [itensAlergia, setItensAlergia] = useState([]);
    const [openModalMedicamentos, setOpenModalMedicamentos] = useState(false);
    const [openModalAlergia, setOpenModalAlergia] = useState(false);

    // ============================= FUNÇÕES =============================

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        // Verifica se o valor inserido tem mais de 2 dígitos
        if (name === 'queixa') {
            setItensValue({ ...itensValue, [name]: value });
        }
        if (value.length <= 3 || value === '') {
            setItensValue({ ...itensValue, [name]: value });
        }
    };

    const atualizarCampoItem = (indiceCampo, indiceCampoItem, campo, valor) => {
        setCampos((prevCampos) => {
            const novosCampos = [...prevCampos];
            novosCampos[indiceCampo].itens[indiceCampoItem][campo] = valor;
            return novosCampos;
        });
    };
    const handleClose = () => {
        setOpenModalMedicamentos(false);
        setOpenModalAlergia(false);
    };

    const handleSubmit = async () => {
        window.open(`/relatorios/triagem/${produntuarioId}/numero/${id}`);
    };

    useEffect(() => {
        getFichaById(ficha);
    }, [ficha]);

    return (
        <MainCard title={`Consulta da F.A: ${produntuarioId} - ${paciente?.nome_completo}`}>
            <CreateMedicacaoTriagem
                open={openModalMedicamentos}
                handleClose={handleClose}
                setItens={setItensMedicacao}
                itens={itensMedicacao}
                atualizarCampoItem={atualizarCampoItem}
                campos={campos}
                setCampos={setCampos}
            />
            <CreateAlergia
                open={openModalAlergia}
                handleClose={handleClose}
                setItens={setItensAlergia}
                itens={itensAlergia}
                atualizarCampoItem={atualizarCampoItem}
                campos={campos}
                setCampos={setCampos}
            />
            <Grid container spacing={gridSpacing} sx={{ gap: '1rem' }}>
                <Box sx={{ width: '80%', display: 'flex', alignItems: 'center', justifyContent: 'space-between', margin: '1rem auto' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <MonitorHeartIcon />
                        <TextField
                            label="SIS"
                            type="number"
                            value={itensValue.pa_sis}
                            name="pa_sis"
                            onChange={handleInputChange}
                            disabled
                            sx={{ width: '130px', marginLeft: '.5rem' }}
                        />
                        <Typography sx={{ margin: '0 .3rem 0 .3rem' }}>X</Typography>
                        <TextField
                            label="DIA"
                            type="number"
                            placeholder="DIA"
                            value={itensValue.pa_dia}
                            name="pa_dia"
                            onChange={handleInputChange}
                            disabled
                            sx={{ width: '130px' }}
                        />
                    </Box>

                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <FavoriteIcon />
                        <TextField
                            label="FC"
                            type="number"
                            value={itensValue.fc}
                            name="fc"
                            onChange={handleInputChange}
                            disabled
                            sx={{ width: '120px', marginLeft: '.5rem' }}
                        />
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <IconLungs />
                        <InputMask mask="99" value={itensValue.fr} onChange={handleInputChange} disabled>
                            {(inputProps) => (
                                <TextField
                                    label="FR"
                                    type="text"
                                    name="fr"
                                    disabled
                                    sx={{ width: '120px', marginLeft: '.5rem' }}
                                    inputProps={inputProps}
                                />
                            )}
                        </InputMask>
                    </Box>

                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <VaccinesIcon />
                        <TextField
                            label="DEXTRO"
                            type="number"
                            name="dextro"
                            value={itensValue.dextro}
                            onChange={handleInputChange}
                            disabled
                            sx={{ width: '120px', marginLeft: '.5rem' }}
                        />
                    </Box>
                </Box>
                <Divider sx={{ width: '80%', margin: '0 auto' }} />
                <Box sx={{ width: '80%', display: 'flex', alignItems: 'center', justifyContent: 'space-between', margin: '1rem auto' }}>
                    {/* <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <MonitorWeightIcon />
                        <TextField
                            label="Peso"
                            name="peso"
                            type="text"
                            value={itensValue.peso}
                            onChange={handleInputChange}
                            disabled
                            sx={{ width: '120px', marginLeft: '.5rem' }}
                        />
                    </Box> */}
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <MonitorWeightIcon />
                        <TextField
                            disabled
                            label="Peso"
                            name="peso"
                            type="text"
                            value={itensValue.peso}
                            onChange={handleInputChange}
                            sx={{ width: '130px', marginLeft: '.5rem' }}
                        />
                        <IconArrowAutofitHeight />
                        <TextField
                            disabled
                            label="Altura"
                            name="altura"
                            type="text"
                            value={itensValue.altura}
                            onChange={handleInputChange}
                            sx={{ width: '130px', marginLeft: '.5rem' }}
                        />
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <CalculateIcon />
                        <TextField
                            label="IMC"
                            name="IMC"
                            type="number"
                            value={itensValue.imc}
                            disabled
                            sx={{ width: '120px', marginLeft: '.5rem' }}
                        />
                    </Box>
                    {/* <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <IconArrowAutofitHeight />
                        <TextField
                            label="Altura"
                            name="altura"
                            type="text"
                            value={itensValue.altura}
                            onChange={handleInputChange}
                            disabled
                            sx={{ width: '120px', marginLeft: '.5rem' }}
                        />
                    </Box> */}

                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <IconThermometer />
                        <TextField
                            label="Temperatura"
                            name="temperatura"
                            type="text"
                            value={itensValue.temperatura}
                            onChange={handleInputChange}
                            disabled
                            sx={{ width: '120px', marginLeft: '.5rem' }}
                        />
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <IconPercentage />
                        <TextField
                            label="Saturação"
                            name="saturacao"
                            type="number"
                            value={itensValue.saturacao}
                            onChange={handleInputChange}
                            disabled
                            sx={{ width: '120px', marginLeft: '.5rem' }}
                        />
                    </Box>
                </Box>
                {/* TABELA */}

                <Box
                    sx={{
                        width: '80%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        margin: '1rem auto'
                    }}
                >
                    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '42%' }}>
                        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                            <Typography sx={{ marginTop: '15px' }}>MEDICAÇÃO EM USO:</Typography>
                            <Box>
                                <Tooltip title="Adicionar item">
                                    <Button
                                        variant="contained"
                                        color="success"
                                        disabled
                                        onClick={(e) => setOpenModalMedicamentos(!openModalMedicamentos)}
                                    >
                                        <AddIcon />
                                    </Button>
                                </Tooltip>
                            </Box>
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'column', marginTop: '1rem', height: '200px' }}>
                            <TableContainer component={Paper}>
                                <Table aria-label="simple table" stickyHeader>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>
                                                <b>Medicacao</b>
                                            </TableCell>
                                            <TableCell align="center">
                                                <b>AÇÕES</b>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {itensMedicacao?.map((item) => (
                                            <TableRow>
                                                <TableCell>{item.medicacao}</TableCell>
                                                <TableCell align="center">
                                                    <ActionButtonsDeleteMedicacao
                                                        row={item}
                                                        getEntradas={itensMedicacao}
                                                        setItens={setItensMedicacao}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </Box>

                    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '42%' }}>
                        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                            <Typography sx={{ marginTop: '15px' }}>ALERGIA:</Typography>
                            <Box>
                                <Tooltip title="Adicionar item">
                                    <Button
                                        variant="contained"
                                        color="success"
                                        disabled
                                        onClick={(e) => setOpenModalAlergia(!openModalAlergia)}
                                    >
                                        <AddIcon />
                                    </Button>
                                </Tooltip>
                            </Box>
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'column', marginTop: '1rem', height: '200px' }}>
                            <TableContainer component={Paper}>
                                <Table aria-label="simple table" stickyHeader>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>
                                                <b>Alergia</b>
                                            </TableCell>
                                            <TableCell align="center">
                                                <b>AÇÕES</b>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {itensAlergia?.map((item) => (
                                            <TableRow>
                                                <TableCell>{item.alergia}</TableCell>
                                                <TableCell align="center">
                                                    <ActionButtonsDeleteAlergia
                                                        row={item}
                                                        getEntradas={itensAlergia}
                                                        setItens={setItensAlergia}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </Box>
                </Box>

                <Box
                    sx={{
                        width: '80%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignContent: 'space-between',
                        justifyContent: 'center',
                        margin: '0 auto'
                    }}
                >
                    <TextField
                        label="Queixa"
                        name="queixa"
                        value={itensValue.queixa}
                        onChange={handleInputChange}
                        multiline={true}
                        minRows={5}
                        disabled
                        sx={{ width: '100%' }}
                    />

                    <Box
                        sx={{
                            width: '80%',
                            display: 'flex',
                            gap: '2rem',
                            justifyContent: 'flex-end',
                            alignSelf: 'flex-end',
                            marginTop: '1rem'
                        }}
                    >
                        <Tooltip title="Imprimir ficha">
                            <Button variant="contained" color="primary" onClick={(e) => handleSubmit(e, itensValue)}>
                                <PrintIcon />
                            </Button>
                        </Tooltip>
                        <Tooltip title="Voltar para consulta">
                            <Button variant="contained" color="primary" onClick={() => navigate(-1)}>
                                Voltar
                            </Button>
                        </Tooltip>
                    </Box>
                </Box>
            </Grid>
        </MainCard>
    );
};

export default FichaTriagemConsulta;
