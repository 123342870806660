import {
    Button,
    Box,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Collapse,
    Tooltip,
    IconButton,
    DialogTitle
} from '@mui/material';
import { useState, useEffect } from 'react';
import { useApi } from 'Service/axios';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { Link } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { toast } from 'react-toastify';

function Row({ item, getUnidades, handleClickOpenEdit }) {
    // =============== INSTÃNCIAS ================
    const theme = useTheme();
    const api = useApi();
    // =============== STATES ================
    const [openDelete, setOpenDelete] = useState(false);
    const [itemId, setItemId] = useState(null);
    // =============== FUNÇÕES ================

    const handleClickOpenDelete = (e, id) => {
        e.preventDefault();
        setOpenDelete(true);
        setItemId(id);
    };

    const handleCloseDelete = (e) => {
        e.preventDefault();
        setOpenDelete(false);
    };
    const handleCancelDeleteDialog = () => {
        setOpenDelete(false);
    };

    const handleDeleteItem = async (e, id) => {
        e.preventDefault();
        try {
            const response = await api.deleteUnidade(id);
            toast.success(response.data.mensagem);
            getUnidades();
            setOpenDelete(false);
        } catch (error) {
            setOpenDelete(false);
            toast.error(error.response.data.mensagem);
        }
    };

    return (
        <>
            <Dialog
                open={openDelete}
                onClose={handleCloseDelete}
                aria-labelledby="alert-dialog-title-delete"
                aria-describedby="alert-dialog-description-delete"
            >
                <DialogTitle id="alert-dialog-title-delete">Deletar Registro</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description-delete">Tem certeza que deseja excluir a Unidade ?</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={(e) => handleDeleteItem(e, itemId)}>Sim</Button>
                    <Button onClick={handleCancelDeleteDialog}>Cancelar</Button>
                </DialogActions>
            </Dialog>
            <TableRow hover sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell>{item.id}</TableCell>
                <TableCell align="center">{item.descricao}</TableCell>
                <TableCell align="center">{item.sigla}</TableCell>
                <TableCell align="center">
                    <Tooltip title="Editar Unidade">
                        <IconButton onClick={(e) => handleClickOpenEdit(e, item)}>
                            <EditIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Excluir Unidade">
                        <IconButton onClick={(e) => handleClickOpenDelete(e, item.id)}>
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                </TableCell>
            </TableRow>
        </>
    );
}

export default Row;
