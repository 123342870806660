import { Button, Drawer, FormControl, FormHelperText, InputLabel, OutlinedInput, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { FormBox } from './style';
import useScriptRef from 'hooks/useScriptRef';
import { useTheme } from '@mui/material/styles';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { IconDeviceFloppy } from '@tabler/icons';
import { useApi } from 'Service/axios';
import { toast } from 'react-toastify';
import { useState } from 'react';

const DrawerCreateUnidade = ({ open, handleClose, getUnidades, ...others }) => {
    // ============================= INSTÂNCIAS =============================
    const theme = useTheme();
    const api = useApi();
    const scriptedRef = useScriptRef();
    // ============================= STATES =============================
    const [loading, setLoading] = useState(false);
    // ============================= FUNÇÕES =============================
    const clearValues = (values) => {
        values.descricao = '';
        values.sigla = '';
    };

    const handleRegister = async (values) => {
        try {
            setLoading(true);
            const response = await api.createUnidade(values);
            toast.success(response.data.mensagem);
            // limpar campos
            clearValues(values);
            getUnidades();
            setLoading(false);
        } catch (error) {
            setLoading(false);
            toast.error(error.response.data.mensagem);
        }
    };

    return (
        <Drawer anchor="right" open={open} onClose={handleClose}>
            <Box sx={{ width: '450px', padding: '.75em', height: '100%' }}>
                <Formik
                    initialValues={{
                        descricao: '',
                        sigla: '',
                        submit: null
                    }}
                    validationSchema={Yup.object().shape({
                        descricao: Yup.string().required('Descricao é obrigatorio')
                    })}
                    onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                        try {
                            if (scriptedRef.current) {
                                setStatus({ success: true });
                                setSubmitting(false);
                            }
                            await handleRegister(values);
                        } catch (err) {
                            console.error(err);
                            if (scriptedRef.current) {
                                setStatus({ success: false });
                                setErrors({ submit: err.msg });
                                setSubmitting(false);
                            }
                        }
                    }}
                >
                    {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                        <form
                            noValidate
                            onSubmit={handleSubmit}
                            {...others}
                            style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}
                        >
                            <Box>
                                <Typography variant="h3">Unidade</Typography>
                                <Typography variant="h4" sx={{ margin: '1em 0 1em 0' }}>
                                    Geral
                                </Typography>
                                <FormBox>
                                    <FormControl
                                        fullWidth
                                        error={Boolean(touched.descricao && errors.descricao)}
                                        sx={{ ...theme.typography.customInput }}
                                    >
                                        <InputLabel htmlFor="outlined-adornment-descricao-register">Descricão</InputLabel>
                                        <OutlinedInput
                                            id="outlined-adornment-descricao-register"
                                            type="text"
                                            value={values.descricao}
                                            name="descricao"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            inputProps={{}}
                                        />
                                        {touched.descricao && errors.descricao && (
                                            <FormHelperText error id="standard-weight-helper-text--register">
                                                {errors.descricao}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                </FormBox>
                                <FormBox>
                                    <FormControl
                                        fullWidth
                                        error={Boolean(touched.sigla && errors.sigla)}
                                        sx={{ ...theme.typography.customInput }}
                                    >
                                        <InputLabel htmlFor="outlined-adornment-sigla-register">Sigla</InputLabel>
                                        <OutlinedInput
                                            id="outlined-adornment-sigla-register"
                                            type="text"
                                            value={values.sigla}
                                            name="sigla"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            inputProps={{}}
                                        />
                                        {touched.sigla && errors.sigla && (
                                            <FormHelperText error id="standard-weight-helper-text--register">
                                                {errors.sigla}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                </FormBox>
                            </Box>

                            {errors.submit && (
                                <Box sx={{ mt: 3 }}>
                                    <FormHelperText error>{errors.submit}</FormHelperText>
                                </Box>
                            )}

                            {/* BOTÕES */}
                            <Box sx={{ mt: 2, width: '20%', marginLeft: 'auto' }}>
                                <AnimateButton>
                                    <Button disableElevation disabled={loading} fullWidth size="large" type="submit" variant="contained">
                                        {loading ? 'Aguarde...' : 'Adicionar'}
                                    </Button>
                                </AnimateButton>
                            </Box>
                        </form>
                    )}
                </Formik>
            </Box>
        </Drawer>
    );
};

export default DrawerCreateUnidade;
