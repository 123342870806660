import QueuePlayNextIcon from '@mui/icons-material/QueuePlayNext';
import PersonalVideoIcon from '@mui/icons-material/PersonalVideo';
import ListIcon from '@mui/icons-material/List';

const icons = {
    QueuePlayNextIcon,
    ListIcon,
    PersonalVideoIcon
};

const triagem = {
    id: 'triagem',
    title: 'Escuta inicial',
    type: 'group',
    children: [
        {
            id: 'triagemPrincipal',
            title: 'Atendimentos Espera',
            type: 'item',
            url: '/main/triagem',
            icon: icons.QueuePlayNextIcon,
            breadcrumbs: false
        },
        {
            id: 'triagemConsulta',
            title: 'Consulta',
            type: 'item',
            url: '/main/consulta',
            icon: icons.ListIcon,
            breadcrumbs: false
        }
    ]
};

export default triagem;
