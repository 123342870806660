import { styled } from '@mui/material';
import Box from '@mui/material/Box';

export const MainBoxRight = styled(Box)({
    padding: '1em',
    marginRight: '2em'
});

export const MainBoxLeft = styled(Box)({
    padding: '1em',
    marginLeft: '2em'
});

export const ContentBox = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '.5em',
    marginBottom: '1em'
});
