import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Drawer,
    FormControl,
    MenuItem,
    TextField,
    Tooltip,
    Typography
} from '@mui/material';
import { useState } from 'react';
import { Box } from '@mui/system';
import { useEffect } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import LogoutIcon from '@mui/icons-material/Logout';

const ActionButtonsEditCreate = ({
    open,
    handleClose,
    itens,
    setItens,
    campos,
    setCampos,
    adicionarCampoItem,
    atualizarCampoItem,
    removerCampoItem,
    indiceCampoItem,
    item,
    listAllItens,
    listAllAlmoxarifado,
    listAllLocalizacao
}) => {
    /////////////////////////////// INSTANCIAS /////////////////////////

    /////////////////////////////// STATES /////////////////////////
    const [openDialog, setOpenDialog] = useState(false);
    const [indexItem, setIndexItem] = useState(null);
    /////////////////////////////// FUNÇÕES /////////////////////////
    const handleOpen = async () => {
        setOpen(true);
    };

    const handleCreteItem = () => {
        const ultimoItem = itens[itens.length - 1];
        const novoId = ultimoItem ? ultimoItem.id + 1 : 1;
        const novoItem = { ...item, id: novoId, action: 'create', hora: new Date() };
        setItens((prevItens) => [...prevItens, novoItem]);
        handleClose();
    };

    useEffect(() => {
        atualizarCampoItem(0, indiceCampoItem, 'indiceCampoItem', indiceCampoItem);
    }, []);

    return (
        <Box>
            <Dialog open={openDialog}>
                <DialogTitle>Atenção</DialogTitle>
                <DialogContent>
                    <Typography>Ao fechar todo o cadastro desse item sera cancelado, continuar?</Typography>
                </DialogContent>
                <DialogActions>
                    <Box sx={{ display: 'flex' }}>
                        <Button color="error" onClick={() => setOpenDialog(false)}>
                            Cancelar
                        </Button>
                        <Button
                            onClick={() => {
                                removerCampoItem(0, indiceCampoItem), setOpenDialog(false);
                            }}
                        >
                            Confirmar
                        </Button>
                    </Box>
                </DialogActions>
            </Dialog>
            <Drawer anchor="right" open={open}>
                <Box sx={{ width: '650px', padding: '.75em', height: '100%' }}>
                    <Box>
                        <FormControl fullWidth>
                            <TextField
                                select
                                label="Item"
                                value={item.id}
                                onChange={(e) => {
                                    const objeto = e.target.value;
                                    setIndexItem(objeto);
                                    atualizarCampoItem(0, indiceCampoItem, 'item_id', objeto.id);
                                    atualizarCampoItem(0, indiceCampoItem, 'descricao', objeto.descricao);
                                    atualizarCampoItem(0, indiceCampoItem, 'unidade', objeto.unidade.descricao);
                                    atualizarCampoItem(0, indiceCampoItem, 'almoxarifado_id', objeto.almoxarifado.id);
                                    atualizarCampoItem(0, indiceCampoItem, 'localizacao_id', objeto.localizacao.id);
                                }}
                            >
                                {listAllItens.map((i) => (
                                    <MenuItem key={i.id} value={i}>
                                        {i.descricao}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </FormControl>
                        <Box sx={{ display: 'flex', gap: '1em', marginTop: '1em', marginBottom: '1em' }}>
                            <FormControl fullWidth>
                                <TextField
                                    label="Quantidade"
                                    type="number"
                                    value={item.quantidade}
                                    onChange={(e) => atualizarCampoItem(0, indiceCampoItem, 'quantidade', parseFloat(e.target.value))}
                                />
                            </FormControl>
                            <FormControl fullWidth>
                                <TextField
                                    label="Valor Unitário"
                                    type="number"
                                    value={item.valor_unitario}
                                    onChange={(e) => {
                                        let resultado = parseFloat(item.quantidade * e.target.value);
                                        atualizarCampoItem(0, indiceCampoItem, 'valor_unitario', parseFloat(e.target.value)),
                                            atualizarCampoItem(0, indiceCampoItem, 'valor_total', resultado);
                                    }}
                                />
                            </FormControl>
                        </Box>
                        <FormControl fullWidth>
                            <TextField label="Valor total" type="number" value={item.valor_total} disabled />
                        </FormControl>
                        <Box sx={{ display: 'flex', gap: '1em', marginTop: '1em', marginBottom: '1em' }}>
                            <FormControl fullWidth>
                                <TextField
                                    label="Almoxarifado"
                                    select
                                    value={item.almoxarifado_id}
                                    onChange={(e) => atualizarCampoItem(0, indiceCampoItem, 'almoxarifado_id', e.target.value)}
                                >
                                    {listAllAlmoxarifado.map((almoxarifado) => (
                                        <MenuItem key={almoxarifado.id} value={almoxarifado.id}>
                                            {almoxarifado.descricao}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </FormControl>
                            <FormControl fullWidth>
                                <TextField
                                    label="Localização"
                                    select
                                    value={item.localizacao_id}
                                    onChange={(e) => atualizarCampoItem(0, indiceCampoItem, 'localizacao_id', e.target.value)}
                                >
                                    {listAllLocalizacao.map((localizacao) => (
                                        <MenuItem key={localizacao.id} value={localizacao.id}>
                                            {localizacao.descricao}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </FormControl>
                        </Box>
                        <Box sx={{ display: 'flex', gap: '1em', marginTop: '1em', marginBottom: '1em' }}>
                            <FormControl fullWidth>
                                <TextField
                                    label="Observação"
                                    value={item.observacao}
                                    onChange={(e) => atualizarCampoItem(0, indiceCampoItem, 'observacao', e.target.value)}
                                />
                            </FormControl>
                        </Box>
                        <Box sx={{ display: 'flex', gap: '1em' }}>
                            {indexItem !== null && indexItem.lote && (
                                <FormControl fullWidth>
                                    <TextField
                                        label="Lote"
                                        type="text"
                                        value={item.lote}
                                        onChange={(e) => atualizarCampoItem(0, indiceCampoItem, 'lote', e.target.value)}
                                    />
                                </FormControl>
                            )}
                            {indexItem !== null && indexItem.validade && (
                                <FormControl fullWidth>
                                    <TextField
                                        type="date"
                                        value={item.validade}
                                        onChange={(e) => atualizarCampoItem(0, indiceCampoItem, 'validade', e.target.value)}
                                    />
                                </FormControl>
                            )}
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '2em' }}>
                            <Tooltip title="Fechar e remover">
                                <Button variant="contained" color="error" onClick={() => setOpenDialog(true)}>
                                    <CloseIcon />
                                </Button>
                            </Tooltip>
                            <Tooltip title="Fechar">
                                <Button variant="contained" color="warning" onClick={handleClose}>
                                    <LogoutIcon />
                                </Button>
                            </Tooltip>
                            <Button variant="contained" onClick={handleCreteItem}>
                                Salvar e fechar
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Drawer>
        </Box>
    );
};

export default ActionButtonsEditCreate;
