import { TableCell, styled } from '@mui/material';
import { Box, Typography, Button } from '@mui/material';
import { styleds } from '@emotion/styled';
import { borderBottom, borderTop } from '@mui/system';

// ===================HEADER===================
export const ContainerPai = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    marginLeft: '1%',
    border: '2px solid #000'
});

export const HeaderBox = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    margin: '0 auto',
    borderTop: '2px solid #000'
});

export const Title = styled(Typography)({
    fontSize: '1em'
});

export const Mid = styled(Box)({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    height: '40px',
    margin: '10px auto',
    borderTop: '2px solid #000',
    borderBottom: '2px solid #000'
});

export const Body = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    width: '100%',
    margin: '0 auto'
});
export const CampoContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'start',
    minWidth: '99%',
    margin: '0 auto'
});

export const Campo = styled(Box)({
    width: '100%', // isso garante que ele ocupe toda a largura disponível
    height: '25px',
    display: 'flex',
    textAlign: 'left',
    alignItems: 'center',
    borderBottom: '1px solid #181818',
    fontSize: '15px'
});
