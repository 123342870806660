import { Link } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Grid, Stack, Typography, useMediaQuery } from '@mui/material';

// project imports
import AuthWrapper1 from '../AuthWrapper1';
import AuthCardWrapper from '../AuthCardWrapper';
import AuthLogin from '../auth-forms/AuthLogin';
import Logo from 'ui-component/Logo';
import Footer from 'layout/MainLayout/Footer';

import BG from '../../../../assets/images/Backgrounds/fundoSTCS2.jpg';

// ================================|| AUTH3 - LOGIN ||================================ //

const Login = () => {
    const theme = useTheme();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <>
            <AuthWrapper1>
                <Grid container direction="column" justifyContent="center" sx={{ minHeight: '100vh', backgroundColor: '#fff' }}>
                    <Grid sx={{ display: 'flex' }}>
                        <Grid item xs={false} lg={4} sm={6}>
                            <Grid container justifyContent="center" alignItems="center" sx={{ minHeight: 'calc(100vh - 68px)' }}>
                                <Grid item sx={{ m: { xs: 1, sm: 3 }, mb: 0 }}>
                                    <AuthCardWrapper sx={{ border: 'none' }}>
                                        <AuthCardWrapper sx={{ border: 'none' }}>
                                            <Grid container spacing={2} alignItems="center" justifyContent="center">
                                                <Grid item sx={{ mb: 3 }}>
                                                    <Logo width="300" />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Grid
                                                        container
                                                        direction={matchDownSM ? 'column-reverse' : 'row'}
                                                        alignItems="center"
                                                        justifyContent="center"
                                                    >
                                                        <Grid item>
                                                            <Stack alignItems="center" justifyContent="center" spacing={1}>
                                                                <Typography
                                                                    color="primary"
                                                                    gutterBottom
                                                                    variant={matchDownSM ? 'h3' : 'h2'}
                                                                >
                                                                    Bem vindo de Volta
                                                                </Typography>
                                                                <Typography
                                                                    variant="caption"
                                                                    fontSize="16px"
                                                                    textAlign={matchDownSM ? 'center' : 'inherit'}
                                                                >
                                                                    Entre com suas credenciais para continuar
                                                                </Typography>
                                                            </Stack>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <AuthLogin />
                                                </Grid>
                                            </Grid>
                                        </AuthCardWrapper>
                                    </AuthCardWrapper>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid
                            xs={8}
                            sx={{
                                display: { xs: 'none', lg: 'block' },
                                width: '100%',
                                backgroundImage: 'url(' + BG + ')',
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: 'cover',
                                backgroundPosition: 'center'
                            }}
                        ></Grid>
                    </Grid>

                    <Footer />
                </Grid>
            </AuthWrapper1>
        </>
    );
};

export default Login;
