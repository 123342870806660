import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { TextField } from '@mui/material';
import { useApi } from 'Service/axios';
import { toast } from 'react-toastify';
import { useEffect } from 'react';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: 2
};

export function MedicalRecordModal({ paciente, prontuarioId, getRecord, data }) {
    const api = useApi();
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [motivoAtendimento, setMotivoAtendimento] = React.useState('');

    useEffect(() => {
        if (data) {
            setMotivoAtendimento(data?.conteudo ? data?.conteudo : '');
        }
    }, [data]);

    async function handleSubmit(event) {
        event.preventDefault();
        const payload = {
            dados: motivoAtendimento
        };
        const toastId = toast.loading('Salvando dados...');
        try {
            await api.addMedicalRecord(prontuarioId, payload);
            toast.update(toastId, {
                render: 'Dados salvos com sucesso!',
                type: 'success',
                isLoading: false,
                autoClose: 2000
            });
            getRecord();
            handleClose();
        } catch (error) {
            toast.update(toastId, {
                render: 'Erro ao salvar dados!',
                type: 'error',
                isLoading: false,
                autoClose: 2000
            });
        }
    }

    return (
        <div>
            <Button onClick={handleOpen} variant="contained">
                +
            </Button>
            <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box sx={style}>
                    <h3>FICHA MÉDICA - {paciente?.nome_completo}</h3>
                    <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column' }}>
                        <TextField
                            multiline
                            label="Motivo do atendimento"
                            rows={4}
                            value={motivoAtendimento}
                            onChange={(e) => {
                                setMotivoAtendimento(e.target.value);
                            }}
                        />
                        <Button variant="contained" type="submit" sx={{ maxWidth: '200px', marginTop: '20px' }}>
                            Salvar
                        </Button>
                    </form>
                </Box>
            </Modal>
        </div>
    );
}
