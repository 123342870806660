import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';

const DeleteModal = ({ openDelete, setOpenDelete, handleDeleteTipo }) => {
    return (
        <Dialog open={openDelete}>
            <DialogTitle>Atenção!</DialogTitle>
            <DialogContent>Ao prosseguir, o grupo será excluído</DialogContent>
            <DialogActions>
                <Box sx={{ display: 'flex', gap: '1rem' }}>
                    <Button variant="contained" color="error" onClick={() => setOpenDelete(false)}>
                        Cancelar
                    </Button>
                    <Button variant="contained" onClick={handleDeleteTipo}>
                        Confirmar
                    </Button>
                </Box>
            </DialogActions>
        </Dialog>
    );
};

export default DeleteModal;
