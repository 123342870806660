import MainCard from 'ui-component/cards/MainCard';
import { Autocomplete, Button, Card, Grid, TextField, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { gridSpacing } from 'store/constant';
import { useEffect, useState } from 'react';
import { useApi } from 'Service/axios';
import { Link } from 'react-router-dom';
import { TableBox } from './style';
import { formatarSUS } from 'utils/formatSUS';
import { formatarCPF } from 'utils/formatCpf';
import formater from 'utils/DateDDMMYYY';
import LobbyActionButton from 'components/LobbyActionButton';
import { Box } from '@mui/system';
import PersonIcon from '@mui/icons-material/Person';
import { formatarData } from 'utils/formatarData';
import { toast } from 'react-toastify';

function Lobby() {
    // ======== INSTÂNCIAS ========
    const api = useApi();
    // ======== STATES ========
    const [allPaciente, setAllPaciente] = useState([]);
    const [selectedPaciente, setSelectedPaciente] = useState(null);
    const [atendimentosDia, setAtendimentosDia] = useState([]);
    const [procedimentosExternos, setProcedimentosExternos] = useState([]);
    const columns = [
        { field: 'id', headerName: 'ID', flex: 0.5 },
        { field: 'nome_completo', headerName: 'Paciente', flex: 2 },
        { field: 'cidade', headerName: 'Cidade', flex: 1 },
        {
            field: 'cadastro_ativo',
            headerName: 'Cadastro_ativo',
            flex: 1,
            valueGetter: (params) => (params.row.cadastro_ativo === true ? 'Ativo' : 'Inativo')
        },
        {
            field: 'data_nascimento',
            headerName: 'Data Nascimento',
            flex: 1,
            valueGetter: (params) => (params.row.data_nascimento !== null ? formatarData(params.row.data_nascimento) : '')
        },
        { field: 'cartao_sus', headerName: 'Cartão Sus', flex: 1, valueGetter: (params) => formatarSUS(params.row.cartao_sus) },
        { field: 'cpf', headerName: 'Cpf', flex: 1, valueGetter: (params) => (params.row.cpf !== null ? formatarCPF(params.row.cpf) : '') },
        {
            field: 'acao',
            headerName: 'AÇÕES',
            flex: 1,
            sortable: false,
            renderCell: (params) => <LobbyActionButton row={params.row} />
        }
    ];
    // ======== FUNÇÕES ========

    const getAllAtendimentos = async () => {
        try {
            const { data } = await api.getTotalAtendimentosDia();
            setAtendimentosDia(data.total_prontuarios);
        } catch (error) {
            toast.error('Erro ao buscar total de atendimentos');
        }
    };

    const getAllProcedimentosExternos = async () => {
        try {
            const { data } = await api.getTotalPEDia();
            setProcedimentosExternos(data.total_prontuarios);
        } catch (error) {
            toast.error('Erro ao buscar total de atendimentos');
        }
    };

    const getAllPaciente = async () => {
        const response = await api.getAllPaciente();

        setAllPaciente(response.data);
    };

    useEffect(() => {
        // Chamada inicial ao abrir a tela
        getAllAtendimentos();
        getAllProcedimentosExternos();

        // Configura o intervalo para as chamadas a cada 10 segundos
        const interval = setInterval(() => {
            getAllAtendimentos();
            getAllProcedimentosExternos();
        }, 10000);

        // Limpa o intervalo quando o componente é desmontado
        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        getAllPaciente();
    }, []);

    return (
        <MainCard>
            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '2em' }}>
                <Typography sx={{ fontSize: '1.125rem', fontWeight: '500' }}>PRONTUÁRIOS</Typography>
                <Link to={`/main/criar_prontuario`}>
                    <Button variant="contained">Novo Prontuário</Button>
                </Link>
            </Box>
            <Grid container spacing={gridSpacing}>
                <Box sx={{ width: '97%', display: 'flex', justifyContent: 'flex-end', gap: '2rem', margin: '1rem 0 0 1rem' }}>
                    <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={allPaciente.slice().sort((a, b) => a.nome_completo.localeCompare(b.nome_completo))}
                        getOptionLabel={(paciente) => paciente.nome_completo}
                        value={selectedPaciente}
                        onChange={(event, newValue) => {
                            setSelectedPaciente(newValue);
                        }}
                        sx={{ width: 300 }}
                        renderInput={(params) => <TextField {...params} label="Pesquisar Paciente" />}
                    />
                    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end', gap: '2rem' }}>
                        <Card
                            sx={{
                                background: '#2196f3',
                                color: '#fff',
                                padding: '1rem',
                                minWidth: '250px',
                                '&:hover svg': {
                                    opacity: '1',
                                    transform: 'scale(1.1)'
                                },
                                height: '65px',
                                display: 'flex',
                                flexDirection: 'row-reverse',
                                alignItems: 'center',
                                justifyContent: 'space-around'
                            }}
                        >
                            <Box
                                sx={{
                                    color: '#fff',
                                    marginLeft: '1rem',
                                    '&> svg': {
                                        width: 40,
                                        height: 40,
                                        opacity: '0.4',
                                        transition: 'all .3s ease-in-out'
                                    }
                                }}
                            >
                                <PersonIcon />
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                <Typography variant="h2" color="inherit">
                                    {procedimentosExternos}
                                </Typography>

                                <Typography variant="subtitle2" color="inherit">
                                    Procedimentos Externos
                                </Typography>
                            </Box>
                        </Card>
                        <Card
                            sx={{
                                background: '#2196f3',
                                color: '#fff',
                                padding: '1rem',
                                minWidth: '250px',
                                '&:hover svg': {
                                    opacity: '1',
                                    transform: 'scale(1.1)'
                                },
                                height: '65px',
                                display: 'flex',
                                flexDirection: 'row-reverse',
                                alignItems: 'center',
                                justifyContent: 'space-around'
                            }}
                        >
                            <Box
                                sx={{
                                    color: '#fff',
                                    marginLeft: '1rem',
                                    '&> svg': {
                                        width: 40,
                                        height: 40,
                                        opacity: '0.4',
                                        transition: 'all .3s ease-in-out'
                                    }
                                }}
                            >
                                <PersonIcon />
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                <Typography variant="h2" color="inherit">
                                    {atendimentosDia}
                                </Typography>

                                <Typography variant="subtitle2" color="inherit">
                                    Atendimentos no Dia
                                </Typography>
                            </Box>
                        </Card>
                    </Box>
                </Box>
                <TableBox>
                    <DataGrid
                        rows={allPaciente.filter((paciente) =>
                            selectedPaciente ? paciente.nome_completo.includes(selectedPaciente.nome_completo.toUpperCase()) : true
                        )}
                        columns={columns}
                        hideFooter={false}
                        onSelectionModelChange={() => handleSelectionModelChange(params.id)}
                    />
                </TableBox>
            </Grid>
        </MainCard>
    );
}

export default Lobby;
