import React from 'react';
import { useApi } from 'Service/axios';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Tooltip } from '@mui/material';
import { useState } from 'react';
import { Box } from '@mui/system';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

const ActionButtonsTabela = ({ row, getEntradas }) => {
    /////////////////////////////// INSTANCIAS /////////////////////////
    const api = useApi();
    /////////////////////////////// STATES /////////////////////////
    const [openDialog, setOpenDialog] = useState(false);
    /////////////////////////////// FUNÇÕES /////////////////////////
    const handleDeleteEntrada = async (entradaId) => {
        try {
            await api.deleteEntradaEstoque(entradaId);
            getEntradas();
            setOpenDialog(false);
            toast.success('Entrada deletada com sucesso');
        } catch (error) {
            toast.error(error.response.data.message);
        }
    };

    return (
        <Box>
            <Dialog open={openDialog}>
                <DialogTitle>Atenção</DialogTitle>
                <DialogContent>Tem certeza que deseja excluir essa entrada?</DialogContent>
                <DialogActions>
                    <Button color="error" onClick={() => setOpenDialog(false)}>
                        não
                    </Button>
                    <Button variant="contained" onClick={() => handleDeleteEntrada(row.id)}>
                        sim
                    </Button>
                </DialogActions>
            </Dialog>
            <Tooltip title="Editar Entrada">
                <Link to={`/main/estoque/entrada/${row.id}`}>
                    <Button>
                        <EditIcon />
                    </Button>
                </Link>
            </Tooltip>
            <Tooltip title="Remover Entrada">
                <Button onClick={() => setOpenDialog(true)}>
                    <DeleteIcon />
                </Button>
            </Tooltip>
        </Box>
    );
};

export default ActionButtonsTabela;
