import { useApi } from 'Service/axios';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Drawer,
    FormControl,
    IconButton,
    MenuItem,
    TextField,
    Tooltip,
    Typography
} from '@mui/material';
import { useEffect, useState } from 'react';
import { Box } from '@mui/system';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

const ActionButton = ({ row, get, setOpenEdit, setOpenDelete, setId }) => {
    /////////////////////////////// INSTANCIAS /////////////////////////
    const api = useApi();
    /////////////////////////////// STATES /////////////////////////

    /////////////////////////////// FUNÇÕES /////////////////////////

    return (
        <Box sx={{ display: 'flex' }}>
            <Tooltip
                title="Editar"
                onClick={() => {
                    setOpenEdit(true);
                    setId(row.id);
                }}
            >
                <IconButton>
                    <EditIcon />
                </IconButton>
            </Tooltip>
            <Tooltip
                title="Excluir"
                onClick={() => {
                    setOpenDelete(true), setId(row.id);
                }}
            >
                <IconButton>
                    <DeleteIcon />
                </IconButton>
            </Tooltip>
        </Box>
    );
};

export default ActionButton;
