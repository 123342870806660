import MainCard from 'ui-component/cards/MainCard';
import { Grid, Box } from '@mui/material';
import { gridSpacing } from 'store/constant';
import { useApi } from 'Service/axios';
import FormEntrada from 'components/Estoque/Movimentos/Entrada';

const EntradaManual = () => {
    // ============================= INSTÂNCIAS ==========================
    const api = useApi();
    // ============================= STATES ==============================

    // ============================= FUNÇÕES =============================

    return (
        <MainCard title="ENTRADA MANUAL">
            <Grid container spacing={gridSpacing}>
                <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                    <FormEntrada />
                </Box>
            </Grid>
        </MainCard>
    );
};

export default EntradaManual;
