import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Autocomplete, Checkbox, Divider, FormControl, FormControlLabel, FormGroup, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { useApi } from 'Service/axios';
import { toast } from 'react-toastify';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: 2,
    height: '650px',
    width: '1100px'
};
const styleSecond = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: 2,
    minHeight: '350px'
};

export function RayxModal({ open, setOpen, name, prontuarioId, get }) {
    const handleClose = () => {
        setOpen(false);
        setObservation('');
        setRayxSelected({});

        setSelectedButtons([]);
    };

    const [groupName, setGroupName] = useState([]);
    const [examsArray, setExamArray] = useState([]);

    const [selectedRayx, setRayxSelected] = useState({});
    const [observation, setObservation] = useState('');
    const api = useApi();
    const [selectedButtons, setSelectedButtons] = useState([]); // Estado para rastrear botões selecionados
    const [isSelected, setIsSelected] = useState(false);

    async function getGroups() {
        try {
            const { data } = await api.getAllXRay();

            // Agrupando os itens pela descrição do grupo_raio_x
            const groupedData = data.reduce((acc, item) => {
                const group = item.grupo_raio_x.descricao;
                if (!acc[group]) {
                    acc[group] = [];
                }
                acc[group].push(item);
                return acc;
            }, {});

            setExamArray(groupedData);
            setGroupName(Object.keys(groupedData));
        } catch (error) {
            toast.error(error?.response?.data?.message ?? error?.message);
        }
    }

    const handleButtonClick = (item) => {
        setSelectedButtons((prevSelected) => {
            if (prevSelected.some((selectedItem) => selectedItem.id === item.id)) {
                return prevSelected.filter((selectedItem) => selectedItem.id !== item.id);
            } else {
                return [...prevSelected, item];
            }
        });
    };

    useEffect(() => {
        if (open) {
            getGroups();
        }
    }, [open]);

    async function handleSubmit() {
        const id = localStorage.getItem('id');
        const payloads = selectedButtons.map((item) => ({
            prontuarioId: prontuarioId,
            tipoRaioX: item.id,
            usuarioId: parseInt(id),
            observacao: observation
        }));

        try {
            await Promise.all(payloads.map((payload) => api.linkRayxConduct(payload)));
            get();
            handleClose();
            setObservation('');
            setRayxSelected({});
        } catch (error) {
            console.error('Erro ao enviar os exames:', error);
        }
    }

    return (
        <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            {!isSelected ? (
                <Box sx={style}>
                    <h3 style={{ textAlign: 'center' }}>Selecione os exames de Raio-x</h3>
                    <Box sx={{ maxHeight: '500px', overflow: 'auto' }}>
                        {groupName?.map((group) => (
                            <Box key={group} sx={{ mb: 2 }}>
                                <Typography variant="h5" gutterBottom>
                                    {group}
                                </Typography>
                                <Divider sx={{ mb: 1 }} />
                                <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(4, 1fr)', gap: '20px' }}>
                                    {examsArray[group]?.map((item) => (
                                        <Button
                                            key={item.id}
                                            variant={
                                                selectedButtons.some((selectedItem) => selectedItem.id === item.id)
                                                    ? 'contained'
                                                    : 'outlined'
                                            }
                                            onClick={() => handleButtonClick(item)}
                                        >
                                            {item.descricao}
                                        </Button>
                                    ))}
                                </Box>
                            </Box>
                        ))}
                    </Box>
                    <Button
                        variant="contained"
                        fullWidth
                        onClick={() => {
                            setIsSelected(true);
                        }}
                        sx={{ width: '300px', marginTop: '10px' }}
                    >
                        Confirmar seleção
                    </Button>
                </Box>
            ) : (
                <Box sx={styleSecond}>
                    <Typography variant="h4" gutterBottom sx={{ marginBottom: '10px' }}>
                        Raio-X - {name}
                    </Typography>
                    <Box sx={{ display: 'flex', gap: '10px', flexWrap: 'wrap' }}>
                        {selectedButtons.map((item) => (
                            <Button key={item.id} variant="contained">
                                {item?.descricao}
                            </Button>
                        ))}
                    </Box>
                    <form onSubmit={(e) => e.preventDefault()}>
                        <TextField
                            label="Observação"
                            rows={3}
                            multiline
                            fullWidth
                            sx={{ margin: '20px 0' }}
                            value={observation}
                            onChange={(e) => setObservation(e.target.value)}
                        />
                        <Box sx={{ display: 'flex', gap: '10px' }}>
                            <Button variant="outlined" color="error" fullWidth onClick={() => setIsSelected(false)}>
                                Voltar
                            </Button>
                            <Button variant="contained" fullWidth onClick={handleSubmit}>
                                Salvar
                            </Button>
                        </Box>
                    </form>
                </Box>
            )}
        </Modal>
    );
}
