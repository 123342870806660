import MainCard from 'ui-component/cards/MainCard';
import { Button, Grid, Box, Tooltip, TextField, FormControl, MenuItem, Typography, Autocomplete, CircularProgress } from '@mui/material';
import { gridSpacing } from 'store/constant';
import { useState, useEffect } from 'react';
import { useApi } from 'Service/axios';
import { DataGrid } from '@mui/x-data-grid';
import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';
import { toast } from 'react-toastify';
import TesteModal from './components/testeModal';
import TesteModalEdit from './components/testeModalEdit';
const { v4: uuidv4 } = require('uuid');
// ============================= INITIALSTATE =============================
const initialState = {
    movimento: new Date(),
    centroId: '',
    fichaId: '',
    medicoId: '',
    itens: []
};

const TesteForm = () => {
    // ============================= INSTÂNCIAS =============================
    const api = useApi();
    // ============================= STATES =============================
    const [campos, setCampos] = useState(initialState);
    const [openCreate, setOpenCreate] = useState(false);
    const [itens, setItens] = useState([]);
    const [listAllItens, setListAllItens] = useState([]);
    const [listAllAlmoxarifado, setListAllAlmoxarifado] = useState([]);
    const [listAllCentro, setListAllCentro] = useState([]);
    const [listAllLocalizacao, setListAllLocalizacao] = useState([]);
    const [filtredLocations, setFiltredLocations] = useState([]);
    const [listAllFichas, setListAllFichas] = useState([]);
    const [listAllMedicos, setListAllMedicos] = useState([]);
    const [lotesItem, setLotesItem] = useState([]);
    const [psicotropico, setPsicotropico] = useState(false);
    const [loading, setLoading] = useState(false);
    const [teste, setTeste] = useState(false);

    const columns = [
        {
            field: 'descricao',
            headerName: 'DESCRIÇÃO',
            flex: 2,
            valueGetter: (params) => {
                if (params.row?.iten?.descricao) {
                    return params.row?.iten?.descricao;
                } else {
                    return params?.row?.descricao;
                }
            }
        },
        {
            field: 'unidade',
            headerName: 'UN',
            flex: 2,
            valueGetter: (params) => {
                if (params.row?.iten?.unidade?.descricao) {
                    return params.row?.iten?.unidade?.descricao;
                } else {
                    return params?.row?.unidade;
                }
            }
        },
        { field: 'quantidade', headerName: 'QNTD', flex: 0.5 },
        {
            field: 'acao',
            headerName: 'AÇÕES',
            flex: 1,
            sortable: false,
            renderCell: (params) => (
                <TesteModalEdit
                    setItens={setItens}
                    row={params.row}
                    removerCampoItem={removerCampoItem}
                    listAllItens={listAllItens}
                    listAllAlmoxarifado={listAllAlmoxarifado}
                    listAllLocalizacao={listAllLocalizacao}
                    filtredLocations={filtredLocations}
                    setFiltredLocations={setFiltredLocations}
                    handleGetLotes={handleGetLotes}
                    lotesItem={lotesItem}
                    setPsicotropico={setPsicotropico}
                />
            )
        }
    ];

    // ============================= FUNÇÕES =============================

    const atualizarCampo = (campo, valor) => {
        setCampos((prev) => ({ ...prev, [campo]: valor }));
        if (campo === 'fichaId' && psicotropico) {
            const ficha = listAllFichas.find((el) => el.id === parseInt(valor));
            setCampos((prev) => ({ ...prev, medicoId: ficha?.id_medico }));
        }
    };

    const adicionarCampoItem = () => {
        setCampos((prevCampos) => ({
            ...prevCampos,
            itens: [
                ...prevCampos.itens,
                {
                    uuid: uuidv4(),
                    item_id: '',
                    descricao: '',
                    quantidade: 1,
                    unidade: '',
                    almoxarifado_id: '',
                    localizacao_id: '',
                    lote: '',
                    observacao: '',
                    disponivel: '',
                    kit_itens: '',
                    psicotropico: false,
                    ativo: true
                }
            ]
        }));
    };

    const removerCampoItem = (uuid) => {
        setItens((prevItens) => {
            const novosItens = [...prevItens];
            const filtredItens = novosItens.filter((item) => item.uuid != uuid);
            return filtredItens;
        });
        setCampos((prevCampos) => {
            const novosCampos = { ...prevCampos };
            novosCampos.itens.filter((item) => item.uuid != uuid);
            return novosCampos;
        });

        // fechar modal
        if (openCreate) {
            setOpenCreate(false);
        }
    };

    const atualizarCampoItem = async (uuid, campo, valor) => {
        setCampos((prevCampos) => {
            const novosCampos = { ...prevCampos };
            novosCampos.itens[campos?.itens.length - 1][campo] = valor;
            return novosCampos;
        });
        if (campo === 'almoxarifado_id') {
            setFiltredLocations(listAllLocalizacao.filter((localizacao) => localizacao.almoxarifado_id === valor));
        } else if (campo === 'localizacao_id') {
            const objeto = campos.itens.find((item) => item.uuid == uuid);
            const payload = { id: objeto.item_id, almoxarifado_id: objeto.almoxarifado_id, localizacao_id: objeto.localizacao_id };
            const lotes = await handleGetLotes(payload);
            setCampos((prevCampos) => {
                const novosCampos = { ...prevCampos };
                novosCampos.itens[campos?.itens.length - 1].disponivel = lotes?.data[0]?.quantidade_disponivel || 0;
                return novosCampos;
            });
        }
    };

    const handleSelectionModelChange = (newSelection) => {
        setSelectedItems(newSelection);
    };

    const handleClose = () => {
        setOpenCreate(false);
    };

    const handleOpenAndCreateCampoItem = () => {
        adicionarCampoItem();
        setOpenCreate(true);
    };

    // --- GET API ---
    const handleGetLotes = async (data) => {
        try {
            const lotes = await api.getLoteByItemId(data);
            setLotesItem(lotes.data);
            return lotes;
        } catch (error) {
            if (error.response.status === 404) return;
            toast.error(`Erro: ${error.message}`);
        }
    };

    const getAllData = async () => {
        try {
            const [responseAlmoxarifado, responseLocalizacao, responseMedicos, responseCentro, responseFichas, responseItens] =
                await Promise.all([
                    api.getAlmoxarifados(),
                    api.getAllLocalizacoes(),
                    api.getAllMedicos(),
                    api.getAllCentroCusto(),
                    api.getAllProntuario(),
                    api.getAllItems()
                ]);

            setListAllAlmoxarifado(responseAlmoxarifado.data);
            setListAllLocalizacao(responseLocalizacao.data);
            setListAllMedicos(responseMedicos.data);
            setListAllCentro(responseCentro.data);
            setListAllFichas(responseFichas.data);
            setListAllItens(responseItens.data);
        } catch (error) {
            toast.error(error?.message);
        }
    };

    const getDate = () => {
        const date = new Date();
        let currentDay = String(date.getDate()).padStart(2, '0');
        let currentMonth = String(date.getMonth() + 1).padStart(2, '0');
        let currentYear = date.getFullYear();
        let currentDate = `${currentYear}-${currentMonth}-${currentDay}`;
        setCampos((prev) => ({ ...prev, movimento: currentDate }));
    };

    //====== SUBMIT ======
    const handleSubmit = async (e, campo) => {
        e.preventDefault();
        if (!campo.centroId || !campo.fichaId) return toast.error('Dados de centro ou ficha faltando!');
        if (psicotropico && !campo.medicoId) return toast.error('Deve ser informado o médico responsável');
        const payload = { baixa: campo, itens: itens };

        if (!payload?.itens.find((itens) => itens?.lote) && psicotropico)
            return toast.error('Obrigatorio adicionar o lote do item no botao Editar item!');
        try {
            setLoading(true);
            await api.createSaidaArray(payload);
            setCampos({
                movimento: getDate(),
                centroId: '',
                fichaId: '',
                medicoId: '',
                itens: []
            });
            setItens([]);
            setOpenCreate(false);
            setTimeout(() => {
                setLoading(false);
            }, 1000);
            toast.success('Baixa cadastrada');
        } catch (error) {
            setTimeout(() => {
                setLoading(false);
            }, 1000);
            toast.error(`Erro ao criar baixa: ${error.response.data.message}`);
        }
    };

    useEffect(() => {
        setLoading(true);
        getAllData();
        getDate();
        setTimeout(() => {
            setLoading(false);
        }, 1000);
    }, []);

    // CONTROLE DO INPUT DE MEDICO PARA REMÉDIO PSICOTROPICO
    useEffect(() => {
        if (itens.length > 0) {
            const statusPsicotropico = itens.find((item) => item.lote !== '');
            const itemComLote = itens.find((item) => item?.iten?.lote && !item?.lote);
            if (itemComLote) {
                setTeste(true);
                toast.error(`Obrigatório informar o lote para o item: ${itemComLote?.iten?.descricao}`);
            } else {
                setTeste(false);
            }

            if (!statusPsicotropico) {
                setPsicotropico(false);
                setCampos((prev) => ({ ...prev, medicoId: '' }));
            } else {
                const ficha = listAllFichas.find((el) => el.id === parseInt(campos.fichaId));
                setCampos((prev) => ({ ...prev, medicoId: ficha?.id_medico }));
            }
        } else if (itens.length === 0) {
            setPsicotropico(false);
        }
    }, [itens]);

    return (
        <MainCard title="TESTE MALUCO">
            <Grid container spacing={gridSpacing}>
                <TesteModal
                    open={openCreate}
                    handleClose={handleClose}
                    setItens={setItens}
                    campos={campos}
                    adicionarCampoItem={adicionarCampoItem}
                    atualizarCampoItem={atualizarCampoItem}
                    removerCampoItem={removerCampoItem}
                    listAllItens={listAllItens}
                    listAllAlmoxarifado={listAllAlmoxarifado}
                    listAllLocalizacao={listAllLocalizacao}
                    filtredLocations={filtredLocations}
                    setFiltredLocations={setFiltredLocations}
                    handleGetLotes={handleGetLotes}
                    lotesItem={lotesItem}
                    setPsicotropico={setPsicotropico}
                />
                <Box sx={{ width: '80%', margin: '0 auto', display: 'flex', justifyContent: 'center' }}>
                    {loading && <CircularProgress />}
                    {!loading && (
                        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', marginLeft: '1em', marginTop: '1em' }}>
                            <Box>
                                <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            gap: '1em',
                                            width: '100%',
                                            marginBottom: '1em'
                                        }}
                                    >
                                        <FormControl fullWidth>
                                            <TextField
                                                type="date"
                                                label="Movimento"
                                                value={campos.movimento}
                                                disabled
                                                onChange={(e) => atualizarCampo('movimento', e.target.value)}
                                                InputLabelProps={{ shrink: true }}
                                            />
                                        </FormControl>
                                        <FormControl fullWidth>
                                            <TextField
                                                select
                                                label="Centro de custo"
                                                value={campos.centroId}
                                                onChange={(e) => atualizarCampo('centroId', e.target.value)}
                                                required
                                            >
                                                {listAllCentro.map((centro) => (
                                                    <MenuItem key={centro.id} value={centro.id}>
                                                        {centro.descricao}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </FormControl>
                                        <FormControl fullWidth>
                                            <Autocomplete
                                                options={listAllFichas}
                                                getOptionLabel={(paciente) => `${paciente.id} - ${paciente.paciente.nome_completo}`}
                                                value={listAllFichas.find((paciente) => paciente.id === campos.fichaId) || null}
                                                onChange={(e, newValue) => atualizarCampo('fichaId', newValue ? newValue.id : null)}
                                                renderInput={(params) => <TextField {...params} label="Ficha" />}
                                            />
                                        </FormControl>
                                        {psicotropico && (
                                            <FormControl fullWidth>
                                                <Autocomplete
                                                    options={listAllMedicos}
                                                    getOptionLabel={(item) => `${item.id} - ${item.nome}`}
                                                    value={listAllMedicos.find((item) => item.id === campos.medicoId) || null}
                                                    onChange={(e, newValue) => atualizarCampo('medicoId', newValue.id)}
                                                    renderInput={(params) => <TextField {...params} label="Medico" />}
                                                />
                                            </FormControl>
                                        )}
                                    </Box>
                                </Box>
                                <Box sx={{ width: '100%', marginTop: '2em', borderTop: '2px dashed #eee' }}>
                                    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', marginTop: '1em' }}>
                                        <Box>
                                            <Typography variant="h4">Relação de itens</Typography>
                                        </Box>
                                        <Box>
                                            <Tooltip title="Adicionar item">
                                                <Button variant="contained" color="success" onClick={(e) => handleOpenAndCreateCampoItem()}>
                                                    <AddIcon />
                                                </Button>
                                            </Tooltip>
                                        </Box>
                                    </Box>

                                    <Box sx={{ marginTop: '2em', display: 'flex', flexDirection: 'column', height: '300px' }}>
                                        <DataGrid
                                            rows={itens}
                                            columns={columns}
                                            hideFooter={true}
                                            onSelectionModelChange={() => handleSelectionModelChange(params.id)}
                                            getRowId={(row) => row.uuid}
                                        />

                                        <Box
                                            sx={{
                                                width: '40%',
                                                marginLeft: 'auto',
                                                marginTop: '1em',
                                                display: 'flex',
                                                justifyContent: 'flex-end',
                                                alignItems: 'center'
                                            }}
                                        >
                                            <Tooltip title="Salvar baixa">
                                                <Button
                                                    variant="contained"
                                                    color="success"
                                                    disabled={loading || teste}
                                                    onClick={(e) => handleSubmit(e, campos)}
                                                >
                                                    <SaveIcon />
                                                </Button>
                                            </Tooltip>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    )}
                </Box>
            </Grid>
        </MainCard>
    );
};

export default TesteForm;
