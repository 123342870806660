import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Divider, TextField } from '@mui/material';
import { toast } from 'react-toastify';
import { useApi } from 'Service/axios';
import { useEffect } from 'react';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: 2
};

export function InsertRooms({ getAllRooms, selectedRoom, open, setOpen }) {
    const [description, setDescription] = React.useState('');
    const [isEdit, setIsEdit] = React.useState(false);
    const handleClose = () => setOpen(false);
    const api = useApi();

    async function handleSubmit(e) {
        e.preventDefault();
        const toastId = toast.loading('Enviando...');
        try {
            await api.createRoom({ descricao: description });
            toast.update(toastId, { render: 'Sala cadastrada com sucesso', type: 'success', isLoading: false, autoClose: 2000 });
            setDescription('');
            getAllRooms();
        } catch (error) {
            console.log('🚀 ~ handleSubmit ~ error:', error);
            toast.update(toastId, { render: 'Falha ao cadastrar sala', type: 'error', isLoading: false, autoClose: 2000 });
        }
    }

    async function handleUpdate(e) {
        e.preventDefault();
        const toastId = toast.loading('Enviando...');
        try {
            await api.updateRoom(selectedRoom?.id, { descricao: description });
            toast.update(toastId, { render: 'Sala atualizada com sucesso', type: 'success', isLoading: false, autoClose: 2000 });
            setDescription('');
            getAllRooms();
        } catch (error) {
            console.log('🚀 ~ handleUpdate ~ error:', error);
            toast.update(toastId, { render: 'Falha ao atualizar sala', type: 'error', isLoading: false, autoClose: 2000 });
        }
    }

    useEffect(() => {
        if (selectedRoom !== null) {
            setIsEdit(true);
            setDescription(selectedRoom?.descricao);
        }

        return () => {
            setIsEdit(false);
            setDescription('');
        };
    }, [selectedRoom]);

    return (
        <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <Box sx={style}>
                <Box>
                    <h3 style={{ textAlign: 'center' }}>{isEdit ? 'Editar sala' : 'Cadastro de salas'}</h3>
                    <Divider />
                </Box>
                <form
                    onSubmit={isEdit ? handleUpdate : handleSubmit}
                    style={{ display: 'flex', flexDirection: 'column', gap: '1rem', marginTop: '1rem' }}
                >
                    <TextField
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        id="outlined-basic"
                        label="Descrição"
                        variant="outlined"
                    />
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Button variant="contained" sx={{ width: '200px', height: '45px' }} type="submit">
                            {isEdit ? 'Editar' : 'Cadastrar'}
                        </Button>
                    </Box>
                </form>
            </Box>
        </Modal>
    );
}
