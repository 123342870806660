import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    FormControlLabel,
    InputLabel,
    MenuItem,
    Radio,
    Select,
    TextField,
    Tooltip,
    Typography
} from '@mui/material';
import { Box } from '@mui/system';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import React from 'react';
import { useState } from 'react';
import {
    ButtonBox,
    CamposBox,
    CamposContainer,
    CamposEspecificosBox,
    CamposEspecificosContainer,
    CamposEspecificosFirstLine,
    CamposEspecificosSecondLine,
    CamposEspecificosSecondLineBox,
    MainBox,
    MainContainer
} from './style';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { useApi } from 'Service/axios';
import { IconCheck, IconCheckbox } from '@tabler/icons';
// ============================= INITIAL STATE PRO FORM =============================
const initialState = {
    nome: '',
    material: '',
    sigla: '',
    metodo: '',
    campos_especificos: []
};
const FormEditExame = ({ idExame }) => {
    // ============================= INSTÂNCIAS =============================
    const navigate = useNavigate();
    const api = useApi();
    // ============================= STATES =============================
    const [campos, setCampos] = useState([initialState]);
    const [exameCriado, setExameCriado] = useState(null);
    const [exame, setExame] = useState(null);
    const [openDeleteCampo, setOpenDeleteCampo] = useState(false);
    const [campoID, setCampoID] = useState(null);
    const [campoIndice, setCampoIndice] = useState(null);
    const [campoEspecificoInidice, setCampoEspecificoIndice] = useState(null);
    const [loading, setLoading] = useState(false);
    // ============================= FUNÇÕES =============================
    const getExame = async (id) => {
        const response = await api.getExameById(id);

        // Formatação de faixa etária
        await response.data.campos_especificos.map((item) => {
            item.faixaEtaria1 = '0';
            item.faixaEtaria2 = '0';
            return item;
        });

        setExame(response.data);
        setCampos([response.data]);
    };

    //////////////////////////////////////////////////////////////////
    const handleBack = () => {
        navigate('/main/laboratorio/exames');
    };

    const adicionarCampoEspecifico = (indiceCampo) => {
        setCampos((prevCampos) => {
            const novosCampos = [...prevCampos];
            novosCampos[indiceCampo].campos_especificos.push({
                nome_especifico: '',
                unidade: '',
                categoria: '',
                faixa_etaria_1: '',
                faixa_etaria_2: '',
                tipo_faixa_etaria: '',
                sexo: '',
                valores: ''
            });
            return novosCampos;
        });
    };

    const atualizarCampo = (indiceCampo, campo, valor) => {
        setCampos((prevCampos) => {
            const novosCampos = [...prevCampos];
            novosCampos[indiceCampo][campo] = valor;
            return novosCampos;
        });
    };

    const atualizarCampoEspecifico = (indiceCampo, indiceCampoEspecifico, campo, valor) => {
        setCampos((prevCampos) => {
            const novosCampos = [...prevCampos];
            novosCampos[indiceCampo].campos_especificos[indiceCampoEspecifico][campo] = valor;
            return novosCampos;
        });
    };

    const handleDeleteItem = (indiceCampo, indiceCampoEspecifico, campoID) => {
        // se não tiver campoID, está deletando um campo novo vazio q foi adicionado no edit
        if (campoID !== undefined) {
            setCampoID(campoID);
            setCampoIndice(indiceCampo);
            setCampoEspecificoIndice(indiceCampoEspecifico);
            setOpenDeleteCampo(true);
        } else {
            setCampos((prevCampos) => {
                const novosCampos = [...prevCampos];
                novosCampos[indiceCampo].campos_especificos.splice(indiceCampoEspecifico, 1);
                return novosCampos;
            });
        }
    };

    const handleCloseDeleteCampo = () => {
        setOpenDeleteCampo(false);
    };

    const removerCampoEspecifico = async (indiceCampo, indiceCampoEspecifico, campoEspecificoID) => {
        try {
            const deleteCampo = await api.deleteCampoEspecifico(campoEspecificoID);
            setCampos((prevCampos) => {
                const novosCampos = [...prevCampos];
                novosCampos[indiceCampo].campos_especificos.splice(indiceCampoEspecifico, 1);
                return novosCampos;
            });
            setOpenDeleteCampo(false);
            toast.success('Campo deletado com sucesso!');
        } catch (error) {
            toast.error(error.response.data.mensagem);
        }
    };

    // ============================= SUBMIT =============================

    const handleUpdateCampoEspecifico = async (campoID, campo) => {
        try {
            const response = await api.updateCamposEspecificos(idExame, campoID, campo);
            toast.success(response.data.mensagem);
            getExame(idExame);
            window.location.reload();
        } catch (error) {
            toast.error(error.response.data.mensagem);
        }
    };

    const handleCadastrarCampoEspecifico = async (campo) => {
        try {
            const response = await api.createCamposEspecificos(idExame, campo);
            toast.success(response.data.mensagem);
            getExame(idExame);
            window.location.reload();
        } catch (error) {
            toast.error(error.response.data.mensagem);
        }
    };

    const handleSubmit = async (e, campos) => {
        e.preventDefault();
        const dataPayload = campos[0];

        // Editar exame PAI
        try {
            setLoading(true);
            const response = await api.updateExame(dataPayload.id, dataPayload);
            setExameCriado(response.data);
            setLoading(false);
            window.location.reload();
            // Editar Campos específicos
            // setTimeout(async () => {
            //     try {
            //         for (let i = 0; i < dataPayload.campos_especificos.length; i++) {
            //             // se tiver id no campo especifico => PUT
            //             // se não tiver, é um campo novo => POST
            //             if (dataPayload?.campos_especificos[i]?.id) {
            //                 await api.updateCamposEspecificos(
            //                     dataPayload.id,
            //                     dataPayload?.campos_especificos[i]?.id,
            //                     dataPayload.campos_especificos[i]
            //                 );
            //             } else {
            //                 await api.createCamposEspecificos(dataPayload.id, dataPayload.campos_especificos[i]);
            //             }
            //         }
            //         toast.success('Exame e Campos específicos registrados com sucesso!');
            //         window.location.reload();
            //     } catch (error) {
            //         setLoading(false);
            //         toast.error(error.response.data.mensagem);
            //     }
            // }, 1000);
        } catch (error) {
            setLoading(false);
            toast.error(error.response.data.mensagem);
        }
    };

    useEffect(() => {
        getExame(idExame);
    }, [idExame]);

    return (
        <MainBox>
            <Dialog
                open={openDeleteCampo}
                onClose={handleCloseDeleteCampo}
                aria-labelledby="tituloDelete"
                aria-describedby="DescricaoDelete"
            >
                <DialogTitle id="tituloDelete">Deletar Campo Específico</DialogTitle>
                <DialogContent>
                    <DialogContentText id="DescricaoDelete">Tem certeza que deseja excluir o campo específico?</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => removerCampoEspecifico(campoIndice, campoEspecificoInidice, campoID)}>Sim</Button>
                    <Button onClick={handleCloseDeleteCampo}>Cancelar</Button>
                </DialogActions>
            </Dialog>
            {campos.map((campo, indiceCampo) => (
                <MainContainer key={indiceCampo}>
                    <CamposContainer>
                        <CamposBox>
                            <FormControl fullWidth>
                                <TextField
                                    label="Nome"
                                    type="text"
                                    value={campo.nome}
                                    onChange={(e) => atualizarCampo(indiceCampo, 'nome', e.target.value)}
                                    inputProps={{ style: { fontSize: 13 } }}
                                />
                            </FormControl>
                            <FormControl fullWidth>
                                <TextField
                                    label="Material"
                                    type="text"
                                    value={campo.material}
                                    onChange={(e) => atualizarCampo(indiceCampo, 'material', e.target.value)}
                                    inputProps={{ style: { fontSize: 13 } }}
                                />
                            </FormControl>
                        </CamposBox>
                        <CamposBox>
                            <FormControl fullWidth>
                                <TextField
                                    label="Sigla"
                                    type="text"
                                    value={campo.sigla}
                                    onChange={(e) => atualizarCampo(indiceCampo, 'sigla', e.target.value)}
                                    inputProps={{ style: { fontSize: 13 } }}
                                />
                            </FormControl>
                            <FormControl fullWidth>
                                <TextField
                                    label="Método"
                                    type="text"
                                    value={campo.metodo}
                                    onChange={(e) => atualizarCampo(indiceCampo, 'metodo', e.target.value)}
                                    inputProps={{ style: { fontSize: 13 } }}
                                />
                            </FormControl>
                        </CamposBox>
                    </CamposContainer>
                    {campo?.campos_especificos.map((campoEspecifico, indiceCampoEspecifico) => (
                        <CamposEspecificosContainer key={indiceCampoEspecifico}>
                            <CamposEspecificosBox>
                                <CamposEspecificosFirstLine>
                                    <FormControl sx={{ width: '45%' }}>
                                        <TextField
                                            label="Nome Campo"
                                            type="text"
                                            value={campoEspecifico.campo_nome}
                                            onChange={(e) =>
                                                atualizarCampoEspecifico(indiceCampo, indiceCampoEspecifico, 'campo_nome', e.target.value)
                                            }
                                            inputProps={{ style: { fontSize: 13 } }}
                                        />
                                    </FormControl>
                                    <FormControl sx={{ width: '45%' }}>
                                        <TextField
                                            label="Unidade"
                                            type="text"
                                            value={campoEspecifico.unidade}
                                            onChange={(e) =>
                                                atualizarCampoEspecifico(indiceCampo, indiceCampoEspecifico, 'unidade', e.target.value)
                                            }
                                            inputProps={{ style: { fontSize: 13 } }}
                                        />
                                    </FormControl>
                                    <FormControl sx={{ width: '45%' }}>
                                        <TextField
                                            label="Categoria"
                                            type="text"
                                            value={campoEspecifico.categoria}
                                            onChange={(e) =>
                                                atualizarCampoEspecifico(indiceCampo, indiceCampoEspecifico, 'categoria', e.target.value)
                                            }
                                            inputProps={{ style: { fontSize: 13 } }}
                                        />
                                    </FormControl>
                                </CamposEspecificosFirstLine>

                                <CamposEspecificosSecondLine>
                                    <CamposEspecificosSecondLineBox>
                                        <FormControl>
                                            <TextField
                                                sx={{ maxWidth: '100px' }}
                                                label="De"
                                                type="number"
                                                value={campoEspecifico.faixa_etaria_1}
                                                onChange={(e) =>
                                                    atualizarCampoEspecifico(
                                                        indiceCampo,
                                                        indiceCampoEspecifico,
                                                        'faixa_etaria_1',
                                                        e.target.value
                                                    )
                                                }
                                                inputProps={{ style: { fontSize: 13 } }}
                                            />
                                        </FormControl>
                                        <FormControl sx={{ marginRight: '1em' }}>
                                            <Typography>Até</Typography>
                                        </FormControl>
                                        <FormControl>
                                            <TextField
                                                sx={{ maxWidth: '100px' }}
                                                label="Até"
                                                type="number"
                                                value={campoEspecifico.faixa_etaria_2}
                                                onChange={(e) =>
                                                    atualizarCampoEspecifico(
                                                        indiceCampo,
                                                        indiceCampoEspecifico,
                                                        'faixa_etaria_2',
                                                        e.target.value
                                                    )
                                                }
                                                inputProps={{ style: { fontSize: 13 } }}
                                            />
                                        </FormControl>
                                        <FormControl>
                                            <InputLabel>Tipo</InputLabel>
                                            <Select
                                                sx={{ width: '100px' }}
                                                type="text"
                                                value={campoEspecifico.tipo_faixa_etaria}
                                                onChange={(e) =>
                                                    atualizarCampoEspecifico(
                                                        indiceCampo,
                                                        indiceCampoEspecifico,
                                                        'tipo_faixa_etaria',
                                                        e.target.value
                                                    )
                                                }
                                                inputProps={{ style: { fontSize: 13 } }}
                                            >
                                                <MenuItem value={'1'} inputProps={{ style: { fontSize: 13 } }}>
                                                    Anos
                                                </MenuItem>
                                                <MenuItem value={'2'} inputProps={{ style: { fontSize: 13 } }}>
                                                    Meses
                                                </MenuItem>
                                                <MenuItem value={'3'} inputProps={{ style: { fontSize: 13 } }}>
                                                    Dias
                                                </MenuItem>
                                            </Select>
                                        </FormControl>
                                    </CamposEspecificosSecondLineBox>
                                    <FormControlLabel
                                        value="1"
                                        control={
                                            <Radio
                                                checked={campoEspecifico.sexo === '1'}
                                                onChange={(e) =>
                                                    atualizarCampoEspecifico(indiceCampo, indiceCampoEspecifico, 'sexo', e.target.value)
                                                }
                                                value="1"
                                                name="Masculino"
                                                inputProps={{ 'aria-label': 'Masculino' }}
                                            />
                                        }
                                        label="Masculino"
                                    />
                                    <FormControlLabel
                                        value="2"
                                        control={
                                            <Radio
                                                checked={campoEspecifico.sexo === '2'}
                                                onChange={(e) =>
                                                    atualizarCampoEspecifico(indiceCampo, indiceCampoEspecifico, 'sexo', e.target.value)
                                                }
                                                value="2"
                                                name="Feminino"
                                                inputProps={{ 'aria-label': 'Feminino' }}
                                            />
                                        }
                                        label="Feminino"
                                    />
                                    <FormControlLabel
                                        value="3"
                                        control={
                                            <Radio
                                                checked={campoEspecifico.sexo === '3'}
                                                onChange={(e) =>
                                                    atualizarCampoEspecifico(indiceCampo, indiceCampoEspecifico, 'sexo', e.target.value)
                                                }
                                                value="3"
                                                name="Ambos"
                                                inputProps={{ 'aria-label': 'Ambos' }}
                                            />
                                        }
                                        label="Ambos"
                                    />
                                </CamposEspecificosSecondLine>
                            </CamposEspecificosBox>

                            <FormControl>
                                <TextField
                                    label="Valores"
                                    type="text"
                                    value={campoEspecifico.valores}
                                    onChange={(e) =>
                                        atualizarCampoEspecifico(indiceCampo, indiceCampoEspecifico, 'valores', e.target.value)
                                    }
                                    inputProps={{ style: { fontSize: 13 } }}
                                />
                            </FormControl>
                            <ButtonBox>
                                {campoEspecifico.id ? (
                                    <Tooltip sx={{ marginRight: '.4em' }} title="Atualizar Campo Especifico">
                                        <Button
                                            variant="contained"
                                            color="success"
                                            onClick={() => handleUpdateCampoEspecifico(campoEspecifico.id, campoEspecifico)}
                                        >
                                            <IconCheck />
                                        </Button>
                                    </Tooltip>
                                ) : (
                                    <Tooltip sx={{ marginRight: '.4em' }} title="Cadastar Campo Especifico">
                                        <Button variant="contained" onClick={() => handleCadastrarCampoEspecifico(campoEspecifico)}>
                                            <IconCheckbox />
                                        </Button>
                                    </Tooltip>
                                )}
                                <Tooltip title="Remover Campo Específico">
                                    <Button
                                        variant="contained"
                                        color="error"
                                        onClick={(e) => handleDeleteItem(indiceCampo, indiceCampoEspecifico, campoEspecifico.id)}
                                    >
                                        <DeleteIcon />
                                    </Button>
                                </Tooltip>
                            </ButtonBox>
                        </CamposEspecificosContainer>
                    ))}
                    <Box sx={{ display: 'flex', flexDirection: 'column', marginTop: '2rem', width: '100%' }}>
                        <Tooltip title="Adicionar Campo Específico">
                            <Button variant="contained" onClick={() => adicionarCampoEspecifico(indiceCampo)}>
                                <AddIcon />
                            </Button>
                        </Tooltip>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginTop: '1rem' }}>
                            <Button color="error" onClick={() => handleBack(indiceCampo)}>
                                Voltar para tabela
                            </Button>
                            <Tooltip title="Atualizar todos os campos">
                                <Button variant="contained" onClick={(e) => handleSubmit(e, campos)} disabled={loading}>
                                    {loading ? 'Aguarde...' : 'Editar Exame'}
                                </Button>
                            </Tooltip>
                        </Box>
                    </Box>
                </MainContainer>
            ))}
        </MainBox>
    );
};

export default FormEditExame;
