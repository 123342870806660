import { lazy } from 'react';
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import Register from 'views/pages/authentication/authentication3/Register3';
import EditUser from 'views/users/EditUser';
import Lobby from 'views/Lobby';
import CriarProntuario from 'views/CriarProntuario';
import EditarProntuario from 'components/RecepcaoForms/EditProntuario/inedx';
import CadastroConvenio from 'views/CadastroConvenio';
import TelaChamadas from 'views/TelaChamadas';
import Prontuario from 'views/Lobby/Prontuario/Prontuario';
import Laboratorio from 'views/Laboratorio';
import Exames from 'views/Laboratorio/Exames';
import NovoExame from 'views/Laboratorio/Exames/Novo';
import FichaLaboratorio from 'views/Laboratorio/Ficha';
import ResultadoExame from 'views/Laboratorio/ResultadoExame';
import EditarExame from 'views/Laboratorio/Exames/Edit';
import Almoxarifado from 'views/Estoque/Almoxarifado';
import Localizacao from 'views/Estoque/Localizacao';
import Categoria from 'views/Estoque/Categoria';
import Unidade from 'views/Estoque/Unidade';
import Item from 'views/Estoque/Item';
import Fornecedor from 'views/Estoque/Fornecedor';
import EntradaEstoque from 'views/Estoque/Entrada';
import NovaEntrada from 'views/Estoque/Entrada/NovaEntrada';
import EditarEntrada from 'views/Estoque/Entrada/EditarEntrada';
import BaixaManual from 'views/Estoque/Movimentos/Baixa';
import EntradaManual from 'views/Estoque/Movimentos/Entrada';
import TransferenciaManual from 'views/Estoque/Movimentos/Transferencia';
import SaldoGeral from 'views/Estoque/SaldoGeral';
import Kardex from 'views/Estoque/Kardex';
import Kits from 'views/Kits/index';

import CentroCustos from 'views/Estoque/CentroCusto';
import RelatoriosLobby from 'views/Lobby/Relatorios';
import RelatoriosEstoque from 'views/Estoque/Relatorios';
import BaixaFicha from 'views/Estoque/Movimentos/BaixaFicha';
import NovaBaixaFicha from 'views/Estoque/Movimentos/BaixaFicha/NovaBaixa';
import EditarBaixaFicha from 'views/Estoque/Movimentos/BaixaFicha/EditarBaixa';
import RelatoriosEstoqueLaboratorio from 'views/Laboratorio/Relatorios';
import EditarEntradaKits from 'views/Kits/EditarEntrada';
import NovaEntradaKits from 'views/Kits/NovaEntrada';
import TriagemLab from 'views/Laboratorio/Triagem/index';
import FichaTriagem from 'components/Laboratório/TabelaTriagem/FichaTriagem/index';
import TelaTriagem from 'components/Laboratório/TabelaTriagem/TelaTriagem';
import ConsultaLab from 'views/Laboratorio/Triagem copy';
import FichaTriagemConsulta from 'components/Laboratório/TabelaTriagem copy/FichaTriagem';
import RegisterMedico from 'views/pages/authentication/auth-forms/RegisterMedico';
import Extorno from 'views/Estoque/Extorno';
import TesteForm from '../views/Estoque/Movimentos/BaixaFicha/testesForm/testeForm';
import { CustomerService } from 'views/X-ray/CustomerService';
import { RegisterXRay } from 'views/X-ray/RegisterXRay';
import { CustomerServiceProcedimentos } from 'views/procedimentos/CustomerService';
import { RegisterProcedimentos } from 'views/procedimentos/RegisterProcedimento';
import { Clinic } from 'views/DoctorsOffice/Clinic';
import { MedicalAppointment } from 'views/DoctorsOffice/MedicalAppointment';
import { MedicalRecord } from 'views/DoctorsOffice/MedicalRecord';
import { MedicalContuct } from 'views/DoctorsOffice/MedicalConduct';
import { CustomerServiceFarmaciaAtendimentos } from 'views/Estoque/Atendimentos/CustomerService';
import { WriteOffItensFromStock } from 'views/Estoque/Atendimentos/WriteOffItens';
import { ConsultServices } from 'views/Estoque/ConsultaAtendimentos/CustomerService';
import { ConsultItems } from 'views/Estoque/ConsultaAtendimentos/WriteOffItens';
import { CustomerServiceLaboratory } from 'views/Laboratorio/Atendimentos/CustomerService';
import { WriteOffExamsLab } from 'views/Laboratorio/Atendimentos/WriteOffItens';
import { ConsultServicesLaboratory } from 'views/Laboratorio/ConsultaAtendimentos/CustomerService';
import { ConsultExamsLab } from 'views/Laboratorio/ConsultaAtendimentos/WriteOffItens';
import { CustomerServiceProcedimentosConsult } from 'views/procedimentos/Consulta/CustomerService';
import { ConsultaProcedimentos } from 'views/procedimentos/Consulta/WriteOffItens';
import { CustomerServiceExamesRaioxConsult } from 'views/X-ray/Consulta/CustomerService';
import { ConsultaRaiox } from 'views/X-ray/Consulta/WriteOffItens';
import { Rooms } from 'views/enfermagem/Rooms';
import { NursingService } from 'views/enfermagem/Nurse';
import { GeneralNursing } from 'views/enfermagem/GeneralNursing';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/main',
    element: <MainLayout />,
    children: [
        {
            path: '/main/dashboard',
            element: <DashboardDefault />
        },
        {
            path: '/main/register',
            element: <Register />
        },
        {
            path: '/main/cadastro-medicos',
            element: <RegisterMedico />
        },
        {
            path: '/main/edituser',
            element: <EditUser />
        },
        {
            path: '/main/lobby',
            element: <Lobby />
        },
        {
            path: '/main/relatorios-prontuarios',
            element: <RelatoriosLobby />
        },
        {
            path: '/main/cadastro/convenio',
            element: <CadastroConvenio />
        },
        {
            path: '/main/criar_prontuario',
            element: <CriarProntuario />
        },
        {
            path: '/main/prontuario/:id',
            element: <Prontuario />
        },
        {
            path: '/main/editar_prontuario/:id',
            element: <EditarProntuario />
        },
        {
            path: '/main/atendimentos',
            element: <TelaChamadas />
        },
        {
            path: '/main/laboratorio/atendimentos',
            element: <Laboratorio />
        },
        {
            path: '/main/triagem',
            element: <TriagemLab />
        },
        {
            path: '/main/consulta',
            element: <ConsultaLab />
        },
        {
            path: '/main/laboratorio/triagem/:ficha/prontuario/:id/',
            element: <FichaTriagem />
        },
        {
            path: '/main/laboratorio/triagem/:ficha',
            element: <FichaTriagemConsulta />
        },
        {
            path: '/main/laboratorio/triagem/tela-triagem/:ficha/prontuario/:id/',
            element: <TelaTriagem />
        },
        {
            path: '/main/laboratorio/exames',
            element: <Exames />
        },
        {
            path: '/main/laboratorio/exame/novo',
            element: <NovoExame />
        },
        {
            path: '/main/laboratorio/exame/:id',
            element: <EditarExame />
        },
        {
            path: '/main/laboratorio/ficha/:id/prontuario/:prontuarioId',
            element: <FichaLaboratorio />
        },
        {
            path: '/main/laboratorio/ficha/:idFicha/resultado/:id/prontuario/:prontuarioId/:idExame',
            element: <ResultadoExame />
        },
        {
            path: '/main/estoque/cadastros/almoxarifado',
            element: <Almoxarifado />
        },
        {
            path: '/main/estoque/cadastros/localizacao',
            element: <Localizacao />
        },
        {
            path: '/main/estoque/cadastros/categoria',
            element: <Categoria />
        },
        {
            path: '/main/estoque/cadastros/unidade',
            element: <Unidade />
        },
        {
            path: '/main/estoque/cadastros/item',
            element: <Item />
        },
        {
            path: '/main/estoque/cadastro/kits',
            element: <Kits />
        },
        {
            path: '/main/estoque/nova-entrada/cadastro/kits',
            element: <NovaEntradaKits />
        },
        {
            path: '/main/estoque/entrada/kits/:id',
            element: <EditarEntradaKits />
        },
        {
            path: '/main/estoque/cadastros/fornecedor',
            element: <Fornecedor />
        },
        {
            path: '/main/estoque/cadastros/centro-custo',
            element: <CentroCustos />
        },
        {
            path: '/main/estoque/entrada',
            element: <EntradaEstoque />
        },
        {
            path: '/main/estoque/entrada/cadastro',
            element: <NovaEntrada />
        },
        {
            path: '/main/estoque/entrada/:id',
            element: <EditarEntrada />
        },
        {
            path: '/main/estoque/baixa-manual',
            element: <BaixaManual />
        },
        {
            path: '/main/estoque/baixa-ficha',
            element: <BaixaFicha />
        },
        {
            path: '/main/estoque/estorno',
            element: <Extorno />
        },
        {
            path: '/main/estoque/baixa-ficha/cadastro',
            element: <NovaBaixaFicha />
        },
        {
            path: '/main/estoque/baixa-ficha/:id',
            element: <EditarBaixaFicha />
        },
        {
            path: '/main/estoque/entrada-manual',
            element: <EntradaManual />
        },
        {
            path: '/main/estoque/transferencia-manual',
            element: <TransferenciaManual />
        },
        {
            path: '/main/estoque/saldo-geral',
            element: <SaldoGeral />
        },
        {
            path: '/main/estoque/kardex',
            element: <Kardex />
        },
        {
            path: '/main/estoque/relatorios',
            element: <RelatoriosEstoque />
        },
        {
            path: '/main/laboratorio/relatorios',
            element: <RelatoriosEstoqueLaboratorio />
        },
        {
            path: '/main/atendimento/raio-x',
            element: <CustomerService />
        },
        {
            path: '/main/cadastro/raio-x',
            element: <RegisterXRay />
        },
        {
            path: '/main/atendimento/procedimentos',
            element: <CustomerServiceProcedimentos />
        },
        {
            path: '/main/cadastro/procedimentos',
            element: <RegisterProcedimentos />
        },
        {
            path: '/main/consultorio/salas',
            element: <Clinic />
        },
        {
            path: '/main/consultorio/atendimento',
            element: <MedicalAppointment />
        },
        {
            path: '/main/consultorio/ficha/:prontuarioId/:pacienteId/:name',
            element: <MedicalRecord />
        },
        {
            path: '/main/consultorio/conduta/:prontuarioId/:name',
            element: <MedicalContuct />
        },
        {
            path: '/main/estoque/farmacia/atendimentos',
            element: <CustomerServiceFarmaciaAtendimentos />
        },
        {
            path: '/main/estoque/farmacia/atendimentos/baixa/:id/:nome',
            element: <WriteOffItensFromStock />
        },
        {
            path: '/main/estoque/farmacia/atendimentos/consulta',
            element: <ConsultServices />
        },
        {
            path: '/main/estoque/farmacia/atendimentos/consulta/:id/:nome',
            element: <ConsultItems />
        },
        {
            path: '/main/laboratorio/controle/atendimentos',
            element: <CustomerServiceLaboratory />
        },
        {
            path: '/main/laboratorio/controle/atendimento/:id/:nome',
            element: <WriteOffExamsLab />
        },
        {
            path: '/main/laboratorio/controle/atendimentos/consulta',
            element: <ConsultServicesLaboratory />
        },
        {
            path: '/main/laboratorio/controle/atendimentos/consulta/:id/:nome',
            element: <ConsultExamsLab />
        },
        {
            path: '/main/atendimento/procedimentos/consulta',
            element: <CustomerServiceProcedimentosConsult />
        },
        {
            path: '/main/atendimento/procedimentos/consulta/:id/:nome',
            element: <ConsultaProcedimentos />
        },
        {
            path: '/main/atendimento/raio-x/consulta',
            element: <CustomerServiceExamesRaioxConsult />
        },
        {
            path: '/main/atendimento/raio-x/consulta/:id/:nome',
            element: <ConsultaRaiox />
        },
        {
            path: '/main/enfermagem/salas',
            element: <Rooms />
        },
        {
            path: '/main/enfermagem',
            element: <NursingService />
        },
        {
            path: '/main/enfermagem/geral/:prontuarioId',
            element: <GeneralNursing />
        }
    ]
};

export default MainRoutes;
