import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { InputArea } from './styles';
import { MenuItem, TextField } from '@mui/material';
import { useApi } from 'Service/axios';
import { toast } from 'react-toastify';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: 2
};

export function AddExamModal({ get, currentRow, open, setOpen, setCurrentRow }) {
    const api = useApi();

    const [procedimento, setProcedimento] = useState('');
    const [codigo, setCodigo] = useState('');
    const [tipo, setTipo] = useState('');
    const [allTipos, setAllTipos] = useState([]);

    const handleClose = () => {
        setCurrentRow(null);
        setOpen(false);
        setTipo(null);
    };

    const getTipos = async () => {
        try {
            const { data } = await api.getAllTipoProcedimento();
            setAllTipos(data);
        } catch (error) {
            toast.error(error.message);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (procedimento?.trim().length == 0 || String(tipo)?.trim().length == 0) {
            return toast.warning('A descrição e tipo não pode ser vazio');
        }

        try {
            await api.createProcedimento({ descricao: procedimento, codigo: codigo, tipo_id: parseInt(tipo) });
            handleClose();
            toast.success('Procedimento cadastrado com sucesso!');
            get();
            setProcedimento('');
            setCodigo('');
            setTipo('');
        } catch (error) {
            toast.error(error.message);
        }
    };

    const handleUpdate = async (e) => {
        e.preventDefault();
        if (procedimento?.trim().length == 0 || String(tipo)?.trim().length == 0) {
            return toast.warning('A descrição e tipo não pode ser vazio');
        }
        try {
            await api.updateProcedimento(currentRow.id, { descricao: procedimento, codigo: codigo, tipo_id: parseInt(tipo) });
            handleClose();
            toast.success('Procedimento atualizado com sucesso!');
            get();
        } catch (error) {
            toast.error(error.message);
        }
    };

    useEffect(() => {
        getTipos();
    }, [open]);

    useEffect(() => {
        if (currentRow) {
            setProcedimento(currentRow.descricao || '');
            setCodigo(currentRow.codigo || '');
            setTipo(currentRow?.tipo_id || '');
        } else {
            setProcedimento('');
            setCodigo('');
            setTipo('');
        }
    }, [currentRow]);

    return (
        <div>
            <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h4" component="h2">
                        {currentRow ? 'Edição de Procedimento' : 'Cadastro de Procedimento'}
                    </Typography>
                    <InputArea onSubmit={currentRow ? handleUpdate : handleSubmit}>
                        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                            <Box sx={{ display: 'flex', gap: '2rem' }}>
                                <TextField label="Procedimento" value={procedimento} onChange={(e) => setProcedimento(e.target.value)} />
                                <TextField label="Código" value={codigo} onChange={(e) => setCodigo(e.target.value)} />
                            </Box>
                            <Box>
                                <TextField
                                    select
                                    label="Tipo"
                                    value={tipo}
                                    onChange={(e) => setTipo(e.target.value)}
                                    sx={{ minWidth: '120px' }}
                                >
                                    {allTipos.map((tipo) => (
                                        <MenuItem key={tipo.id} value={tipo.id}>
                                            {tipo.descricao}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Box>
                            <Button variant="contained" type="submit">
                                {currentRow ? 'Editar' : 'Cadastrar'}
                            </Button>
                        </Box>
                    </InputArea>
                </Box>
            </Modal>
        </div>
    );
}
