import { Button, Box, Typography, FormControl, TextField, MenuItem, Dialog, Autocomplete } from '@mui/material';
import { useApi } from 'Service/axios';
import { CustomFlexBox } from './style';
import { DataGrid } from '@mui/x-data-grid';
import ActionButton from '../ActionButton';
import { useState } from 'react';
import { toast } from 'react-toastify';

const FilterDrawer = ({ open, setOpen, itens, saidas, setBaixa, handleChangeLocations, handleGetLotes, setHasLote, setIsEditing }) => {
    // ============================= INSTÂNCIAS ==========================
    const api = useApi();
    // ============================= STATES ==============================
    const [filtredValues, setFiltredValues] = useState([]);
    const [selectedItem, setSelectedItem] = useState('');
    const [selectedDate, setSelectedDate] = useState({ firstDate: '', lastDate: '' });
    const columns = [
        { field: 'id', headerName: 'ID', flex: 0.3 },
        { field: 'item', headerName: 'Item', flex: 2, valueGetter: (params) => params.row.item.descricao },
        { field: 'data', headerName: 'Data', flex: 1, valueGetter: (params) => formatarData(params.row.data) },
        { field: 'quantidade', headerName: 'Quantidade', flex: 1 },
        {
            field: 'paciente',
            headerName: 'Paciente',
            flex: 1,
            valueGetter: (params) => (params.row.prontuario ? params.row.prontuario.paciente.nome_completo : '')
        },
        {
            field: 'responsavel',
            headerName: 'Responsável',
            flex: 1,
            valueGetter: (params) => (params.row.usuario ? params.row.usuario.nome : '')
        },
        {
            field: 'acao',
            headerName: 'AÇÕES',
            flex: 1,
            sortable: false,
            renderCell: (params) => (
                <ActionButton
                    row={params.row}
                    setBaixa={setBaixa}
                    setOpen={setOpen}
                    resetStates={resetStates}
                    handleChangeLocations={handleChangeLocations}
                    handleGetLotes={handleGetLotes}
                    setHasLote={setHasLote}
                    setIsEditing={setIsEditing}
                />
            )
        }
    ];
    // ============================= FUNÇÕES =============================
    const handleSelectionModelChange = (newSelection) => {
        setSelectedItems(newSelection);
    };

    const formatarData = (date) => {
        const dateObj = new Date(date);
        const year = dateObj.getUTCFullYear();
        const month = ('0' + (dateObj.getUTCMonth() + 1)).slice(-2);
        const day = ('0' + dateObj.getUTCDate()).slice(-2);
        return `${day}/${month}/${year}`;
    };

    const handleChangeFirstDate = (value) => {
        setSelectedDate({ ...selectedDate, firstDate: value });
    };

    const handleChangeLastDate = (value) => {
        setSelectedDate({ ...selectedDate, lastDate: value });
    };

    const resetStates = () => {
        setOpen(false);
        setFiltredValues([]);
        setSelectedItem('');
        setSelectedDate({ firstDate: '', lastDate: '' });
    };

    const handleFilterValues = async (values) => {
        let filtredItens;
        let firstDate = selectedDate.firstDate;
        let lastDate = selectedDate.lastDate;
        if (values === '') {
            toast.error('Deve ser informado um item');
            return;
        }
        try {
            const response = await api.getFilterItemsByIdAndDate(values?.id, firstDate, lastDate);
            if (response.status === 200) {
                setFiltredValues(response.data);
            }
        } catch (error) {}
    };

    return (
        <Dialog open={open} onClose={() => setOpen(false)} fullWidth={true} maxWidth={'md'}>
            <Box sx={{ padding: '1em', display: 'flex', flexDirection: 'column' }}>
                <Box sx={{ width: '100%' }}>
                    <CustomFlexBox>
                        <FormControl fullWidth>
                            <Autocomplete
                                options={itens}
                                getOptionLabel={(item) => `${item.id} - ${item.descricao} - ${!item.apelido ? '' : item.apelido}`}
                                value={itens.find((item) => item.id === selectedItem?.id) || null}
                                onChange={(e, newValue) => setSelectedItem(newValue)}
                                renderInput={(params) => <TextField {...params} label="Item" />}
                            />
                            {/* <TextField select label="Item" value={selectedItem} onChange={(e) => setSelectedItem(e.target.value)}>
                                {itens.map((item) => (
                                    <MenuItem key={item.id} value={item.id}>
                                        {item.descricao}
                                    </MenuItem>
                                ))}
                            </TextField> */}
                        </FormControl>
                    </CustomFlexBox>
                    <CustomFlexBox flex={'flex'} justifyContent={'space-between'} alignItems={'center'} gap={'1em'}>
                        <FormControl fullWidth>
                            <TextField
                                type="date"
                                label="De"
                                value={selectedDate.firstDate}
                                onChange={(e) => handleChangeFirstDate(e.target.value)}
                                InputLabelProps={{ shrink: true }}
                            />
                        </FormControl>
                        <Typography variant="body">Até</Typography>
                        <FormControl fullWidth>
                            <TextField
                                type="date"
                                label="Até"
                                value={selectedDate.lastDate}
                                onChange={(e) => handleChangeLastDate(e.target.value)}
                                InputLabelProps={{ shrink: true }}
                            />
                        </FormControl>
                    </CustomFlexBox>
                    <CustomFlexBox flex={'flex'} justifyContent={'flex-end'}>
                        <Button onClick={() => handleFilterValues(selectedItem)}>FILTRAR</Button>
                    </CustomFlexBox>
                </Box>
                <Box sx={{ height: '300px' }}>
                    <DataGrid
                        rows={filtredValues}
                        columns={columns}
                        hideFooter={true}
                        onSelectionModelChange={() => handleSelectionModelChange(params.id)}
                    />
                </Box>
            </Box>
        </Dialog>
    );
};

export default FilterDrawer;
