import { Typography, styled } from '@mui/material';
import { Box } from '@mui/material';
import { width } from '@mui/system';

export const FormBox = styled(Box)({
    width: '100%',
    minHeight: '80vh',
    display: 'flex',
    marginLeft: '1em',
    flexDirection: 'column',
    borderTop: '2px dashed #000'
});

export const FieldsContainer = styled(Box)({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem'
});

export const FormInputBox = styled(Box)({
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between'
});

export const FieldBox = styled(Box)({
    width: '45%'
});

export const HeaderBox = styled(Box)({
    marginTop: '.3em',
    width: '100%'
});

export const HeaderBoxSecondary = styled(Box)({
    display: 'flex',
    justifyContent: 'space-between',
    width: '32%'
});

export const HalfBox = styled(Box)({
    display: 'flex',
    width: '50%',
    gap: '5px'
});

export const TableBox = styled(Box)({
    marginTop: '.1em'
});

export const HeaderInfoBox = styled(Box)({
    gap: '5px',
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%'
});

export const ExameInfoBox = styled(Box)({
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between'
});

export const InfoBox = styled(Box)({
    display: 'flex',
    gap: '5px'
});

export const InfoBoxMedium = styled(Box)({
    display: 'flex',
    gap: '5px',
    width: '45%'
});

export const InfoBoxMediumEnd = styled(Box)({
    gap: '5px',
    width: '45%',
    display: 'flex',
    justifyContent: 'flex-end'
});
