import { CircularProgress, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useApi } from 'Service/axios';
import SantaCasaLogo from '../../../../assets/images/Logos/logo2.png';
import formater from 'utils/DateDDMMYYY';

const RelatorioPacientePorPeriodo = () => {
    // ---- INSTÂNCIAS ----
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const productId = searchParams.get('productId');
    const firstDate = searchParams.get('firstDate');
    const lastDate = searchParams.get('lastDate');
    const typeDate = searchParams.get('tipo');
    const api = useApi();
    // ---- STATES ----
    const [movSumarizado, setMovSumarizado] = useState([]);
    const [loading, setLoading] = useState(true);
    const [groupOfItems, setGroupOfItems] = useState([]);
    const [entrada, setEntrada] = useState([]);
    const [saida, setSaida] = useState([]);
    const [saldoSum, setSaldoSum] = useState([]);
    const [saldoGeral, setSaldoGeral] = useState(null);

    // ---- FUNÇÕES ----

    const resetLoading = () => {
        setTimeout(() => {
            setLoading(false);
        }, 1000);
    };

    const getMovSumarizado = async (id, firstDate, lastDate) => {
        try {
            const response = await api.getKardex(id, firstDate, lastDate);
            if (response.data.kardex.length === 0) return resetLoading();
            setSaldoGeral(response.data.saldoGeral);
            setMovSumarizado(response.data.kardex);
            const descricaoArrays = {};
            setEntrada(0);
            setSaida(0);
            // Iterar sobre o array original
            response?.data?.kardex.forEach((movimento) => {
                if (movimento.tipo === 'Entrada') {
                    setEntrada((prev) => prev + 1);
                } else {
                    setSaida((prev) => prev + 1);
                }
                const descricao = movimento?.item?.descricao;

                if (!descricaoArrays[descricao]) {
                    descricaoArrays[descricao] = [];
                }

                descricaoArrays[descricao].push(movimento);
            });

            // Converter o objeto de arrays de volta para um array
            const finalRes = Object.values(descricaoArrays);
            setGroupOfItems(finalRes);

            resetLoading();
        } catch (error) {
            toast.error(`Erro ao buscar dados: ${error.message}`);
        }
    };

    const getAllMovSumarizado = async (type, firstDate, lastDate) => {
        try {
            const response = await api.getAllMoves(Number(type), firstDate, lastDate);
            setMovSumarizado(response.data.kardex);

            if (response.data.kardex.length === 0) return resetLoading();

            const descricaoArrays = {};

            // Iterar sobre o array original
            response.data.kardex.forEach((movimento, index) => {
                const descricao = movimento?.item?.descricao;

                if (!descricaoArrays[descricao]) {
                    descricaoArrays[descricao] = [];
                }

                descricaoArrays[descricao].push(movimento);
            });

            // Converter o objeto de arrays de volta para um array
            const finalRes = Object.values(descricaoArrays);

            // montagem da contagem de saldos, entradas e saidas
            finalRes.forEach((item, index) => {
                let entradaCount = 0;
                let saidaCount = 0;

                item.forEach((el, index2) => {
                    if (el.tipo === 'Entrada' || el.tipo === 'Extorno') {
                        entradaCount++;
                    } else {
                        saidaCount++;
                    }

                    if (index2 === item.length - 1) {
                        setSaldoSum((prev) => {
                            const newSaldo = [...prev];
                            newSaldo[index] = { ...newSaldo[index], sum: el?.saldo };
                            return newSaldo;
                        });
                    }
                });

                // Atualizar os estados fora do loop interno
                setEntrada((prev) => {
                    const newEntrada = [...prev];
                    newEntrada[index] = { ...newEntrada[index], sum: entradaCount };
                    return newEntrada;
                });

                setSaida((prev) => {
                    const newSaida = [...prev];
                    newSaida[index] = { ...newSaida[index], sum: saidaCount };
                    return newSaida;
                });
            });

            setGroupOfItems(finalRes);
            resetLoading();
        } catch (error) {
            toast.error(`Erro ao buscar dados: ${error.message}`);
        }
    };

    const extrairDataEHoraDefault = (dataString) => {
        // Criar um objeto Date a partir da string
        let data = new Date(dataString);

        // Extrair os componentes da data
        let ano = data.getFullYear();
        let mes = data.getMonth() + 1; // Os meses começam do zero, então é necessário adicionar 1
        let dia = data.getDate();

        // Extrair os componentes da hora
        let horas = data.getHours();
        let minutos = data.getMinutes();
        let segundos = data.getSeconds();

        // Formatar os componentes para terem sempre dois dígitos
        mes = mes < 10 ? '0' + mes : mes;
        dia = dia < 10 ? '0' + dia : dia;
        horas = horas < 10 ? '0' + horas : horas;
        minutos = minutos < 10 ? '0' + minutos : minutos;
        segundos = segundos < 10 ? '0' + segundos : segundos;

        // Retornar a data e hora formatadas
        return `${dia}/${mes}/${ano}  ${horas}:${minutos}:${segundos}`;
    };

    const formatarDataMovimento = (date) => {
        const formatedDate = String(date).split('T')[0];
        const data = new Date(formatedDate);

        // Obter os componentes da data (dia, mês e ano)
        const dia = String(data.getDate() + 1).padStart(2, '0');
        const mes = String(data.getMonth() + 1).padStart(2, '0'); // O mês em JavaScript é baseado em zero
        const ano = data.getFullYear();

        // Formatar a data no formato desejado (dd/mm/yyyy)
        const dataFormatada = `${dia}/${mes}/${ano}`;

        return dataFormatada;
    };

    if (!loading) {
        setTimeout(() => {
            window.print();
        }, 1000);
    }

    useEffect(() => {
        if (productId) {
            getMovSumarizado(productId, firstDate, lastDate);
        } else {
            getAllMovSumarizado(typeDate, firstDate, lastDate);
        }
    }, [firstDate]);

    return (
        <Box
            sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                marginLeft: '1em',
                padding: '30px 30px'
            }}
        >
            <Typography variant="h3">RELATÓRIO DE MOVIMENTAÇÃO POR PERÍODO</Typography>
            {loading && (
                <Box sx={{ marginTop: '2em', textAlign: 'center' }}>
                    <Typography>Aguarde por favor, este processo pode demorar...</Typography>
                    <CircularProgress sx={{ marginTop: '2em' }} />
                </Box>
            )}

            {!loading && groupOfItems.length === 0 && (
                <Box sx={{ marginTop: '2em', textAlign: 'center' }}>
                    <Typography>Sem Dados para o período inserido</Typography>
                </Box>
            )}

            {!loading && (
                <Box sx={{ width: '100%', padding: '30px' }}>
                    <Box
                        sx={{
                            width: '100%' /* paddingTop: '20px', paddingBottom: '80px'  */
                        }}
                    >
                        {groupOfItems?.map((currentGroup, index) => (
                            <Box>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        backgroundColor: '#eee',
                                        padding: '.5em',
                                        border: '1px solid #181818',
                                        borderRadius: '5px',
                                        marginBottom: '5px',
                                        marginTop: '10px'
                                    }}
                                >
                                    <Typography>
                                        PRODUTO:{currentGroup[0]?.item?.id} - {currentGroup[0]?.item?.descricao}
                                    </Typography>
                                    <Typography>UN:{currentGroup[0]?.item?.unidade?.descricao}</Typography>
                                    <Typography>PSICOTRÓPICO:{currentGroup[0]?.item?.psicotropico ? 'SIM' : 'NÃO'}</Typography>
                                </Box>
                                {currentGroup?.map((item) => (
                                    <Box sx={{ border: '2px solid #ccc', borderRadius: '5px', marginBottom: '24px' }}>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                padding: '0px 5px',
                                                borderBottom: '2px dashed #ccc'
                                            }}
                                        >
                                            <Typography>DATA: {item.dataCriacao}</Typography>
                                            <Typography>
                                                TIPO:
                                                <span style={{ fontWeight: 'bold' }}>
                                                    {item?.tipo == 'Extorno' ? 'Estorno' : item?.tipo}
                                                </span>
                                            </Typography>
                                            <Typography>
                                                QTD: <span style={{ fontWeight: 'bold' }}>{item?.quantidade}</span>
                                            </Typography>
                                            <Typography>
                                                ANTERIOR: <span style={{ fontWeight: 'bold' }}>{item?.saldo_anterior}</span>
                                            </Typography>
                                            <Typography>
                                                ATUAL: <span style={{ fontWeight: 'bold' }}>{item?.saldo || '-'}</span>
                                            </Typography>
                                        </Box>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                padding: '3px',
                                                borderBottom: '2px dashed #ccc'
                                            }}
                                        >
                                            <Typography>
                                                LOTE: <span style={{ fontWeight: 'bold' }}>{item?.lote}</span>
                                            </Typography>
                                            <Typography>
                                                ALMOXARIFADO: <span style={{ fontWeight: 'bold' }}>{item?.almoxarifado}</span>
                                            </Typography>
                                            <Typography>
                                                LOCALIZAÇÃO: <span style={{ fontWeight: 'bold' }}>{item?.localizacao}</span>
                                            </Typography>
                                        </Box>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                padding: '5px 5px',
                                                borderBottom: '1px solid #ccc',
                                                width: '100%'
                                            }}
                                        >
                                            <Typography>F.A: {item.fa}</Typography>
                                        </Box>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                width: '100%',
                                                justifyContent: 'space-between',

                                                marginBottom: '5px'
                                            }}
                                        >
                                            <Typography>PACIENTE: {item.paciente}</Typography>
                                            <Typography sx={{ width: '100%' }}>MÉDICO: {item?.medico} </Typography>
                                        </Box>
                                        {/* <Box
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                padding: '2px 5px',
                                                borderBottom: '1px solid #ccc'
                                            }}
                                        >
                                            <Typography>ENTRADA: {entrada[index]?.sum ?? entrada}</Typography>
                                            <Typography>SAÍDA: {saida[index]?.sum ?? saida}</Typography>
                                            <Typography sx={{ width: '100px' }}>TOTAL: {saldoSum[index]?.sum ?? saldoGeral}</Typography>
                                        </Box> */}
                                    </Box>
                                ))}
                            </Box>
                        ))}
                    </Box>

                    <Box
                        sx={{
                            width: '100%',
                            display: 'flex',
                            padding: '0 .5em',
                            justifyContent: 'center',
                            alignItems: 'center',
                            borderTop: '1px solid #181818'
                        }}
                    >
                        <img src={SantaCasaLogo} alt="Santa Casa Logotipo" style={{ width: '90px' }} />
                        <Box>
                            <Typography>SANTA CASA DE MISERICÓRDIA DE JOANÓPOLIS</Typography>
                            <Typography>RUA FRANCISCO WOLHERS N°57 - CENTRO - JOANÓPOLIS - SP</Typography>
                            <Typography>CEP: 12980-000 - TELEFONE: (11) 4539-9333</Typography>
                        </Box>
                    </Box>
                </Box>
            )}
        </Box>
    );
};

export default RelatorioPacientePorPeriodo;
