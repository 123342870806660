import { Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@mui/material';
import { Link } from 'react-router-dom';
import formateDate from 'utils/formatDate-1';
import { IconFolder, IconAlertTriangle } from '@tabler/icons';
import { useState } from 'react';
import { useContext } from 'react';
import { useApi } from 'Service/axios';
import { toast } from 'react-toastify';
import TelegramIcon from '@mui/icons-material/Telegram';
import { ChamadaContext } from 'context/ChamadasContext';
import DialogInativar from './DialogInativar';
import SettingsPowerIcon from '@mui/icons-material/SettingsPower';
import React from 'react';
import { IconRibbonHealth } from '@tabler/icons';
import Laco from '../../../assets/images/Logos/laco.jpg';
import { Box, display } from '@mui/system';

const TabelaTriagem = ({ allFichas, page, rowsPerPage }) => {
    const { setChamadaAcionada, setChamadasRealizadas } = useContext(ChamadaContext);

    const api = useApi();
    // --- STATES --- \\
    const [cooldown, setCooldown] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [atendimentoId, setAtendimentoId] = useState(null);

    // Realiza o cadastro da chamada
    // e recebe o objeto pelo socket no contexto automaticamente
    const handleCreateChamada = async (item) => {
        try {
            // Controla o disabled dos botões
            setCooldown(true);
            // Cadastra a chamada no banco
            const payloadChamada = { setor: 'ESCUTA INICIAL', prontuario_id: item?.id, message: 'TESTE' };
            const criarChamada = await api.createChamadaPainel(payloadChamada);

            if (criarChamada.status == 201) {
                setTimeout(() => {
                    setCooldown(false);
                }, 3000);
                // State do context
                // Se true ele chama o get para atualizar os atendimentos pendentes

                setChamadaAcionada(true);

                toast.success('Paciente Chamado com sucesso!');
            }
        } catch (error) {
            toast.error(`Erro ao chamar paciente: ${error.message}`);
        }
    };

    const handleOpen = (id) => {
        setDialogOpen(true);
        setAtendimentoId(id);
    };

    const handleClose = () => {
        setDialogOpen(false);
    };

    return (
        <React.Fragment>
            {/* Modal ao clicar no inativar atendimento */}
            <DialogInativar
                open={dialogOpen}
                handleClose={handleClose}
                atendimentoId={atendimentoId}
                setChamadaAcionada={setChamadaAcionada}
            />
            <TableContainer component={Paper} sx={{ maxHeight: '420px' }}>
                <Table stickyHeader sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <b>F.A</b>
                            </TableCell>
                            <TableCell align="center">
                                <b>Paciente</b>
                            </TableCell>
                            <TableCell align="center">
                                <b>Idade</b>
                            </TableCell>
                            <TableCell align="center">
                                <b>Data</b>
                            </TableCell>
                            <TableCell align="center">
                                <b>Hora</b>
                            </TableCell>
                            <TableCell align="center">
                                <b>Status</b>
                            </TableCell>
                            <TableCell align="center">
                                <b>Chamadas</b>
                            </TableCell>
                            <TableCell align="center">
                                <b>Ações</b>
                            </TableCell>
                            <TableCell align="center">
                                <b>Inativar</b>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {allFichas.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item) => (
                            <TableRow key={item?.id}>
                                <TableCell>{item?.id}</TableCell>
                                <TableCell align="center">
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        {item?.paciente.nome_completo}
                                        {item?.paciente?.autismo && (
                                            <img src={Laco} alt="Laço autismo" style={{ width: '40px', height: 'auto' }} />
                                        )}
                                    </Box>
                                </TableCell>
                                <TableCell align="center">{item?.paciente?.idade}</TableCell>
                                <TableCell align="center">{formateDate(item?.data_atendimento)}</TableCell>
                                <TableCell align="center">{item.hora_atendimento}</TableCell>
                                <TableCell align="center">{item?.status}</TableCell>
                                <TableCell align="center">{item?.quantidade_chamadas}</TableCell>
                                <TableCell align="center">
                                    <Button
                                        title={cooldown ? 'Aguarde...' : 'Chamar Paciente'}
                                        onClick={() => handleCreateChamada(item)}
                                        disabled={cooldown}
                                    >
                                        {cooldown ? <IconAlertTriangle style={{ color: 'yellow' }} /> : <TelegramIcon />}
                                    </Button>
                                    <Link to={`/main/laboratorio/triagem/${item?.id_paciente}/prontuario/${item?.id}`}>
                                        <Tooltip title="Abrir Ficha">
                                            <Button>
                                                <IconFolder />
                                            </Button>
                                        </Tooltip>
                                    </Link>
                                </TableCell>
                                <TableCell align="center">
                                    <Tooltip title="Inativar Atendimento">
                                        <Button onClick={() => handleOpen(item.id)}>
                                            <SettingsPowerIcon />
                                        </Button>
                                    </Tooltip>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </React.Fragment>
    );
};

export default TabelaTriagem;
