import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { useApi } from 'Service/axios';
import { useEffect } from 'react';
import { FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, TextField } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { toast } from 'react-toastify';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: 1
};

export function SignatureLab({ confirmFunction, setAssinaturaId, open, setOpen }) {
    const handleClose = () => setOpen(false);
    const api = useApi();
    const [image, setImage] = React.useState(null);
    const [showPassword, setShowPassword] = React.useState(false);
    const [confirmPassword, setConfirmPassword] = React.useState('');

    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    async function getMedicalSignature() {
        const currentUser = localStorage.getItem('userInfos');
        const user = JSON.parse(currentUser);
        const id = user.usuario.id;
        try {
            const { data } = await api.getSignature(id);
            setAssinaturaId(data.id);
            const buffer = new Uint8Array(data.conteudo.data);
            const dataURL = await bufferToDataURL(buffer);
            setImage(dataURL);
        } catch (error) {
            console.log('🚀 ~ getMedicalSignature ~ error:', error);
        }
    }

    function bufferToDataURL(buffer) {
        return new Promise((resolve, reject) => {
            const blob = new Blob([buffer], { type: 'image/png' });
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.onerror = reject;
            reader.readAsDataURL(blob);
        });
    }

    async function handleConfirm() {
        const currentUser = localStorage.getItem('userInfos');
        const user = JSON.parse(currentUser);
        const id = user.usuario.id;
        const payload = { usuarioId: id, senha: confirmPassword };
        try {
            await api.confirmSignature(payload);
            confirmFunction();
            handleClose();
        } catch (error) {
            toast.error('Erro ao confirmar a assinatura');
            console.log('🚀 ~ handleConfirm ~ error:', error);
        }
    }

    useEffect(() => {
        getMedicalSignature();
    }, []);

    return (
        <>
            <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h4" component="h2">
                        Confirmação de identidade
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        Verifique se corresponde a sua assinatura digital.
                    </Typography>
                    <Box sx={{ marginTop: '20px' }}>
                        <img style={{ width: '90%', height: 250 }} src={image} alt="Imagem de assinatura" />
                        <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '20px', alignItems: 'center', gap: '40px' }}>
                            <FormControl sx={{ m: 1, width: '100%' }} variant="outlined">
                                <InputLabel htmlFor="outlined-adornment-password">Confirme sua senha</InputLabel>
                                <OutlinedInput
                                    id="outlined-adornment-password"
                                    type={showPassword ? 'text' : 'password'}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                    label="Confirme sua senha"
                                />
                            </FormControl>
                            <Button variant="contained" sx={{ width: '200px', height: '45px' }} onClick={handleConfirm}>
                                Confirmar
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Modal>
        </>
    );
}
