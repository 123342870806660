import { useApi } from 'Service/axios';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Drawer,
    FormControl,
    MenuItem,
    TextField,
    Tooltip,
    Typography
} from '@mui/material';
import { Box } from '@mui/system';
import { useState, useEffect } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import LogoutIcon from '@mui/icons-material/Logout';

const ActionButtonEdit = ({
    status,
    setCampos,
    itens,
    setItens,
    row,
    atualizarCampoItem,
    removerCampoItem,
    listAllItens,
    listAllAlmoxarifado,
    listAllLocalizacao
}) => {
    /////////////////////////////// INSTANCIAS /////////////////////////
    const api = useApi();
    /////////////////////////////// STATES /////////////////////////
    const [open, setOpen] = useState(false);
    const [indexItem, setIndexItem] = useState(null);
    const [itemSelecionado, setItemSelecionado] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);
    /////////////////////////////// FUNÇÕES /////////////////////////
    const handleOpen = async () => {
        setOpen(true);
    };

    const handleItemSelect = (selectedItemId) => {
        const selectedItem = listAllItens.find((item) => item.id === selectedItemId);
        const novoStatusLote = selectedItem?.lote ? true : false;
        const novoStatusValidade = selectedItem?.validade ? true : false;
        setItemSelecionado({
            ...itemSelecionado,
            item_id: selectedItem.id,
            descricao: selectedItem.descricao,
            unidade: selectedItem.unidade.descricao,
            almoxarifado_id: selectedItem.almoxarifado.id,
            localizacao_id: selectedItem.localizacao.id,
            observacao: selectedItem.observacao,
            statusValidade: novoStatusValidade,
            statusLote: novoStatusLote
        });
    };

    const handleValorUnitario = (e, itemSelecionado) => {
        const novoValorUnitario = parseFloat(e.target.value);
        const novoValorTotal = parseFloat(itemSelecionado.quantidade * novoValorUnitario);

        setItemSelecionado({
            ...itemSelecionado,
            valor_unitario: novoValorUnitario,
            valor_total: novoValorTotal
        });
    };
    const atualizarCampoItemSelecionado = (item, campo, valor) => {
        setItemSelecionado({
            ...item,
            [campo]: campo === 'item_id' || campo === 'quantidade' || campo === 'valor_unitario' ? parseInt(valor) : valor
        });
    };

    const handleEditItem = (itemSelecionado) => {
        const selectedItem = listAllItens.find((item) => item.id === itemSelecionado.item_id);
        itemSelecionado.item = selectedItem;

        setItens((prevItens) => {
            const novosItens = prevItens.map((item) => {
                // Verifique se o item.id é igual ao itemSelecionado.id
                if (item.id === itemSelecionado.id) {
                    // Se for igual, retorne o itemSelecionado para atualização
                    return itemSelecionado;
                } else {
                }
                // Mantenha outros itens inalterados
                return item;
            });

            return novosItens;
        });
        setCampos((prevCampos) => {
            const novosCampos = [...prevCampos];
            const indiceItem = novosCampos[0].itens.findIndex((item) => item.indiceCampoItem === itemSelecionado.indiceCampoItem);

            if (indiceItem !== -1) {
                const item = novosCampos[0].itens[indiceItem];
                item.action = 'update';
            }
            return novosCampos;
        });

        setOpen(false);
    };

    useEffect(() => {
        const novoStatusLote = !!row.lote || !!itemSelecionado?.lote;
        const novoStatusValidade = !!row.validade || !!itemSelecionado?.validade;
        setItemSelecionado({ ...row, statusValidade: novoStatusValidade, statusLote: novoStatusLote });
    }, []);

    return (
        <Box>
            <Dialog open={openDialog}>
                <DialogTitle>Atenção</DialogTitle>
                <DialogContent>
                    <Typography>Ao fechar todo o cadastro desse item sera cancelado, continuar?</Typography>
                </DialogContent>
                <DialogActions>
                    <Box sx={{ display: 'flex' }}>
                        <Button color="error" onClick={() => setOpenDialog(false)}>
                            Cancelar
                        </Button>
                        <Button onClick={() => removerCampoItem(0, row.indiceCampoItem)}>Confirmar</Button>
                    </Box>
                </DialogActions>
            </Dialog>
            <Drawer anchor="right" open={open}>
                <Box sx={{ width: '650px', padding: '.75em', height: '100%' }}>
                    {itemSelecionado !== null && (
                        <Box>
                            <FormControl fullWidth>
                                <TextField
                                    label="Item"
                                    select
                                    value={itemSelecionado ? itemSelecionado.item_id : ''}
                                    onChange={(e) => handleItemSelect(e.target.value)}
                                >
                                    {listAllItens.map((i) => (
                                        <MenuItem key={i.id} value={i.id}>
                                            {i.descricao}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </FormControl>
                            <Box sx={{ display: 'flex', gap: '1em', marginTop: '1em', marginBottom: '1em' }}>
                                <FormControl fullWidth>
                                    <TextField
                                        label="Quantidade"
                                        type="number"
                                        value={parseFloat(itemSelecionado.quantidade)}
                                        onChange={(e) =>
                                            atualizarCampoItemSelecionado(itemSelecionado, 'quantidade', parseFloat(e.target.value))
                                        }
                                    />
                                </FormControl>
                                <FormControl fullWidth>
                                    <TextField
                                        label="Valor Unitário"
                                        type="number"
                                        value={itemSelecionado ? itemSelecionado.valor_unitario : 0}
                                        onChange={(e) => handleValorUnitario(e, itemSelecionado)}
                                    />
                                </FormControl>
                            </Box>
                            <FormControl fullWidth>
                                <TextField label="Valor Total" type="number" value={itemSelecionado.valor_total} disabled />
                            </FormControl>
                            <Box sx={{ display: 'flex', gap: '1em', marginTop: '1em', marginBottom: '1em' }}>
                                <FormControl fullWidth>
                                    <TextField
                                        label="Almoxarifado"
                                        select
                                        value={itemSelecionado.almoxarifado_id}
                                        onChange={(e) => atualizarCampoItemSelecionado(itemSelecionado, 'almoxarifado_id', e.target.value)}
                                    >
                                        {listAllAlmoxarifado.map((almoxarifado) => (
                                            <MenuItem key={almoxarifado.id} value={almoxarifado.id}>
                                                {almoxarifado.descricao}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </FormControl>
                                <FormControl fullWidth>
                                    <TextField
                                        label="Localização"
                                        select
                                        value={itemSelecionado.localizacao_id}
                                        onChange={(e) => atualizarCampoItemSelecionado(itemSelecionado, 'localizacao_id', e.target.value)}
                                    >
                                        {listAllLocalizacao.map((localizacao) => (
                                            <MenuItem key={localizacao.id} value={localizacao.id}>
                                                {localizacao.descricao}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </FormControl>
                            </Box>
                            <Box sx={{ display: 'flex', gap: '1em', marginTop: '1em', marginBottom: '1em' }}>
                                <FormControl fullWidth>
                                    <TextField
                                        label="Observação"
                                        value={itemSelecionado.observacao}
                                        onChange={(e) => atualizarCampoItemSelecionado(itemSelecionado, 'observacao', e.target.value)}
                                    />
                                </FormControl>
                            </Box>
                            <Box sx={{ display: 'flex', gap: '1em' }}>
                                {itemSelecionado && itemSelecionado.statusLote && (
                                    <FormControl fullWidth>
                                        <TextField
                                            label="Lote"
                                            type="text"
                                            value={itemSelecionado.lote}
                                            onChange={(e) => atualizarCampoItemSelecionado(itemSelecionado, 'lote', e.target.value)}
                                        />
                                    </FormControl>
                                )}
                                {itemSelecionado && itemSelecionado.statusValidade && (
                                    <FormControl fullWidth>
                                        <TextField
                                            type="date"
                                            value={itemSelecionado.validade}
                                            onChange={(e) => atualizarCampoItemSelecionado(itemSelecionado, 'validade', e.target.value)}
                                        />
                                    </FormControl>
                                )}
                            </Box>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '2em' }}>
                                <Tooltip title="Fechar e remover">
                                    <Button variant="contained" color="error" onClick={() => setOpenDialog(true)}>
                                        <CloseIcon />
                                    </Button>
                                </Tooltip>
                                <Tooltip title="Fechar">
                                    <Button variant="contained" color="warning" onClick={() => setOpen(false)}>
                                        <LogoutIcon />
                                    </Button>
                                </Tooltip>
                                <Button variant="contained" onClick={(e) => handleEditItem(itemSelecionado)}>
                                    Salvar alterações
                                </Button>
                            </Box>
                        </Box>
                    )}
                </Box>
            </Drawer>
            <Box sx={{ display: 'flex', gap: 2 }}>
                <Tooltip title="Editar Item">
                    <Button sx={{ all: 'unset', cursor: 'pointer' }} onClick={handleOpen} disabled={status}>
                        <EditIcon />
                    </Button>
                </Tooltip>
                <Tooltip title="Remover Item">
                    <Button
                        sx={{ all: 'unset', cursor: 'pointer' }}
                        disabled={status}
                        onClick={() => removerCampoItem(0, row.indiceCampoItem)}
                    >
                        <DeleteIcon />
                    </Button>
                </Tooltip>
            </Box>
        </Box>
    );
};

export default ActionButtonEdit;
