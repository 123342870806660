import MainRelatorio from 'layout/RelatorioLayout/Main';
import LaudoExame from 'views/Relatorios/LaudoExame';
import LaboratorioLayout from 'layout/LaboratorioLayout';
import ConjuntoLaudos from 'views/Relatorios/ConjuntoLaudos';
import LaudoExamesEspeciais from 'views/Relatorios/LaudoExame/ExamesEspeciais';

const LaboratorioRoutes = {
    path: '/laboratorio',
    element: <LaboratorioLayout />,
    children: [
        {
            path: '/laboratorio/resultado/:prontuarioId/:exameId/:idExameProntuario',
            element: <LaudoExame />
        },
        {
            path: '/laboratorio/resultado-especial/:prontuarioId/:exameId/:idExameProntuario',
            element: <LaudoExamesEspeciais />
        },
        {
            path: '/laboratorio/conjunto-laudos',
            element: <ConjuntoLaudos />
        }
    ]
};

export default LaboratorioRoutes;
