import React, { useState } from 'react';
// Context
import { createContext } from 'react';
// hooks
import useAuth from 'hooks/useAuth';

const Context = createContext();

function UserProvider({ children }) {
    const { authenticated, errorMessage, loggedUser, login, registerUser, logout } = useAuth();

    const functions = {
        authenticated,
        errorMessage,
        loggedUser,
        login,
        registerUser,
        logout
    };

    return <Context.Provider value={{ functions }}>{children}</Context.Provider>;
}

export { Context, UserProvider };
