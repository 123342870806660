export function formatarDataIdade(dataString) {
    // Criar um objeto de data a partir da string fornecida
    const data = new Date(dataString);

    // Ajustar para o fuso horário local
    const dataLocal = new Date(data.getTime() - data.getTimezoneOffset() * 60000);

    // Obter o dia, mês e ano
    const dia = dataLocal.getDate().toString().padStart(2, '0');
    const mes = (dataLocal.getMonth() + 1).toString().padStart(2, '0'); // Adicionar 1 ao mês
    const ano = dataLocal.getFullYear();

    // Calcular a diferença em anos entre a data fornecida e a data atual
    const hoje = new Date();
    const diferencaAnos = hoje.getFullYear() - ano;

    // Construir a string formatada
    const dataFormatada = `${dia}/${mes}/${ano} - (${diferencaAnos} anos)`;

    return dataFormatada;
}
