import TroubleshootIcon from '@mui/icons-material/Troubleshoot';
import { IconClipboardPlus, IconAdjustments, IconFolderPlus } from '@tabler/icons';
import PersonalVideoIcon from '@mui/icons-material/PersonalVideo';
const icons = {
    IconClipboardPlus,
    IconAdjustments,
    TroubleshootIcon,
    IconFolderPlus,
    PersonalVideoIcon
};

const xray = {
    id: 'x-ray',
    title: 'Raio X',
    type: 'group',
    children: [
        {
            id: 'pedidosRaiox',
            title: 'Pedidos',
            type: 'collapse',
            icon: icons.IconFolderPlus,
            children: [
                {
                    id: 'atendimento-raio-x',
                    title: 'Atendimentos Pendentes',
                    type: 'item',
                    url: '/main/atendimento/raio-x',
                    icon: icons.IconClipboardPlus,
                    breadcrumbs: false
                },
                {
                    id: 'consultaRaioX',
                    title: 'Consulta',
                    type: 'item',
                    url: '/main/atendimento/raio-x/consulta',
                    icon: icons.TroubleshootIcon,
                    breadcrumbs: false
                }
            ]
        },

        {
            id: 'register-raio-x',
            title: 'Cadastro',
            type: 'item',
            url: '/main/cadastro/raio-x',
            icon: icons.IconAdjustments,
            breadcrumbs: false
        }
    ]
};

export default xray;
