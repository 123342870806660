import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Autocomplete, MenuItem, TextField } from '@mui/material';
import { useState } from 'react';
import { useApi } from 'Service/axios';
import { useEffect } from 'react';
import { VolunteerActivism } from '@mui/icons-material';
import { toast } from 'react-toastify';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 900,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: 2,
    maxHeight: '90vh',
    minHeight: '30vh'
};

export function Medicine({ open, setOpen, name, prontuarioId, get }) {
    const handleClose = () => setOpen(false);
    const api = useApi();
    const [inputValue, setInputValue] = useState({ descricao: '' });
    const [viaAplicacao, setViaAplicacao] = useState('');
    const [selectedExams, setSelectedExams] = useState(null);
    const [medicine, setMedicine] = useState([]);
    const [observation, setObservation] = useState('');
    const [qtd, setQtd] = useState('');

    const handleChange = (event) => {
        setViaAplicacao(event.target.value);
    };

    async function getMedicine() {
        try {
            const { data } = await api.getAllItems();
            setMedicine(data.filter((item) => item?.descricao !== '.'));
        } catch (error) {
            toast.error(error?.response?.data?.message ?? error?.message);
        }
    }

    const handleInsertExam = (selectedExam) => {
        setSelectedExams(selectedExam);
        setInputValue({ descricao: '' });
    };

    useEffect(() => {
        if (open) {
            getMedicine();
        }
    }, [open]);

    async function handleSubmit(event) {
        event.preventDefault();

        const userString = localStorage.getItem('userInfos');
        const userObject = JSON.parse(userString);
        const toastId = toast.loading('Inserindo medicamento...');
        const payload = {
            itemId: selectedExams?.id,
            diluicaoId: null,
            viaAplicacao: viaAplicacao,
            quantidadeItem: qtd,
            quantidadeDiluicao: null,
            frequencia: 'AGORA',
            observacao: observation,
            prontuarioId: prontuarioId,
            responsavelId: userObject.usuario.id
        };
        try {
            await api.linkMedicinesConduct(payload);
            toast.update(toastId, {
                render: 'Medicamento inserido com sucesso!',
                type: 'success',
                isLoading: false,
                autoClose: 2000
            });
            get();
            handleClose();
            setQtd('');
            setObservation('');
            setViaAplicacao('');
        } catch (error) {
            toast.update(toastId, {
                render: 'Erro ao inserir medicamento!',
                type: 'error',
                isLoading: false,
                autoClose: 2000
            });
        }
    }

    return (
        <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <Box sx={style}>
                <Typography variant="h4" gutterBottom>
                    MEDICAMENTOS - {name}
                </Typography>

                <form style={{ display: 'flex', flexDirection: 'column', gap: '10px', marginBottom: '20px' }} onSubmit={handleSubmit}>
                    <Typography variant="h5" gutterBottom sx={{ margin: '15px 0 5px 0' }}>
                        MEDICAMENTO
                    </Typography>
                    <Box sx={{ display: 'flex', gap: '10px', alignItems: 'center', flexWrap: 'wrap' }}>
                        <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            options={medicine}
                            getOptionLabel={(option) => option.descricao}
                            inputValue={inputValue.descricao}
                            onInputChange={(event, newInputValue) => setInputValue({ descricao: newInputValue })}
                            sx={{ flexGrow: 1, minWidth: 200 }}
                            onChange={(event, newValue) => newValue && handleInsertExam(newValue)}
                            renderInput={(params) => <TextField {...params} fullWidth label="Medicamento" />}
                        />
                        <TextField
                            label="QTD"
                            onChange={(e) => setQtd(e.target.value)}
                            value={qtd}
                            sx={{ width: '100px', flexShrink: 0 }}
                        />
                        <TextField
                            label="UN"
                            sx={{ width: '100px', flexShrink: 0 }}
                            InputLabelProps={{ shrink: true }}
                            value={selectedExams && selectedExams?.unidade?.sigla}
                        />
                        <TextField
                            sx={{ flexGrow: 1, minWidth: 150 }}
                            label="Via de aplicação"
                            select
                            SelectProps={{
                                native: true
                            }}
                            InputLabelProps={{ shrink: true }}
                            value={viaAplicacao}
                            onChange={handleChange}
                        >
                            <option value="">Selecione</option>
                            <option value="intravenosa">Intravenosa - IV</option>
                            <option value="intramuscular">Intramuscular - IM</option>
                            <option value="subcutanea">Subcutânea - SC</option>
                            <option value="oral">Oral</option>
                            <option value="topica">Tópica</option>
                            <option value="inalatorio">Inalatório</option>
                            <option value="oftalmico">Oftálmico</option>
                        </TextField>
                    </Box>

                    {/* <Typography variant="h5" gutterBottom sx={{ margin: '15px 0 5px 0' }}>
                        OPÇÃO DE DILUIÇÃO
                    </Typography>
                    <Box sx={{ display: 'flex', gap: '10px', alignItems: 'center', flexWrap: 'wrap' }}>
                        <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            // inputValue={inputValue.descricao}
                            // onInputChange={(event, newInputValue) => setInputValue({ descricao: newInputValue })}
                            sx={{ flexGrow: 1, minWidth: 200 }}
                            renderInput={(params) => <TextField {...params} fullWidth label="Diluição" />}
                        />
                        <TextField label="QTD" sx={{ width: '100px', flexShrink: 0 }} />
                        <TextField label="UN" sx={{ width: '100px', flexShrink: 0 }} />
                        <TextField
                            sx={{ flexGrow: 1, minWidth: 200 }}
                            value="AGORA"
                            disabled
                            label="Frequencia"
                            InputLabelProps={{ shrink: true }}
                        />
                    </Box> */}

                    <Typography variant="h5" gutterBottom sx={{ margin: '15px 0 5px 0' }}>
                        Observação
                    </Typography>
                    <TextField
                        label="Observação"
                        value={observation}
                        onChange={(e) => setObservation(e.target.value)}
                        fullWidth
                        rows={4}
                        multiline
                    />
                    <Box sx={{ display: 'flex', justifyContent: 'end' }}>
                        <Button variant="contained" type="submit">
                            Inserir
                        </Button>
                    </Box>
                </form>
            </Box>
        </Modal>
    );
}
