import { TableCell, styled } from '@mui/material';
import { Box, Typography, Button } from '@mui/material';

// ===================HEADER===================
export const HeaderBox = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    margin: '0 auto',
    marginLeft: '1em',
    borderTop: '2px dotted #000'
});

export const Title = styled(Typography)({
    fontSize: '.73em',
    marginTop: '.3em'
});
// ===================TRIAGEM===================
export const HeaderTriagem = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    margin: '0 auto',
    marginLeft: '1em',
    borderTop: '2px dotted #000'
});

export const ContentTriagem = styled(Box)({
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    margin: '0 auto',
    marginLeft: '1em',
    borderTop: '2px dotted #000'
});

export const Classificacao = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    margin: '0 auto',
    marginLeft: '1em',
    borderTop: '2px dotted #000'
});

export const PrincipaisDados = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    margin: '0 auto',
    marginLeft: '1em',
    borderTop: '2px dotted #000'
});

export const ExamesPrescricao = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    margin: '0 auto',
    marginLeft: '1em',
    borderTop: '2px dotted #000'
});

export const Destino = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    margin: '0 auto',
    marginLeft: '1em',
    borderTop: '2px dotted #000'
});

export const Assinatura = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    margin: '0 auto',
    marginLeft: '1em',
    marginTop: '1em',
    borderTop: '2px dotted #000'
});

export const Rodape = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    margin: '0 auto',
    marginLeft: '1em'
});
