import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    FormControl,
    FormControlLabel,
    MenuItem,
    Switch,
    TextField,
    Typography
} from '@mui/material';
import { Box } from '@mui/system';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useApi } from 'Service/axios';

const ActionButtonGeral = () => {
    // ---- INSTÂNCIAS ----
    const api = useApi();
    // ---- STATES ----
    const [allProducts, setAllProducts] = useState([]);
    const [filtredProducts, setFiltredProducts] = useState([]);
    const [selectedAlmoxarifado, setSelectedAlmoxarifado] = useState();
    const [almoxarifados, setAlmoxarifados] = useState([]);

    const [product, setProduct] = useState(null);
    const [checked, setChecked] = useState(true);
    const [switchCheck, setSwitchCheck] = useState(false);
    const [switchCheckPisico, setSwitchCheckPisico] = useState();
    const [open, setOpen] = useState(false);
    // ---- FUNÇÕES ----

    const getProducts = async () => {
        try {
            const response = await api.getAllItemsNoFilter();
            setAllProducts(response.data);
            setFiltredProducts(response.data.filter((item) => item.psicotropico === true));
        } catch (error) {
            toast.error(`Erro ao buscar dados: ${error.message}`);
        }
    };

    const handleChangeCheckbox = (e) => {
        const newCheckedValue = e.target.checked;
        setChecked(newCheckedValue);

        if (!newCheckedValue) {
            setFiltredProducts(allProducts.filter((item) => item.psicotropico === false));
        } else {
            setFiltredProducts(allProducts.filter((item) => item.psicotropico === true));
        }
    };

    const handleChangeSwitch = (e) => {
        const newSwitchCheckValue = e.target.checked;
        setSwitchCheck(e.target.checked);

        if (!newSwitchCheckValue) {
            setFiltredProducts(allProducts.filter((item) => item.psicotropico === true));
        } else {
            setFiltredProducts(allProducts);
        }
    };

    const handleChangeInput = (value) => {
        setProduct(value);
    };

    const handleClose = () => {
        setProduct(null);
        setChecked(true);
        setFiltredProducts(allProducts.filter((item) => item.psicotropico === true));
        setOpen(false);
    };

    const handleNavigate = (params) => {
        /*  if (switchCheckPisico && params != null) {
            window.open(`/relatorios/estoque-geral/${selectedAlmoxarifado}`);
        } else if (!switchCheck && params === null && !checked) {
            window.open(`/relatorios/estoque-geral/${'abertos'}`);
        } else {
            handleClose();
            if (switchCheck) {
                window.open(`/relatorios/estoque-geral/${selectedAlmoxarifado}`);
            } else {
                window.open(`/relatorios/estoque-geral/${selectedAlmoxarifado}`);
            }
      } */
        if (params === undefined) {
            return toast.error('Escolha Um Almoxarifado');
        }
        if (switchCheckPisico && params != undefined && !switchCheck) {
            window.open(`/relatorios/estoque-geral/${'psicotropicos'}/${selectedAlmoxarifado}`);
        } else if (!switchCheck && !switchCheckPisico && params != undefined) {
            window.open(`/relatorios/estoque-geral/${'abertos'}/${selectedAlmoxarifado}`);
        } else {
            handleClose();
            window.open(`/relatorios/estoque-geral/${'todos'}/${selectedAlmoxarifado}`);
        }
    };

    const handleFilterAlmoxarifado = async (params) => {
        setSelectedAlmoxarifado(params);
    };
    const uniqueAlmoxarifadosList = allProducts
        ?.map((product) => product.almoxarifado)
        ?.filter((almoxarifado, index, array) => {
            return array.findIndex((a) => a?.id === almoxarifado?.id) === index;
        });

    useEffect(() => {
        getProducts();
    }, []);

    // ...

    return (
        <Box>
            <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth={'md'}>
                <DialogContent>
                    <Box sx={{ width: '100%' }}>
                        <Box sx={{ textAlign: 'center', padding: '.5em' }}>
                            <Typography variant="h3">RELATÓRIO DE ESTOQUE GERAL</Typography>
                        </Box>
                        <Box sx={{ width: '100%' }}>
                            <Box sx={{ padding: '.4em' }}>
                                <FormControl fullWidth sx={{ marginBottom: '30px' }}>
                                    <TextField
                                        label="Almoxarifado"
                                        select
                                        value={selectedAlmoxarifado}
                                        onChange={(e) => handleFilterAlmoxarifado(e.target.value)}
                                    >
                                        {uniqueAlmoxarifadosList.map((almoxarifado) => (
                                            <MenuItem key={almoxarifado?.id} value={almoxarifado?.id}>
                                                {almoxarifado?.descricao}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </FormControl>

                                <FormControl fullWidth>
                                    <TextField
                                        label="Produto"
                                        select
                                        value={product}
                                        onChange={(e) => handleChangeInput(e.target.value)}
                                        disabled={!selectedAlmoxarifado || switchCheck}
                                    >
                                        {filtredProducts
                                            .filter((product) => !selectedAlmoxarifado || product.almoxarifado_id === selectedAlmoxarifado)
                                            .map((product) => (
                                                <MenuItem key={product.id} value={product.id}>
                                                    {product.descricao}
                                                </MenuItem>
                                            ))}
                                    </TextField>
                                </FormControl>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            disabled={switchCheck}
                                            label="TESTE"
                                            checked={switchCheckPisico}
                                            onChange={() => setSwitchCheckPisico(!switchCheckPisico)}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                        />
                                    }
                                    label="PSICOTRÓPICO"
                                />
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={switchCheck}
                                            onChange={(e) => handleChangeSwitch(e)}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                        />
                                    }
                                    label="Todos"
                                />
                            </Box>
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button color="error" onClick={() => setOpen(false)}>
                        Cancelar
                    </Button>
                    <Button variant="contained" onClick={() => handleNavigate(selectedAlmoxarifado)}>
                        Gerar
                    </Button>
                </DialogActions>
            </Dialog>
            <Button onClick={() => setOpen(true)}>Abrir</Button>
        </Box>
    );
};

export default ActionButtonGeral;
