import { useRef } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { createContext } from 'react';

const azureWEBSOCKET = process.env.REACT_APP_websocket_azure;

export const WebsocketContext = createContext(false, null, () => {});

export const WebsocketProvider = ({ children }) => {
    const [isReady, setIsReady] = useState(false);
    const [val, setVal] = useState(null);

    const ws = useRef(null);
    function sendPing(ws) {
        if (ws.current.readyState === WebSocket.OPEN) {
            ws.current.send('ping');
        }
    }

    useEffect(() => {
        // !! PRODUÇÃO !!
        const socket = new WebSocket(azureWEBSOCKET);

        //const socket = new WebSocket('ws://localhost:8080');

        socket.onopen = () => setIsReady(true);
        socket.onclose = () => setIsReady(false);
        socket.onmessage = (event) => setVal(event.data);

        ws.current = socket;

        return () => {
            socket.close();
        };
    }, []);

    useEffect(() => {
        const pingInterval = setInterval(() => sendPing(ws), 30000);

        return () => {
            clearInterval(pingInterval);
        };
    }, []);

    const ret = [isReady, val, ws.current?.send.bind(ws.current)];

    return <WebsocketContext.Provider value={ret}>{children}</WebsocketContext.Provider>;
};
