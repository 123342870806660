import React from 'react';
import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';
import { ConjuntoLaudoContext } from 'context/LaudoContext';
import { useContext } from 'react';
import { useApi } from 'Service/axios';
import { useEffect } from 'react';
import formateDate from 'utils/formatDate-1';
import { useState } from 'react';
import Logo from '../../../../assets/images/Logos/LOGO_DO_LAC.jpg';
import SantaCasaLogo from '../../../../assets/images/Logos/logo2.png';
import { display, fontSize } from '@mui/system';
import BwLogo from '../../../../assets/BW TECNOLOGIA.png';

const PdfAtestado = ({ data }) => {
    console.log('🚀 ~ PdfReceita ~ data:', data);
    const styles = {
        page: {
            paddingTop: 15,
            paddingBottom: 15,
            paddingHorizontal: 15,
            backgroundColor: '#fff',
            fontSize: 10,
            fontWeight: 'normal',
            rap: 'wrap',
            position: 'relative'
        },
        header: {
            padding: 5,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center'
        },
        image: {
            width: 100,
            backgroundSize: 'cover'
        },
        text1: {
            fontSize: 10
        },
        box: {
            border: '1px solid #ddd',
            padding: 10,

            borderRadius: 5
        },
        between: {
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row',
            justifyContent: 'space-between'
        },
        labelArea: {
            backgroundColor: '#ccc',
            padding: '8px',
            borderRadius: 5,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between'
        },

        row: { flexDirection: 'row', borderBottom: '1 solid #000' },
        cell: { flexGrow: 1, padding: 5 },
        fixed: {
            bottom: 0,
            left: 0,
            width: '100%',
            padding: 10,
            position: 'absolute',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center'
        }
    };

    const getCurrentDateTime = () => {
        const now = new Date();
        const date = now.toLocaleDateString('pt-BR'); // Formato DD/MM/YYYY
        const time = now.toLocaleTimeString('pt-BR', { hour: '2-digit', minute: '2-digit' }); // Formato HH:MM
        return { date, time };
    };

    const { date, time } = getCurrentDateTime();

    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <View
                    sx={{
                        width: '100%',
                        display: 'flex',
                        padding: '.5em',
                        gap: '10px',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginBottom: '20px',
                        borderTop: '1px solid #000'
                    }}
                >
                    <Image src={SantaCasaLogo} alt="Santa Casa Logotipo" style={{ width: '8%', left: '250px' }} />
                    <View style={{ left: '180px', fontSize: 7 }}>
                        <Text>SANTA CASA DE MISERICÓRDIA DE JOANÓPOLIS</Text>
                    </View>
                </View>

                <View style={{ marginTop: '15px' }}>
                    <Text style={{ textAlign: 'center', marginBottom: '15px' }}>ATESTADO MÉDICO</Text>

                    <View style={{ display: 'flex', justifyContent: 'space-between', fontSize: 10 }}>
                        <View>
                            <Text>DADOS DO PACIENTE</Text>
                            <Text>NOME: {data?.nome}</Text>
                            <Text>NASCIMENTO: {data?.nascimento}</Text>
                            <Text>ATENDIMENTO: {data?.data}</Text>
                        </View>
                    </View>

                    <View style={{ marginTop: '15px' }}>
                        <Text>
                            ATESTO PARA FINS DE TRABALHO QUE O(A) PACIENTE ACIMA ESTEVE EM CONSULTA MÉDICA NO DIA DE HOJE E NECESSITA
                            PERMANECER AFASTADO(A) DE SUAS ATIVIDADES LABORATIVAS PELO PERÍODO DE {data?.dias} DIAS A CONTAR DESTA DATA.
                        </Text>
                        <Text style={{ textTransform: 'uppercase', marginTop: '10px' }}>{data.observation}</Text>
                    </View>
                </View>

                <View fixed style={styles.fixed}>
                    <View
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            width: '50%',
                            justifyContent: 'flex-start',
                            alignItems: 'center'
                        }}
                    >
                        <Image fixed src={SantaCasaLogo} alt="Santa Casa Logotipo" style={{ width: '30px' }} />
                        <View
                            fixed
                            style={{
                                padding: '20px',
                                gap: '5px',
                                fontSize: 5
                            }}
                        >
                            <Text>SANTA CASA DE MISERICÓRDIA DE JOANÓPOLIS</Text>
                            <Text>RUA FRANCISCO WOLHERS N°57 - CENTRO - JOANÓPOLIS - SP</Text>
                            <Text>CEP: 12980-000 - TELEFONE: (11) 4539-9333</Text>
                        </View>
                    </View>
                    <Image style={{ display: 'flex', width: '15%', justifyContent: 'flex-end', alignItems: 'center' }} src={BwLogo} />
                </View>
            </Page>
        </Document>
    );
};

export default PdfAtestado;
