import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useApi } from 'Service/axios';
import SantaCasaLogo from '../../../../assets/images/Logos/logo2.png';

const RelatorioEstoqueGeralSimples = () => {
    // ---- INSTÂNCIAS ----
    const { id } = useParams();
    const api = useApi();
    // ---- STATES ----
    const [movSumarizado, setMovSumarizado] = useState([]);
    const [loading, setLoading] = useState(true);
    // ---- FUNÇÕES ----
    const criarNovoArray = (dados) => {
        // Usando o método map para iterar sobre os dados
        const novoArray = dados.map((array) => {
            // Usando o método reduce para calcular o saldo total
            const saldoTotal = array.reduce((saldo, elemento) => saldo + elemento.quantidade_disponivel, 0);

            // Retornando um objeto com as informações desejadas
            return {
                id: array[0].item_id,
                descricao: array[0].item.descricao,
                unidade: array[0].item.unidade.descricao,
                saldoTotal: saldoTotal
            };
        });

        return novoArray;
    };

    const getMovSumarizado = async (id) => {
        try {
            const apiFunctions = {
                todos: api.getMovSumarizados,
                psicotropicos: api.getPsicotropicos,
                abertos: api.getAbertos
            };

            const apiFunction = apiFunctions[id] || api.getMovSumarizadosByProductId;

            const response = await apiFunction(id);

            const descricaoArrays = {};

            // Iterar sobre o array original
            response.data.forEach((movimento, index) => {
                const descricao = movimento?.item?.descricao;

                if (!descricaoArrays[descricao]) {
                    descricaoArrays[descricao] = [];
                }

                descricaoArrays[descricao].push(movimento);
            });

            // Converter o objeto de arrays de volta para um array
            const finalRes = criarNovoArray(Object.values(descricaoArrays));

            setMovSumarizado(finalRes);
            setTimeout(() => {
                setLoading(false);
            }, 1000);
        } catch (error) {
            toast.error(`Erro ao buscar dados: ${error.message}`);
        }
    };

    if (!loading) window.print();

    useEffect(() => {
        getMovSumarizado(id);
    }, [id]);

    return (
        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            <Typography variant="h3">RELATÓRIO DE ESTOQUE GERAL SIMPLES</Typography>
            <Typography>FILTRO: {id}</Typography>
            <Box sx={{ width: '100%' }}>
                <TableContainer component={Paper}>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="center">
                                    <b>CÓDIGO</b>
                                </TableCell>
                                <TableCell align="center">
                                    <b>DESCRIÇÃO</b>
                                </TableCell>
                                <TableCell align="center">
                                    <b>UN</b>
                                </TableCell>
                                <TableCell align="center">
                                    <b>QUANTIDADE TOTAL</b>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {movSumarizado.map((element, index) => {
                                return (
                                    element.saldoTotal > 0 && (
                                        <TableRow key={index}>
                                            <TableCell align="center" l sx={{ fontSize: '.87em', lineHeight: '.5em' }}>
                                                {element.id}
                                            </TableCell>
                                            <TableCell align="center" sx={{ fontSize: '.87em', lineHeight: '.5em' }}>
                                                {element.descricao}
                                            </TableCell>
                                            <TableCell align="center" sx={{ fontSize: '.87em', lineHeight: '.5em' }}>
                                                {element.unidade}
                                            </TableCell>
                                            <TableCell align="center" sx={{ fontSize: '.87em', lineHeight: '.5em' }}>
                                                {element.saldoTotal}
                                            </TableCell>
                                        </TableRow>
                                    )
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        padding: '.5em',
                        justifyContent: 'center',
                        alignItems: 'center',
                        borderTop: '1px solid #000'
                    }}
                >
                    <img src={SantaCasaLogo} alt="Santa Casa Logotipo" style={{ width: '90px' }} />
                    <Box>
                        <Typography>SANTA CASA DE MISERICÓRDIA DE JOANÓPOLIS</Typography>
                        <Typography>RUA FRANCISCO WOLHERS N°57 - CENTRO - JOANÓPOLIS - SP</Typography>
                        <Typography>CEP: 12980-000 - TELEFONE: (11) 4539-9333</Typography>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default RelatorioEstoqueGeralSimples;
