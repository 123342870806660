import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Autocomplete, TextField } from '@mui/material';
import { useEffect } from 'react';
import { useApi } from 'Service/axios';
import { useState } from 'react';
import { IconTrash } from '@tabler/icons';
import { toast } from 'react-toastify';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: 2,
    maxHeight: '90vh',
    minHeight: '30vh'
};

export function LaboratoryModal({ open, setOpen, name, prontuarioId, get }) {
    const handleClose = () => setOpen(false);
    const [exams, setExams] = useState([]);
    const [selectedExams, setSelectedExams] = useState([]);
    const [inputValue, setInputValue] = useState({ nome: '' });
    const [observation, setObservation] = useState('');
    const api = useApi();

    async function getExams() {
        try {
            const { data } = await api.getAllExames();

            setExams(data);
        } catch (error) {
            toast.error(error?.response?.data?.message ?? error?.message);
        }
    }

    const handleInsertExam = (selectedExam) => {
        setSelectedExams((prevSelectedExams) => [...prevSelectedExams, selectedExam]);
        setInputValue({ nome: '' }); // Limpa o valor do input após a seleção do exame
    };

    useEffect(() => {
        if (open) {
            getExams();
        }
    }, [open]);

    const handleDeleteExam = (examId) => {
        setSelectedExams((prevSelectedExams) => prevSelectedExams.filter((exam) => exam.id !== examId));
    };

    // ...

    <Button variant="contained" color="error" onClick={() => handleDeleteExam(exam.id)}>
        <IconTrash />
    </Button>;

    const handleDeleteSelectedExam = (examId) => {
        setSelectedExams((prevSelectedExams) => prevSelectedExams.filter((exam) => exam.id !== examId));
    };

    async function handleSubmit() {
        const id = localStorage.getItem('id');

        try {
            const payloads = selectedExams.map((exam) => ({
                id_prontuario: prontuarioId,
                id_exame_prontuario: exam.especial ? null : exam.id,
                id_exame_especial: exam.especial ? exam.id : null,
                id_medico: id,
                observacao: observation
            }));

            const promises = payloads.map((payload) => api.linkExamLaboratory(payload));

            // Use Promise.all para aguardar todas as requisições
            await Promise.all(promises);
            get();
            handleClose();
            setObservation('');
            setSelectedExams([]);
            setInputValue({ nome: '' });
        } catch (error) {
            console.error('Erro ao enviar os exames:', error);
        }
    }

    return (
        <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <Box sx={style}>
                <Typography variant="h4" gutterBottom>
                    LABORATÓRIO - {name}
                </Typography>
                <form style={{ display: 'flex', gap: '10px', marginBottom: '20px' }} onSubmit={(e) => e.preventDefault()}>
                    <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={exams}
                        getOptionLabel={(option) => option.nome}
                        inputValue={inputValue} // Define o valor do input
                        onInputChange={(event, newInputValue) => setInputValue(newInputValue)} // Atualiza o valor do input
                        sx={{ width: '100%', marginTop: '10px' }}
                        renderInput={(params) => <TextField {...params} fullWidth label="Exames" />}
                        onChange={(event, newValue) => newValue && handleInsertExam(newValue)}
                    />
                </form>

                {selectedExams.length > 0 && (
                    <Typography variant="h6" sx={{ marginBottom: '10px' }}>
                        Exames Selecionados:
                    </Typography>
                )}
                <Box sx={{ maxHeight: '250px', overflowY: 'auto', marginBottom: '20px' }}>
                    {selectedExams.map((exam) => (
                        <Box
                            key={exam.id}
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                border: '1px solid #ccc',
                                borderRadius: '5px',
                                alignItems: 'center',
                                padding: '10px',
                                marginTop: '10px'
                            }}
                        >
                            <Typography>{exam.nome}</Typography>
                            <Button variant="contained" color="error" onClick={() => handleDeleteSelectedExam(exam.id)}>
                                <IconTrash />
                            </Button>
                        </Box>
                    ))}
                </Box>
                {selectedExams.length > 0 && (
                    <>
                        <TextField
                            label="Observação"
                            rows={3}
                            multiline
                            fullWidth
                            sx={{ marginBottom: '20px' }}
                            value={observation}
                            onChange={(e) => setObservation(e.target.value)}
                        />
                        <Button variant="contained" fullWidth onClick={handleSubmit}>
                            Salvar
                        </Button>
                    </>
                )}
            </Box>
        </Modal>
    );
}
