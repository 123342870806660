import { useParams, Link } from 'react-router-dom';
import MainCard from 'ui-component/cards/MainCard';
import { Button, Grid, Box, TablePagination, Autocomplete, Tooltip, TextField } from '@mui/material';
import { gridSpacing } from 'store/constant';
import { useState } from 'react';
import { useEffect } from 'react';
import { useApi } from 'Service/axios';
import { HeaderBox, TableBox } from './style';
import { toast } from 'react-toastify';
import ItemTabela from 'components/Estoque/Item/Tabela';
import DrawerCreateItem from 'components/Estoque/Item/DrawerCreate';
import DrawerEditItem from 'components/Estoque/Item/DrawerEdit';
import DrawerCreateFornecedor from 'components/Estoque/Fornecedor/DrawerCreate';
import DrawerEditFornecedor from 'components/Estoque/Fornecedor/DrawerEdit';
import FornecedorTabela from 'components/Estoque/Fornecedor/Tabela';

const Fornecedor = () => {
    // ============================= INSTÂNCIAS =========================
    const api = useApi();
    // ============================= STATES =============================
    const [allFornecedor, setAllFornecedor] = useState([]);
    const [selectedFornecedor, setSelectedFornecedor] = useState(null);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [open, setOpen] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [fornecedor, setFornecedor] = useState(null);

    // ============================= FUNÇÕES =============================

    const handleClickOpenEdit = (e, item) => {
        e.preventDefault();
        setFornecedor(item);
        setOpenEdit(true);
    };

    const handleCloseEdit = () => {
        setOpenEdit(false);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const getFornecedores = async () => {
        try {
            const response = await api.getAllFornecedor();
            setAllFornecedor(response.data);
        } catch (error) {
            toast.error(error.response.data.mensagem);
        }
    };

    useEffect(() => {
        getFornecedores();
    }, []);

    return (
        <MainCard title="FORNECEDOR">
            <Grid container spacing={gridSpacing}>
                {/* DRAWERS */}
                <DrawerCreateFornecedor open={open} handleClose={handleClose} getFornecedores={getFornecedores} />
                <DrawerEditFornecedor open={openEdit} handleClose={handleCloseEdit} getFornecedores={getFornecedores} item={fornecedor} />
                <HeaderBox>
                    <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={allFornecedor}
                        getOptionLabel={(fornecedor) => fornecedor.nome_fantasia}
                        value={selectedFornecedor}
                        onChange={(event, newValue) => {
                            setSelectedFornecedor(newValue);
                        }}
                        sx={{ width: 300 }}
                        renderInput={(params) => <TextField {...params} label="Pesquisar Fornecedor" />}
                    />
                    <Button variant="contained" size="medium" onClick={() => setOpen(true)} sx={{ height: '40px' }}>
                        Adicionar
                    </Button>
                </HeaderBox>

                <TableBox>
                    <FornecedorTabela
                        allFornecedor={allFornecedor}
                        setAllFornecedor={setAllFornecedor}
                        selectedFornecedor={selectedFornecedor}
                        page={page}
                        rowsPerPage={rowsPerPage}
                        getFornecedores={getFornecedores}
                        handleClickOpenEdit={handleClickOpenEdit}
                    />
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={allFornecedor.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </TableBox>
            </Grid>
        </MainCard>
    );
};

export default Fornecedor;
