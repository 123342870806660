import { Box, Grid, IconButton, Switch, Tooltip, Typography } from '@mui/material';
import Aguardando from 'components/Triagem/Cards/Aguardando';
import TempoEspera from 'components/Triagem/Cards/TempoEspera';
import TempoMedio from 'components/Triagem/Cards/TempoMedio';
import { gridSpacing } from 'store/constant';
import MainCard from 'ui-component/cards/MainCard';
import { TimerArea } from './styles';
import { DataGrid } from '@mui/x-data-grid';
import VisibilityIcon from '@mui/icons-material/Visibility';
import SendIcon from '@mui/icons-material/Send';
import InventoryIcon from '@mui/icons-material/Inventory';
import ExamModal from './components/ExamModal';
import { AnalysesModal } from './components/AnalyseModal';
import { useApi } from 'Service/axios';
import { useEffect, useState } from 'react';
import { formatarData } from 'utils/formatarData';
import { toast } from 'react-toastify';
import Laco from '../../../assets/images/Logos/laco.jpg';

export function CustomerService() {
    const api = useApi();
    const [openExamModal, setOpenExamModal] = useState(false);
    const [currentRow, setCurrentRow] = useState(null);
    const [openAnalyse, setOpenAnalyse] = useState(false);
    const [rows, setRows] = useState([]);
    const [defaultRows, setDefaultRows] = useState([]);
    const [averageWaitTime, setAverageWaitTime] = useState('');
    const [waitTime, setWaitTime] = useState('');
    const [checked, setChecked] = useState(false);

    const handleChange = (event) => {
        setChecked(event.target.checked);
    };

    const columns = [
        { field: 'id', headerName: 'F.A', flex: 0.5 },
        {
            field: 'patient',
            headerName: 'Paciente',
            flex: 1,
            renderCell: (params) => (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    {params.row.patient}{' '}
                    {params.row.autismo && <img src={Laco} alt="Laco" style={{ width: '30px', height: '30px', borderRadius: '50%' }} />}
                </Box>
            )
        },
        { field: 'age', headerName: 'Idade', flex: 0.5 },
        { field: 'date', headerName: 'Data', flex: 1 },
        { field: 'hour', headerName: 'Hora', flex: 0.5 },
        { field: 'situation', headerName: 'Situação', flex: 1 },
        {
            field: 'actions',
            headerName: 'Ações',
            flex: 1,
            renderCell: (params) => (
                <Box>
                    <Tooltip title="Análise">
                        <IconButton
                            onClick={() => {
                                setOpenAnalyse(true);
                                setCurrentRow(params.row);
                            }}
                        >
                            <VisibilityIcon />
                        </IconButton>
                    </Tooltip>

                    <Tooltip title="Raio-x">
                        <IconButton
                            onClick={() => {
                                setOpenExamModal(true);
                                setCurrentRow(params.row);
                            }}
                        >
                            <InventoryIcon />
                        </IconButton>
                    </Tooltip>
                </Box>
            )
        }
    ];

    async function getAllProntuario() {
        try {
            const { data } = await api.getAllOpenExamsRaiox();

            // Use o método reduce para agrupar os campos por categoria
            const camposAgrupados = data.reduce((agrupados, item) => {
                const prontuario = item.prontuario.id;

                // Se a categoria já existir no objeto agrupados, adicione o item a ela
                if (agrupados[prontuario]) {
                    agrupados[prontuario].push(item);
                } else {
                    // Se a categoria ainda não existir, crie um novo array com o item
                    agrupados[prontuario] = [item];
                }

                return agrupados;
            }, {});

            const row = data.map((item) => ({
                ...item,
                id: item?.prontuario?.id,
                patient: item?.prontuario?.paciente.nome_completo,
                patientId: item?.prontuario?.paciente.id,
                age: item?.prontuario?.paciente.idade + ' anos',
                date: formatarData(item?.prontuario?.data_atendimento),
                hour: item?.prontuario?.hora_atendimento,
                situation: item?.prontuario?.status,
                createdAt: item?.createdAt,
                autismo: item?.prontuario?.paciente.autismo
            }));
            setRows(row);
            setDefaultRows(row);
        } catch (error) {
            toast.error(error?.response?.data?.message ?? error?.message);
        }
    }

    // CALCULO DE TEMPO DE ESPERA
    useEffect(() => {
        if (rows.length > 0) {
            const calculateWaitTime = () => {
                const recordDate = new Date(rows[0]?.createdAt);
                const currentDate = new Date();
                const timeDifference = currentDate.getTime() - recordDate.getTime();
                const waitTimeInSeconds = Math.floor(timeDifference / 1000);

                const hours = Math.floor(waitTimeInSeconds / 3600);
                const minutes = Math.floor((waitTimeInSeconds % 3600) / 60);
                const seconds = waitTimeInSeconds % 60;

                setWaitTime(`${hours}h ${minutes}m ${seconds}s`);
            };

            const intervalId = setInterval(calculateWaitTime, 1000);

            return () => clearInterval(intervalId);
        }
    }, [rows]);

    // CALCULO DE TEMPO MEDIO
    useEffect(() => {
        if (rows.length > 0) {
            const calculateAverageWaitTime = () => {
                const currentDate = new Date();
                const totalWaitTimeInSeconds = rows.reduce((accumulator, record) => {
                    const recordDate = new Date(record.createdAt);
                    const timeDifference = currentDate.getTime() - recordDate.getTime();
                    return accumulator + Math.floor(timeDifference / 1000);
                }, 0);

                const averageWaitTimeInSeconds = totalWaitTimeInSeconds / rows.length;
                const hours = Math.floor(averageWaitTimeInSeconds / 3600);
                const minutes = Math.floor((averageWaitTimeInSeconds % 3600) / 60);
                const seconds = Math.floor(averageWaitTimeInSeconds % 60);

                setAverageWaitTime(`${hours}h ${minutes}m ${seconds}s`);
            };

            const intervalId = setInterval(calculateAverageWaitTime, 1000);

            return () => clearInterval(intervalId);
        }
    }, [rows]);

    useEffect(() => {
        const getData = () => {
            getAllProntuario();
        };
        getData();
        const interval = setInterval(getData, 10000);
        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        if (checked) {
            setRows(defaultRows.filter((i) => i.exame_prontuario?.prontuario?.interno));
        } else {
            setRows(defaultRows.filter((i) => !i.exame_prontuario?.prontuario?.interno));
        }
    }, [checked]);

    return (
        <MainCard title="RAIO-X">
            <Grid spacing={gridSpacing} sx={{ position: 'relative' }}>
                <ExamModal open={openExamModal} setOpen={setOpenExamModal} currentRow={currentRow} getAllProntuario={getAllProntuario} />
                <AnalysesModal open={openAnalyse} setOpen={setOpenAnalyse} currentRow={currentRow} />
                <TimerArea>
                    <TempoMedio tempoMedio={averageWaitTime} />
                    <TempoEspera tempoEspera={waitTime} />
                    <Aguardando aguardandoQTD={rows.length} />
                </TimerArea>
                <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                    <Box sx={{ display: 'flex', gap: '.4rem', marginLeft: 'auto', alignItems: 'center' }}>
                        <Typography>Interno</Typography>
                        <Switch checked={checked} onChange={handleChange} inputProps={{ 'aria-label': 'controlled' }} />
                    </Box>
                    <DataGrid rows={rows} columns={columns} hideFooter={true} />
                </Box>
            </Grid>
        </MainCard>
    );
}
