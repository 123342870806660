import {
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    Tooltip
} from '@mui/material';
import React from 'react';
import { OrderButton } from './style';
import { Link } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import Row from './Row';
import { useState } from 'react';

const ItemTabela = ({ allItem, setAllItem, selectedItem, getItems, page, rowsPerPage, handleClickOpenEdit }) => {
    const [orderById, setOrderById] = useState('desc');
    const [orderByNome, setOrderByNome] = useState('desc');
    const [orderByCategoria, setOrderByCategoria] = useState('des');

    const handleSortById = () => {
        const sortedItem = [...allItem].sort((a, b) => {
            if (orderById === 'desc') {
                return b.id - a.id;
            } else {
                return a.id - b.id;
            }
        });

        setAllItem(sortedItem);
        setOrderById(orderById === 'asc' ? 'desc' : 'asc');
    };

    const handleSortByNome = () => {
        const sortedItem = [...allItem].sort((a, b) => {
            const nomeA = a.apelido.toUpperCase();
            const nomeB = b.apelido.toUpperCase();

            if (orderByNome === 'desc') {
                if (nomeA < nomeB) return -1;
                if (nomeA > nomeB) return 1;
            } else {
                if (nomeA > nomeB) return -1;
                if (nomeA < nomeB) return 1;
            }

            return 0;
        });

        setAllItem(sortedItem);
        setOrderByNome(orderByNome === 'asc' ? 'desc' : 'asc');
    };

    const handleSortByCategoria = () => {
        const sortedItem = [...allItem].sort((a, b) => {
            const nomeA = a.categoria.descricao.toUpperCase();
            const nomeB = b.categoria.descricao.toUpperCase();

            if (orderByCategoria === 'desc') {
                if (nomeA < nomeB) return -1;
                if (nomeA > nomeB) return 1;
            } else {
                if (nomeA > nomeB) return -1;
                if (nomeA < nomeB) return 1;
            }

            return 0;
        });

        setAllItem(sortedItem);
        setOrderByCategoria(orderByCategoria === 'asc' ? 'desc' : 'asc');
    };

    return (
        <TableContainer
            component={Paper}
            sx={{
                maxHeight: '400px'
            }}
        >
            <Table size="small" sx={{ minWidth: 650 }} aria-label="simple table" stickyHeader>
                <TableHead>
                    <TableRow>
                        <OrderButton>
                            <TableSortLabel onClick={handleSortById} direction={orderById === 'asc' ? 'desc' : 'asc'}>
                                <b>ID</b>
                            </TableSortLabel>
                        </OrderButton>
                        <OrderButton align="center">
                            <TableSortLabel onClick={handleSortByNome} direction={orderByNome === 'asc' ? 'desc' : 'asc'}>
                                <b>Descricao</b>
                            </TableSortLabel>
                        </OrderButton>
                        <OrderButton align="center">
                            <TableSortLabel onClick={handleSortByCategoria} direction={orderByCategoria === 'asc' ? 'desc' : 'asc'}>
                                <b>Categoria</b>
                            </TableSortLabel>
                        </OrderButton>
                        <TableCell align="center">
                            <b>Ações</b>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {allItem.length > 0 &&
                        allItem
                            .filter((item) => (selectedItem ? item.descricao.includes(selectedItem.descricao) : true))
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((item) => <Row key={item.id} item={item} getItems={getItems} handleClickOpenEdit={handleClickOpenEdit} />)}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default ItemTabela;
