import React from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Drawer,
    FormControl,
    InputLabel,
    OutlinedInput,
    TextField,
    Tooltip,
    Typography
} from '@mui/material';
import { useState } from 'react';
import { Box } from '@mui/system';
import CloseIcon from '@mui/icons-material/Close';
import { v4 as uuidv4 } from 'uuid';

const CreateMedicacaoTriagem = ({ open, handleClose, setItens, removerCampoItem, indiceCampoItem }) => {
    /////////////////////////////// STATES /////////////////////////
    const [openDial, setOpenDialog] = useState();
    const [medicacao, setMedicacao] = useState([]);
    /////////////////////////////// FUNÇÕES /////////////////////////

    const handleSubmit = (e) => {
        e.preventDefault();
        setItens((prevItens) => [...prevItens, { id: uuidv4(), medicacao }]);
        setMedicacao('');
    };

    return (
        <Box>
            <Dialog open={openDial}>
                <DialogTitle>Atenção</DialogTitle>
                <DialogContent>
                    <Typography>Ao fechar todo o cadastro desse item sera cancelado, continuar?</Typography>
                </DialogContent>
                <DialogActions>
                    <Box sx={{ display: 'flex' }}>
                        <Button color="error" onClick={() => setOpenDialog(false)}>
                            Cancelar
                        </Button>
                        <Button onClick={() => removerCampoItem(0, indiceCampoItem)}>Confirmar</Button>
                    </Box>
                </DialogActions>
            </Dialog>
            <Dialog open={open} onClose={handleClose}>
                <DialogContent>
                    <Box sx={{ width: '450px', padding: '.75em', height: '100%' }}>
                        <form onSubmit={handleSubmit}>
                            <Box sx={{ width: '100%', display: 'flex', gap: '1em', marginTop: '15px' }}>
                                <FormControl fullWidth>
                                    <h3 style={{ textAlign: 'center' }}>Insira a Medicação</h3>
                                    <TextField
                                        label="Medicação"
                                        type="text"
                                        name="nome"
                                        value={medicacao}
                                        sx={{ width: '100%', marginTop: '1em' }}
                                        onChange={(e) => setMedicacao(e.target.value)}
                                    />
                                </FormControl>
                            </Box>

                            <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '2em', gap: '1em' }}>
                                <Tooltip title="Fechar">
                                    <Button variant="contained" color="error" onClick={() => handleClose()}>
                                        <CloseIcon />
                                    </Button>
                                </Tooltip>
                                <Button variant="contained" type="submit">
                                    Salvar
                                </Button>
                            </Box>
                        </form>
                    </Box>
                </DialogContent>
            </Dialog>
            {/* <Drawer anchor="right" open={open}>
                <Box sx={{ width: '450px', padding: '.75em', height: '100%' }}>
                    <Box>
                        <Box sx={{ width: '100%', display: 'flex', gap: '1em', marginTop: '15px' }}>
                            <FormControl fullWidth>
                                <InputLabel htmlFor="outlined-adornment-nome-register">MEDICAÇÃO EM USO</InputLabel>
                                <OutlinedInput
                                    id="outlined-adornment-nome-register"
                                    type="text"
                                    name="nome"
                                    value={medicacao}
                                    sx={{ width: '100%', marginTop: '1em' }}
                                    onChange={(e) => setMedicacao(e.target.value)}
                                />
                            </FormControl>
                        </Box>

                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '2em', gap: '1em' }}>
                            <Tooltip title="Fechar">
                                <Button variant="contained" color="error" onClick={() => handleClose()}>
                                    <CloseIcon />
                                </Button>
                            </Tooltip>
                            <form onSubmit={handleSubmit}>
                                <Button variant="contained" type="submit">
                                    Salvar
                                </Button>
                            </form>
                        </Box>
                    </Box>
                </Box>
            </Drawer> */}
        </Box>
    );
};

export default CreateMedicacaoTriagem;
