import MainCard from 'ui-component/cards/MainCard';
import { Button, Grid, TextField, Typography, Box, InputLabel, Select, MenuItem, ButtonGroup, FormLabel } from '@mui/material';
import { gridSpacing } from 'store/constant';
import { Outlet } from 'react-router-dom';

import Logo from '../../src/assets/images/Logos/logo.png';
import Logo2 from '../../src/assets/logobw2.png';
import { useState } from 'react';
import { useEffect } from 'react';

const NurginLayoute = () => {
    const [dataAtual, setDataAtual] = useState(new Date());
    const formatarNumero = (numero) => {
        return numero < 10 ? `0${numero}` : numero;
    };

    const dia = formatarNumero(dataAtual.getDate());
    const mes = formatarNumero(dataAtual.getMonth() + 1);
    const ano = dataAtual.getFullYear();

    // ----EFFECTS --- //

    useEffect(() => {
        const intervalId = setInterval(() => {
            setDataAtual(new Date());
        }, 1000);

        return () => clearInterval(intervalId);
    }, []);

    return (
        <Box>
            <Box
                sx={{
                    display: 'flex',
                    gap: '2rem',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    padding: '0 30px',
                    width: '100%'
                }}
            >
                <Box>
                    <img src={Logo} alt="logo Santa Casa" style={{ width: '100px', height: 'auto', marginRight: '10px' }} />
                    <img src={Logo2} alt="logo Santa Casa" style={{ width: '300px', marginRight: '10px', marginTop: '10px' }} />
                </Box>
                <Box sx={{ display: 'flex', gap: '1rem' }}>
                    <Typography variant="h3">
                        DATA: {dia}/{mes}/{ano}
                    </Typography>
                    <Typography variant="h3">
                        HORA: {formatarNumero(dataAtual.getHours())}:{formatarNumero(dataAtual.getMinutes())}:
                        {formatarNumero(dataAtual.getSeconds())}
                    </Typography>
                </Box>
            </Box>

            <Outlet />
        </Box>
    );
};

export default NurginLayoute;
