import { Box, Grid } from '@mui/material';
import { gridSpacing } from 'store/constant';
import MainCard from 'ui-component/cards/MainCard';
import { DataGrid } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { useApi } from 'Service/axios';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import { formatarData } from 'utils/formatarData';

export function ConsultaProcedimentos() {
    const { id, nome } = useParams();
    const api = useApi();
    const [rows, setRows] = useState([]);
    const columns = [
        { field: 'id', headerName: 'ID', flex: 0.2 },
        { field: 'exam', headerName: 'PROCEDIMENTO', flex: 1 },
        { field: 'date', headerName: 'DATA', flex: 1 },
        { field: 'hour', headerName: 'HORA', flex: 1 },
        { field: 'observation', headerName: 'OBSERVACAO', flex: 1 }
    ];

    async function getAllItens(id) {
        try {
            const { data } = await api.getProcedimentosEcgFehadosByProntuarioId(id);
            const row = data.map((item) => ({
                id: item?.id,
                prontuarioId: item?.controle_procedimento?.prontuario?.id,
                pacienteId: item?.controle_procedimento?.prontuario?.paciente?.id,
                exam: item?.controle_procedimento?.procedimento?.descricao,
                date: formatarData(item?.createdAt.split('T')[0]),
                hour: item?.createdAt.split('T')[1].split('.')[0],
                status: item?.controle_procedimento?.finalizado ? 'FINALIZADO' : 'AGUARDANDO',
                observation: item?.observacao
            }));

            setRows(row);
        } catch (error) {
            toast.error(error?.response?.data?.message ?? error?.message);
        }
    }

    useEffect(() => {
        getAllItens(id);
    }, []);

    return (
        <MainCard title={`FA ${id} - ${nome}`}>
            <Grid spacing={gridSpacing} sx={{ position: 'relative' }}>
                <Box sx={{ width: '100%' }}>
                    <DataGrid rows={rows} columns={columns} hideFooter={true} />
                </Box>
            </Grid>
        </MainCard>
    );
}
