import { useEffect, useState } from 'react';
import { gridSpacing } from 'store/constant';
import { useTheme } from '@mui/material/styles';
import MainCard from 'ui-component/cards/MainCard';
import {
    Box,
    Button,
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    OutlinedInput,
    Select,
    Typography,
    MenuItem,
    Stepper,
    Step,
    StepLabel,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Switch
} from '@mui/material';
import * as Yup from 'yup';
import { Formik, useFormikContext } from 'formik';
import useScriptRef from 'hooks/useScriptRef';
import InputMask from 'react-input-mask';
import { useApi } from 'Service/axios';
import { FormBox } from '../../RegisterForms/UserRegisterForm/style';
import { useParams, useNavigate } from 'react-router';
import { removeMask } from 'utils/removeMask';
import { formatDate } from 'utils/formatDate';
import { toast } from 'react-toastify';

const EditarProntuario = ({ ...others }) => {
    // ==== INSTÂNCIAS =====
    const { id } = useParams();
    const theme = useTheme();
    const navigate = useNavigate();
    const api = useApi();
    const maxEtapa = 3;
    const scriptedRef = useScriptRef();
    // ==== STATES =====
    const [etapa, setEtapa] = useState(0);
    const [open, setOpen] = useState(false);
    const [paciente, setPaciente] = useState(null);
    const [checkInfo, setCheckInfo] = useState(true);
    const [allConvenio, setAllConvenio] = useState([]);
    const [switchCheck, setSwitchCheck] = useState();
    const [switchCheckAtivo, setSwitchCheckAtivo] = useState();
    // ==== FUNÇÕESS =====
    const getConvenio = async () => {
        const response = await api.getAllConvenio();
        setAllConvenio(response.data);
    };

    const getPaciente = async (id) => {
        const response = await api.getPacienteById(id);
        setPaciente(response.data);
    };

    const handleProximaEtapa = async (values) => {
        switch (true) {
            case etapa === 0:
                if (values.nome === '' || values.nome === null) {
                    toast.error('O nome do paciente é obrigatório');
                    return;
                }

                setEtapa((prevEtapa) => prevEtapa + 1);
                break;
            case etapa === 1:
                if (checkInfo) {
                    if (values.cartao_sus === '') {
                        toast.error('O nome cartão do SUS é obrigatório');
                        return;
                    }
                    const responseNome = await api.getPacienteByNome(values.nome.toUpperCase());
                    const responseSUS = await api.getPacienteBySUS(removeMask(String(values.cartao_sus)));
                    if (responseNome.data.status && responseSUS.data.status) {
                        setOpen(true);
                        return;
                    }
                    setEtapa((prevEtapa) => prevEtapa + 1);
                    break;
                }
            default:
                setEtapa((prevEtapa) => prevEtapa + 1);
                break;
        }
    };
    const handleEtapaAnterior = () => {
        setEtapa((prevEtapa) => prevEtapa - 1);
    };

    const handleBackTable = (e) => {
        e.preventDefault();
        history.back();
    };

    const handleCepChange = async (event, changeValue) => {
        const cep = removeMask(event.target.value);

        if (cep.length === 8) {
            try {
                const cepData = await api.getCepLocation(cep);

                if (cepData) {
                    const { ibge, bairro, localidade, logradouro, uf } = cepData?.data;
                    // chamada para api ibge
                    //const ibgeData = await api.getIbgeLocation(ibge);

                    changeValue('ibge', ibge);
                    changeValue('bairro', bairro);
                    changeValue('cidade', localidade);
                    changeValue('endereco', logradouro);
                    changeValue('estado', uf);
                }
            } catch (error) {
                console.error(error);
            }
        }
    };

    const handleVerifyValues = async (values) => {
        values.cpf = removeMask(values.cpf);
        values.rg = removeMask(values.rg);
        values.cartao_sus = removeMask(values.cartao_sus);
        values.cep = removeMask(values.cep);
        values.telefone = removeMask(values.telefone);
        values.telefone_secundario = removeMask(values.telefone_secundario);
        values.ibge = removeMask(values.ibge);
        if (values.nome_social === null) values.nome_social = values.nome;
    };

    // SUBMIT
    const handleRegister = async (values) => {
        let payload = { ...values, autismo: switchCheck, cadastro_ativo: switchCheckAtivo };
        try {
            await api.updatePaciente(id, payload);
            toast.success('Paciente editado com sucesso!');
            navigate('/main/lobby');
        } catch (error) {
            toast.error(`Erro ao editar paciente: ${error.message}`);
        }
    };

    useEffect(() => {
        getPaciente(id);
        getConvenio();
    }, [id]);
    useEffect(() => {
        setSwitchCheck(paciente?.autismo);
        setSwitchCheckAtivo(paciente?.cadastro_ativo);
    }, [paciente]);

    return (
        <>
            {paciente !== null && (
                <MainCard title={`Editando Paciente: ${paciente.nome_completo}`}>
                    <Dialog open={open}>
                        <DialogTitle>Atenção</DialogTitle>
                        <DialogContent>
                            Já existe um paciente com este nome e cartão do sus cadastrado, deseja prosseguir com o registro?
                        </DialogContent>
                        <DialogActions>
                            <Button
                                color="error"
                                onClick={() => {
                                    setOpen(false), setCheckInfo(false);
                                }}
                            >
                                FECHAR
                            </Button>
                            <Button
                                variant="contained"
                                onClick={() => {
                                    setOpen(false), setEtapa((prevEtapa) => prevEtapa + 1);
                                }}
                            >
                                CONTINUAR
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <Grid container spacing={gridSpacing}>
                        <Box sx={{ width: '80%', margin: '0 auto' }}>
                            <Formik
                                initialValues={{
                                    nome: paciente?.nome_completo,
                                    nome_social: paciente?.nome_social,
                                    cpf: paciente?.cpf,
                                    sexo:
                                        paciente.sexo === null
                                            ? ''
                                            : paciente?.sexo === 3
                                            ? 'OUTROS'
                                            : paciente?.sexo === 1
                                            ? 'MASCULINO'
                                            : 'FEMININO',
                                    cor: paciente?.cor,
                                    estado_civil: paciente?.estado_civil,
                                    data_nascimento: paciente?.data_nascimento !== null ? formatDate(paciente?.data_nascimento) : '',
                                    rg: paciente?.rg,
                                    nome_mae: paciente?.nome_mae,
                                    nome_pai: paciente?.nome_pai,
                                    cartao_sus: paciente?.cartao_sus,
                                    convenio: paciente?.convenio_id,
                                    numero_carterinha: paciente?.numero_carteirinha,
                                    naturalidade: paciente?.naturalidade,
                                    cep: paciente?.cep,
                                    tipo_logradouro: paciente?.tipo_logradouro,
                                    endereco: paciente?.endereco,
                                    numero: paciente?.numero,
                                    bairro: paciente?.bairro,
                                    cidade: paciente?.cidade,
                                    ibge: paciente?.ibge,
                                    estado: paciente?.estado,
                                    telefone: paciente?.telefone,
                                    telefone_secundario: paciente?.telefone_secundario,
                                    obito: paciente?.obito === true ? '1' : '2',
                                    submit: null
                                }}
                                validationSchema={Yup.object().shape({
                                    nome: Yup.string().min(3).required('Nome é obrigatorio'),
                                    // nome_social: Yup.string().min(3).required('Nome Social é obrigatorio'),
                                    // sexo: Yup.string().min(3).required('Sexo é obrigatorio'),
                                    // cor: Yup.string().min(3).required('Cor é obrigatorio'),
                                    // data_nascimento: Yup.string().min(8).required('Data de Nascimento é obrigatorio'),
                                    // cpf: Yup.string().min(11).required('CPF é obrigatório'),
                                    // rg: Yup.string().max(9).required('RG é obrigatório'),
                                    // nome_mae: Yup.string().min(3).required('Nome da Mãe é obrigatório'),
                                    cartao_sus: Yup.string().min(15).required('Cartão do SUS é obrigatório')
                                    // cep: Yup.string().required('CEP é obrigatório'),
                                    // tipo_logradouro: Yup.string().required('Tipo Logradouro é obrigatório'),
                                    // endereco: Yup.string().required('Endereço é obrigatório'),
                                    // numero: Yup.string().min(1).required('Número é obrigatório'),
                                    // bairro: Yup.string().required('Bairro é obrigatório'),
                                    // cidade: Yup.string().required('Cidade é obrigatório'),
                                    // ibge: Yup.string().required('IBGE é obrigatório'),
                                    // estado: Yup.string().required('estado é obrigatório'),
                                    // telefone: Yup.string().required('telefone é obrigatório')
                                })}
                                onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                                    let payload = { ...values, autismo: switchCheck, cadastro_ativo: switchCheckAtivo };
                                    try {
                                        if (scriptedRef.current) {
                                            setStatus({ success: true });
                                            setSubmitting(false);
                                        }

                                        handleVerifyValues(values);
                                        handleRegister(values);
                                    } catch (err) {
                                        console.error(err);
                                        if (scriptedRef.current) {
                                            setStatus({ success: false });
                                            setErrors({ submit: err.msg });
                                            setSubmitting(false);
                                        }
                                    }
                                }}
                            >
                                {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, setFieldValue }) => (
                                    <form
                                        noValidate
                                        onSubmit={handleSubmit}
                                        {...others}
                                        style={{ display: 'flex', flexDirection: 'column' }}
                                    >
                                        <Typography variant="h4" sx={{ textAlign: 'center', margin: '1em', marginBottom: '3em' }}>
                                            Atualize as informações do paciente
                                        </Typography>
                                        <Stepper activeStep={etapa} alternativeLabel sx={{ marginBottom: '3em' }}>
                                            <Step>
                                                <StepLabel>Informações Pessoais</StepLabel>
                                            </Step>
                                            <Step>
                                                <StepLabel>Documentos</StepLabel>
                                            </Step>
                                            <Step>
                                                <StepLabel>Endereço</StepLabel>
                                            </Step>
                                            <Step>
                                                <StepLabel>Outros</StepLabel>
                                            </Step>
                                        </Stepper>
                                        {/* DADOS PESSOAIS */}
                                        {etapa === 0 && (
                                            <>
                                                {/* NOME / NOME SOCIAL */}
                                                <FormBox>
                                                    <FormControl
                                                        fullWidth
                                                        error={Boolean(touched.nome && errors.nome)}
                                                        sx={{ ...theme.typography.customInput }}
                                                    >
                                                        <InputLabel htmlFor="outlined-adornment-nome-register">Nome</InputLabel>
                                                        <OutlinedInput
                                                            id="outlined-adornment-nome-register"
                                                            type="text"
                                                            value={values.nome}
                                                            name="nome"
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                            inputProps={{}}
                                                        />
                                                        {touched.nome && errors.nome && (
                                                            <FormHelperText error id="standard-weight-helper-text--register">
                                                                {errors.nome}
                                                            </FormHelperText>
                                                        )}
                                                    </FormControl>

                                                    <FormControl
                                                        fullWidth
                                                        error={Boolean(touched.nome_social && errors.nome_social)}
                                                        sx={{ ...theme.typography.customInput }}
                                                    >
                                                        <InputLabel htmlFor="outlined-adornment-nome_social-register">
                                                            Nome Social
                                                        </InputLabel>
                                                        <OutlinedInput
                                                            id="outlined-adornment-nome_social-register"
                                                            type="text"
                                                            value={values.nome_social}
                                                            name="nome_social"
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                            inputProps={{}}
                                                        />
                                                        {touched.nome_social && errors.nome_social && (
                                                            <FormHelperText error id="standard-weight-helper-text--register">
                                                                {errors.nome_social}
                                                            </FormHelperText>
                                                        )}
                                                    </FormControl>
                                                </FormBox>

                                                {/* NATURALIDADE / TELEFONE */}
                                                <FormBox>
                                                    <FormControl
                                                        fullWidth
                                                        error={Boolean(touched.naturalidade && errors.naturalidade)}
                                                        sx={{ ...theme.typography.customInput }}
                                                    >
                                                        <InputLabel htmlFor="outlined-adornment-naturalidade-register">
                                                            Naturalidade
                                                        </InputLabel>
                                                        <OutlinedInput
                                                            id="outlined-adornment-naturalidade-register"
                                                            type="text"
                                                            value={values.naturalidade}
                                                            name="naturalidade"
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                            inputProps={{}}
                                                        />
                                                        {touched.naturalidade && errors.naturalidade && (
                                                            <FormHelperText error id="standard-weight-helper-text--register">
                                                                {errors.naturalidade}
                                                            </FormHelperText>
                                                        )}
                                                    </FormControl>
                                                    <FormControl
                                                        fullWidth
                                                        error={Boolean(touched.telefone && errors.telefone)}
                                                        sx={{ ...theme.typography.customInput }}
                                                    >
                                                        <InputLabel htmlFor="outlined-adornment-telefone-register">Celular</InputLabel>
                                                        <InputMask
                                                            mask="(99) 99999-9999"
                                                            value={values.telefone}
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                        >
                                                            {(inputProps) => (
                                                                <OutlinedInput
                                                                    id="outlined-adornment-telefone-register"
                                                                    type="text"
                                                                    name="telefone"
                                                                    inputProps={inputProps}
                                                                />
                                                            )}
                                                        </InputMask>
                                                        {touched.telefone && errors.telefone && (
                                                            <FormHelperText error id="standard-weight-helper-text--register">
                                                                {errors.telefone}
                                                            </FormHelperText>
                                                        )}
                                                    </FormControl>
                                                    <FormControl
                                                        fullWidth
                                                        error={Boolean(touched.telefone_secundario && errors.telefone_secundario)}
                                                        sx={{ ...theme.typography.customInput }}
                                                    >
                                                        <InputLabel htmlFor="outlined-adornment-telefone_secundario-register">
                                                            Telefone Secundário
                                                        </InputLabel>
                                                        <InputMask
                                                            mask="(99) 99999-9999"
                                                            value={values.telefone_secundario}
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                        >
                                                            {(inputProps) => (
                                                                <OutlinedInput
                                                                    id="outlined-adornment-telefone_secundario-register"
                                                                    type="text"
                                                                    name="telefone_secundario"
                                                                    inputProps={inputProps}
                                                                />
                                                            )}
                                                        </InputMask>
                                                        {touched.telefone_secundario && errors.telefone_secundario && (
                                                            <FormHelperText error id="standard-weight-helper-text--register">
                                                                {errors.telefone_secundario}
                                                            </FormHelperText>
                                                        )}
                                                    </FormControl>
                                                </FormBox>

                                                {/* SEXO / COR */}
                                                <FormBox>
                                                    <FormControl
                                                        fullWidth
                                                        error={Boolean(touched.sexo && errors.sexo)}
                                                        sx={{ ...theme.typography.customInput }}
                                                    >
                                                        <InputLabel htmlFor="outlined-adornment-sexo-register">Sexo</InputLabel>
                                                        <Select
                                                            style={{ height: '60px', padding: '20px 0px 0px 0px' }}
                                                            id="outlined-adornment-sexo-register"
                                                            type="text"
                                                            value={values.sexo}
                                                            name="sexo"
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                            inputProps={{}}
                                                        >
                                                            <MenuItem value={'MASCULINO'}>Masculino</MenuItem>
                                                            <MenuItem value={'FEMININO'}>Feminino</MenuItem>
                                                            <MenuItem value={'OUTROS'}>Outros</MenuItem>
                                                        </Select>
                                                        {touched.sexo && errors.sexo && (
                                                            <FormHelperText error id="standard-weight-helper-text--register">
                                                                {errors.sexo}
                                                            </FormHelperText>
                                                        )}
                                                    </FormControl>

                                                    <FormControl
                                                        fullWidth
                                                        error={Boolean(touched.cor && errors.cor)}
                                                        sx={{ ...theme.typography.customInput }}
                                                    >
                                                        <InputLabel htmlFor="outlined-adornment-cor-register">Cor</InputLabel>
                                                        <Select
                                                            style={{ height: '60px', padding: '20px 0px 0px 0px' }}
                                                            id="outlined-adornment-cor-register"
                                                            type="text"
                                                            value={values.cor}
                                                            name="cor"
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                            inputProps={{}}
                                                        >
                                                            <MenuItem value={'AMARELA'}>Amarela</MenuItem>
                                                            <MenuItem value={'BRANCA'}>Branca</MenuItem>
                                                            <MenuItem value={'PRETA'}>Preta</MenuItem>
                                                            <MenuItem value={'PARDA'}>Parda</MenuItem>
                                                            <MenuItem value={'INDIGENA'}>Indígena</MenuItem>
                                                        </Select>
                                                        {touched.telefone && errors.telefone && (
                                                            <FormHelperText error id="standard-weight-helper-text--register">
                                                                {errors.telefone}
                                                            </FormHelperText>
                                                        )}
                                                    </FormControl>
                                                </FormBox>

                                                {/* ESTADO CIVIL / DATA NASCIMENTO */}
                                                <FormBox>
                                                    <FormControl
                                                        fullWidth
                                                        error={Boolean(touched.estado_civil && errors.estado_civil)}
                                                        sx={{ ...theme.typography.customInput }}
                                                    >
                                                        <InputLabel htmlFor="outlined-adornment-estado_civil-register">
                                                            Estado Civil
                                                        </InputLabel>
                                                        <Select
                                                            style={{ height: '60px', padding: '20px 0px 0px 0px' }}
                                                            id="outlined-adornment-estado_civil-register"
                                                            type="text"
                                                            value={values.estado_civil}
                                                            name="estado_civil"
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                            inputProps={{}}
                                                        >
                                                            <MenuItem value={'SOLTEIRO'}>Solteiro(a)</MenuItem>
                                                            <MenuItem value={'CASADO'}>Casado(a)</MenuItem>
                                                            <MenuItem value={'VIVUVO'}>Viuvo(a)</MenuItem>
                                                            <MenuItem value={'DIVORCIADO'}>Divorciado(a)</MenuItem>
                                                            <MenuItem value={'DESQUITADO'}>Desquitado(a)</MenuItem>
                                                            <MenuItem value={'SEPARADO'}>Separado(a)</MenuItem>
                                                            <MenuItem value={'COMPANHEIERO'}>Companheiro(a)</MenuItem>
                                                        </Select>
                                                        {touched.estado_civil && errors.estado_civil && (
                                                            <FormHelperText error id="standard-weight-helper-text--register">
                                                                {errors.estado_civil}
                                                            </FormHelperText>
                                                        )}
                                                    </FormControl>

                                                    <FormControl
                                                        fullWidth
                                                        error={Boolean(touched.data_nascimento && errors.data_nascimento)}
                                                        sx={{ ...theme.typography.customInput }}
                                                    >
                                                        <InputLabel htmlFor="outlined-adornment-data_nascimento-register">
                                                            Data Nascimento
                                                        </InputLabel>
                                                        <OutlinedInput
                                                            id="outlined-adornment-data_nascimento-register"
                                                            type="date"
                                                            value={values.data_nascimento}
                                                            name="data_nascimento"
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                            inputProps={{}}
                                                        />
                                                        {touched.data_nascimento && errors.data_nascimento && (
                                                            <FormHelperText error id="standard-weight-helper-text--register">
                                                                {errors.data_nascimento}
                                                            </FormHelperText>
                                                        )}
                                                    </FormControl>
                                                </FormBox>

                                                {/* NOME MAE / NOME PAI */}
                                                <FormBox>
                                                    <FormControl
                                                        fullWidth
                                                        error={Boolean(touched.nome_mae && errors.nome_mae)}
                                                        sx={{ ...theme.typography.customInput }}
                                                    >
                                                        <InputLabel htmlFor="outlined-adornment-nome_mae-register">Nome Mãe</InputLabel>
                                                        <OutlinedInput
                                                            id="outlined-adornment-nome_mae-register"
                                                            type="text"
                                                            value={values.nome_mae}
                                                            name="nome_mae"
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                            inputProps={{}}
                                                        />
                                                        {touched.nome_mae && errors.nome_mae && (
                                                            <FormHelperText error id="standard-weight-helper-text--register">
                                                                {errors.nome_mae}
                                                            </FormHelperText>
                                                        )}
                                                    </FormControl>

                                                    <FormControl
                                                        fullWidth
                                                        error={Boolean(touched.nome_pai && errors.nome_pai)}
                                                        sx={{ ...theme.typography.customInput }}
                                                    >
                                                        <InputLabel htmlFor="outlined-adornment-nome_pai-register">Nome pai</InputLabel>
                                                        <OutlinedInput
                                                            id="outlined-adornment-nome_pai-register"
                                                            type="text"
                                                            value={values.nome_pai}
                                                            name="nome_pai"
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                            inputProps={{}}
                                                        />
                                                        {touched.nome_pai && errors.nome_pai && (
                                                            <FormHelperText error id="standard-weight-helper-text--register">
                                                                {errors.nome_pai}
                                                            </FormHelperText>
                                                        )}
                                                    </FormControl>
                                                </FormBox>
                                            </>
                                        )}
                                        {/* DOCUMENTOS */}
                                        {etapa === 1 && (
                                            <>
                                                {/* CPF / RG */}
                                                <FormBox>
                                                    <FormControl
                                                        fullWidth
                                                        error={Boolean(touched.cpf && errors.cpf)}
                                                        sx={{ ...theme.typography.customInput }}
                                                    >
                                                        <InputLabel htmlFor="outlined-adornment-cpf-register">CPF</InputLabel>
                                                        <InputMask
                                                            mask="999.999.999-99"
                                                            value={values.cpf}
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                        >
                                                            {(inputProps) => (
                                                                <OutlinedInput
                                                                    id="outlined-adornment-cpf-register"
                                                                    type="text"
                                                                    name="cpf"
                                                                    inputProps={inputProps}
                                                                />
                                                            )}
                                                        </InputMask>
                                                        {touched.cpf && errors.cpf && (
                                                            <FormHelperText error id="standard-weight-helper-text--register">
                                                                {errors.cpf}
                                                            </FormHelperText>
                                                        )}
                                                    </FormControl>

                                                    <FormControl
                                                        fullWidth
                                                        error={Boolean(touched.rg && errors.rg)}
                                                        sx={{ ...theme.typography.customInput }}
                                                    >
                                                        <InputLabel htmlFor="outlined-adornment-rg-register">RG</InputLabel>
                                                        <InputMask
                                                            mask="99.999.999-9"
                                                            value={values.rg}
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                        >
                                                            {(inputProps) => (
                                                                <OutlinedInput
                                                                    id="outlined-adornment-rg-register"
                                                                    type="text"
                                                                    name="rg"
                                                                    inputProps={inputProps}
                                                                />
                                                            )}
                                                        </InputMask>
                                                        {touched.rg && errors.rg && (
                                                            <FormHelperText error id="standard-weight-helper-text--register">
                                                                {errors.rg}
                                                            </FormHelperText>
                                                        )}
                                                    </FormControl>
                                                </FormBox>

                                                {/* CARTAO SUS / TELEFONE */}
                                                <FormBox>
                                                    <FormControl
                                                        fullWidth
                                                        error={Boolean(touched.cartao_sus && errors.cartao_sus)}
                                                        sx={{ ...theme.typography.customInput }}
                                                    >
                                                        <InputLabel htmlFor="outlined-adornment-cartao_sus-register">Cartão SUS</InputLabel>
                                                        <InputMask
                                                            mask="999-9999-9999-9999"
                                                            value={values.cartao_sus}
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                        >
                                                            {(inputProps) => (
                                                                <OutlinedInput
                                                                    id="outlined-adornment-cartao_sus-register"
                                                                    type="text"
                                                                    name="cartao_sus"
                                                                    inputProps={inputProps}
                                                                />
                                                            )}
                                                        </InputMask>
                                                        {touched.cartao_sus && errors.cartao_sus && (
                                                            <FormHelperText error id="standard-weight-helper-text--register">
                                                                {errors.cartao_sus}
                                                            </FormHelperText>
                                                        )}
                                                    </FormControl>
                                                    <FormControl
                                                        fullWidth
                                                        error={Boolean(touched.numero_carterinha && errors.numero_carterinha)}
                                                        sx={{ ...theme.typography.customInput }}
                                                    >
                                                        <InputLabel htmlFor="outlined-adornment-numero_carterinha-register">
                                                            Número Carterinha
                                                        </InputLabel>
                                                        <OutlinedInput
                                                            id="outlined-adornment-numero_carterinha-register"
                                                            type="text"
                                                            value={values.numero_carterinha}
                                                            name="numero_carterinha"
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                            inputProps={{}}
                                                        />
                                                        {touched.numero_carterinha && errors.numero_carterinha && (
                                                            <FormHelperText error id="standard-weight-helper-text--register">
                                                                {errors.numero_carterinha}
                                                            </FormHelperText>
                                                        )}
                                                    </FormControl>
                                                </FormBox>
                                            </>
                                        )}
                                        {/* ENDEREÇO*/}
                                        {etapa === 2 && (
                                            <>
                                                {/* CEP / IBGE */}
                                                <FormBox>
                                                    <FormControl
                                                        fullWidth
                                                        error={Boolean(touched.cep && errors.cep)}
                                                        sx={{ ...theme.typography.customInput }}
                                                    >
                                                        <InputLabel htmlFor="outlined-adornment-cep-register">CEP</InputLabel>
                                                        <InputMask
                                                            mask="99999-999"
                                                            value={values.cep}
                                                            onBlur={handleBlur}
                                                            onChange={(e) => {
                                                                handleCepChange(e, setFieldValue);
                                                                handleChange(e);
                                                            }}
                                                        >
                                                            {(inputProps) => (
                                                                <OutlinedInput
                                                                    id="outlined-adornment-cep-register"
                                                                    type="text"
                                                                    name="cep"
                                                                    inputProps={inputProps}
                                                                />
                                                            )}
                                                        </InputMask>
                                                        {touched.cep && errors.cep && (
                                                            <FormHelperText error id="standard-weight-helper-text--register">
                                                                {errors.cep}
                                                            </FormHelperText>
                                                        )}
                                                    </FormControl>

                                                    <FormControl
                                                        fullWidth
                                                        error={Boolean(touched.ibge && errors.ibge)}
                                                        sx={{ ...theme.typography.customInput }}
                                                    >
                                                        <InputLabel htmlFor="outlined-adornment-ibge-register">IBGE</InputLabel>
                                                        <OutlinedInput
                                                            id="outlined-adornment-ibge-register"
                                                            type="text"
                                                            value={values.ibge}
                                                            name="ibge"
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                            inputProps={{}}
                                                        />
                                                        {touched.ibge && errors.ibge && (
                                                            <FormHelperText error id="standard-weight-helper-text--register">
                                                                {errors.ibge}
                                                            </FormHelperText>
                                                        )}
                                                    </FormControl>
                                                </FormBox>

                                                {/* LOGRADOURO / ENDEREÇO */}
                                                <FormBox>
                                                    <FormControl
                                                        fullWidth
                                                        error={Boolean(touched.tipo_logradouro && errors.tipo_logradouro)}
                                                        sx={{ ...theme.typography.customInput }}
                                                    >
                                                        <InputLabel htmlFor="outlined-adornment-tipo_logradouro-register">
                                                            Tipo Logradouro
                                                        </InputLabel>
                                                        <OutlinedInput
                                                            id="outlined-adornment-tipo_logradouro-register"
                                                            type="text"
                                                            value={values.tipo_logradouro}
                                                            name="tipo_logradouro"
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                            inputProps={{}}
                                                        />
                                                        {touched.tipo_logradouro && errors.tipo_logradouro && (
                                                            <FormHelperText error id="standard-weight-helper-text--register">
                                                                {errors.tipo_logradouro}
                                                            </FormHelperText>
                                                        )}
                                                    </FormControl>
                                                    <FormControl
                                                        fullWidth
                                                        error={Boolean(touched.endereco && errors.endereco)}
                                                        sx={{ ...theme.typography.customInput }}
                                                    >
                                                        <InputLabel htmlFor="outlined-adornment-endereco-register">Endereço</InputLabel>
                                                        <OutlinedInput
                                                            id="outlined-adornment-endereco-register"
                                                            type="text"
                                                            value={values.endereco}
                                                            name="endereco"
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                            inputProps={{}}
                                                        />
                                                        {touched.endereco && errors.endereco && (
                                                            <FormHelperText error id="standard-weight-helper-text--register">
                                                                {errors.endereco}
                                                            </FormHelperText>
                                                        )}
                                                    </FormControl>
                                                </FormBox>

                                                {/* NUMERO / BAIRRO */}
                                                <FormBox>
                                                    <FormControl
                                                        fullWidth
                                                        error={Boolean(touched.numero && errors.numero)}
                                                        sx={{ ...theme.typography.customInput }}
                                                    >
                                                        <InputLabel htmlFor="outlined-adornment-numero-register">Número</InputLabel>
                                                        <OutlinedInput
                                                            id="outlined-adornment-numero-register"
                                                            type="number"
                                                            value={values.numero}
                                                            name="numero"
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                            inputProps={{}}
                                                        />
                                                        {touched.numero && errors.numero && (
                                                            <FormHelperText error id="standard-weight-helper-text--register">
                                                                {errors.numero}
                                                            </FormHelperText>
                                                        )}
                                                    </FormControl>

                                                    <FormControl
                                                        fullWidth
                                                        error={Boolean(touched.bairro && errors.bairro)}
                                                        sx={{ ...theme.typography.customInput }}
                                                    >
                                                        <InputLabel htmlFor="outlined-adornment-bairro-register">Bairro</InputLabel>
                                                        <OutlinedInput
                                                            id="outlined-adornment-bairro-register"
                                                            type="text"
                                                            value={values.bairro}
                                                            name="bairro"
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                            inputProps={{}}
                                                        />
                                                        {touched.bairro && errors.bairro && (
                                                            <FormHelperText error id="standard-weight-helper-text--register">
                                                                {errors.bairro}
                                                            </FormHelperText>
                                                        )}
                                                    </FormControl>
                                                </FormBox>

                                                {/* CIDADE / ESTADO */}
                                                <FormBox>
                                                    <FormControl
                                                        fullWidth
                                                        error={Boolean(touched.cidade && errors.cidade)}
                                                        sx={{ ...theme.typography.customInput }}
                                                    >
                                                        <InputLabel htmlFor="outlined-adornment-cidade-register">Cidade</InputLabel>
                                                        <OutlinedInput
                                                            id="outlined-adornment-cidade-register"
                                                            type="text"
                                                            value={values.cidade}
                                                            name="cidade"
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                            inputProps={{}}
                                                        />
                                                        {touched.cidade && errors.cidade && (
                                                            <FormHelperText error id="standard-weight-helper-text--register">
                                                                {errors.cidade}
                                                            </FormHelperText>
                                                        )}
                                                    </FormControl>

                                                    <FormControl
                                                        fullWidth
                                                        error={Boolean(touched.estado && errors.estado)}
                                                        sx={{ ...theme.typography.customInput }}
                                                    >
                                                        <InputLabel htmlFor="outlined-adornment-estado-register">Estado</InputLabel>
                                                        <OutlinedInput
                                                            id="outlined-adornment-estado-register"
                                                            type="text"
                                                            value={values.estado}
                                                            name="estado"
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                            inputProps={{}}
                                                        />
                                                        {touched.estado && errors.estado && (
                                                            <FormHelperText error id="standard-weight-helper-text--register">
                                                                {errors.estado}
                                                            </FormHelperText>
                                                        )}
                                                    </FormControl>
                                                </FormBox>
                                            </>
                                        )}
                                        {/* ATENDIMENTO*/}
                                        {etapa === 3 && (
                                            <>
                                                {/* CONVENIO*/}
                                                <FormBox sx={{ width: '100%', gap: '5px' }}>
                                                    <FormControl
                                                        fullWidth
                                                        error={Boolean(touched.convenio && errors.convenio)}
                                                        sx={{ ...theme.typography.customInput }}
                                                    >
                                                        <InputLabel htmlFor="outlined-adornment-convenio-register">Convênio</InputLabel>
                                                        <Select
                                                            style={{ height: '60px', padding: '20px 0px 0px 0px' }}
                                                            id="outlined-adornment-convenio-register"
                                                            type="text"
                                                            value={values.convenio}
                                                            name="convenio"
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                            inputProps={{}}
                                                        >
                                                            {allConvenio.map((convenio) => (
                                                                <MenuItem key={convenio.id} value={convenio.id}>
                                                                    {convenio.descricao}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                        {touched.convenio && errors.convenio && (
                                                            <FormHelperText error id="standard-weight-helper-text--register">
                                                                {errors.convenio}
                                                            </FormHelperText>
                                                        )}
                                                    </FormControl>
                                                    <FormControl
                                                        fullWidth
                                                        error={Boolean(touched.obito && errors.obito)}
                                                        sx={{ ...theme.typography.customInput }}
                                                    >
                                                        <InputLabel htmlFor="outlined-adornment-obito-register">Óbito</InputLabel>
                                                        <Select
                                                            style={{ height: '60px', padding: '20px 0px 0px 0px' }}
                                                            id="outlined-adornment-obito-register"
                                                            type="text"
                                                            value={values.obito}
                                                            name="obito"
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                            inputProps={{}}
                                                        >
                                                            <MenuItem value={'1'}>Sim</MenuItem>
                                                            <MenuItem value={'2'}>Não</MenuItem>
                                                        </Select>
                                                        {touched.obito && errors.obito && (
                                                            <FormHelperText error id="standard-weight-helper-text--register">
                                                                {errors.obito}
                                                            </FormHelperText>
                                                        )}
                                                    </FormControl>
                                                    <Box sx={{ display: 'flex', width: '35%', marginTop: '5px', gap: '5px' }}>
                                                        <Switch
                                                            checked={switchCheck}
                                                            onChange={() => setSwitchCheck(!switchCheck)}
                                                            inputProps={{ 'aria-label': 'controlled' }}
                                                        />
                                                        <Typography sx={{ marginTop: '8px' }}>Autismo</Typography>
                                                    </Box>
                                                    <Box sx={{ display: 'flex', width: '38%', marginTop: '5px', gap: '5px' }}>
                                                        <Switch
                                                            checked={switchCheckAtivo}
                                                            onChange={() => {
                                                                setSwitchCheckAtivo(!switchCheckAtivo);
                                                            }}
                                                            inputProps={{ 'aria-label': 'controlled' }}
                                                        />
                                                        <Typography sx={{ marginTop: '8px', width: '100px' }}>Cadastro Ativo</Typography>
                                                    </Box>
                                                </FormBox>
                                            </>
                                        )}

                                        {errors.submit && (
                                            <Box sx={{ mt: 3 }}>
                                                <FormHelperText error>{errors.submit}</FormHelperText>
                                            </Box>
                                        )}

                                        {/* BOTÕES */}
                                        <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between' }}>
                                            <Button color="error" onClick={handleBackTable}>
                                                Voltar
                                            </Button>
                                            <Box>
                                                {etapa > 0 && (
                                                    <Button type="button" onClick={handleEtapaAnterior}>
                                                        Voltar
                                                    </Button>
                                                )}

                                                {etapa <= maxEtapa ? (
                                                    <Button type="button" onClick={() => handleProximaEtapa(values)}>
                                                        Próximo
                                                    </Button>
                                                ) : (
                                                    <Button type="submit">Enviar</Button>
                                                )}
                                            </Box>
                                        </Box>
                                    </form>
                                )}
                            </Formik>
                        </Box>
                    </Grid>
                </MainCard>
            )}
        </>
    );
};

export default EditarProntuario;
