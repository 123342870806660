import { CircularProgress, Grid } from '@mui/material';
import MainCard from 'ui-component/cards/MainCard';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { gridSpacing } from 'store/constant';
import EditUserFormPrincipal from '../../views/users/EditUserForms/EditUserFormPrincipal';
import { useApi } from 'Service/axios';
import { useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { toast } from 'react-toastify';
import { Box } from '@mui/system';
import { formatarCEL } from 'utils/formatCEL';
import ActionButton from 'components/RegisterForms/ActionButton';

function EditUser() {
    // ========== INSTÂNCIAS ==========
    const api = useApi();
    // ========== STATES ==========
    const [data, setData] = useState([]);
    const [openEdit, setOpenEdit] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [loading, setLoading] = useState(true);
    const [idSelect, setId] = useState();
    const columns = [
        { field: 'id', headerName: 'ID', flex: 0.5 },
        { field: 'nome', headerName: 'NOME', flex: 1 },
        { field: 'email', headerName: 'EMAIl', flex: 1 },
        {
            field: 'telefone',
            headerName: 'CELULAR',
            flex: 1,
            valueGetter: (params) => (params?.row?.telefone ? formatarCEL(params?.row?.telefone) : '')
        },
        { field: 'setor', headerName: 'SETOR', flex: 1, valueGetter: (params) => params.row.setor.toUpperCase() },
        { field: 'role', headerName: 'ROLE', flex: 1, valueGetter: (params) => params.row.role.toUpperCase() },
        {
            field: 'acao',
            headerName: 'AÇÕES',
            flex: 1,
            sortable: false,
            renderCell: (params) => (
                <ActionButton row={params.row} get={get} setOpenEdit={setOpenEdit} setOpenDelete={setOpenDelete} setId={setId} />
            )
        }
    ];

    // ========== FUNÇÕES ==========

    const get = async () => {
        try {
            const response = await api.getAllUsers();
            setData(response.data.usuarios);
            setLoading(false);
        } catch (error) {
            toast.error(`Ocorreu um erro: ${error.message}`);
        }
    };

    const handleCloseEdit = () => {
        setOpenEdit(false);
        get();
    };

    const handleCloseDelete = async () => {
        setOpenDelete(false);
    };

    const handleCancelDeleteDialog = async () => {
        await handleCloseDelete();
    };

    const handleConfirmDeleteDialog = async (id) => {
        try {
            await api.deleteUser(id);
            setOpenDelete(false);
            get();
            toast.success(`Deletado com sucesso!`);
        } catch (error) {
            toast.error(`ERRO: ${error.response.data.mensagem}`);
            setOpenDelete(false);
        }
    };

    useEffect(() => {
        get();
    }, []);

    return (
        <>
            <Dialog open={openEdit} onClose={handleCloseEdit}>
                <DialogContent>
                    <EditUserFormPrincipal id={idSelect} setOpenEdit={setOpenEdit} />
                </DialogContent>
            </Dialog>
            <Dialog
                open={openDelete}
                onClose={handleCloseDelete}
                aria-labelledby="alert-dialog-title-delete"
                aria-describedby="alert-dialog-description-delete"
            >
                <DialogTitle id="alert-dialog-title-delete">{'Deletar Registro'}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description-delete">Tem certeza que deseja excluir o registo ?</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleConfirmDeleteDialog(idSelect)}>Sim</Button>
                    <Button onClick={handleCancelDeleteDialog}>Cancelar</Button>
                </DialogActions>
            </Dialog>

            <MainCard>
                <Grid container spacing={gridSpacing} justifyContent="center">
                    <Grid item xs={12} sm={12}>
                        {loading ? (
                            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                <CircularProgress />
                            </Box>
                        ) : (
                            <Box sx={{ height: '540px' }}>
                                <DataGrid
                                    rows={data}
                                    columns={columns}
                                    hideFooter={false}
                                    onSelectionModelChange={() => handleSelectionModelChange(params.id)}
                                />
                            </Box>
                        )}
                    </Grid>
                </Grid>
            </MainCard>
        </>
    );
}

export default EditUser;
