import React, { useState } from 'react';
import {
    Autocomplete,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Drawer,
    FormControl,
    MenuItem,
    TextField,
    Tooltip,
    Typography
} from '@mui/material';
import { Box } from '@mui/system';
import CloseIcon from '@mui/icons-material/Close';
const { v4: uuidv4 } = require('uuid');

const TesteModal = ({
    open,
    handleClose,
    setItens,
    campos,
    adicionarCampoItem,
    atualizarCampoItem,
    listAllItens,
    listAllAlmoxarifado,
    listAllLocalizacao,
    filtredLocations,
    setFiltredLocations,
    handleGetLotes,
    lotesItem,
    setPsicotropico
}) => {
    /////////////////////////////// STATES /////////////////////////
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedItem, setSelectedItem] = useState('' || null);
    const item = campos?.itens[campos?.itens.length - 1];
    /////////////////////////////// FUNÇÕES /////////////////////////
    const adicionarItemNaTabela = (novoItem) => {
        // Caso tenha selecionado um KIT
        // Explodimos os itens do kit no state de itens
        // Caso seja um item normal apenas adicionamos
        if (novoItem.kit_itens && novoItem.kit_itens.length > 0) {
            const itensDoKit = novoItem.kit_itens.map((itemDoKit) => {
                const copiaDoItemDoKit = { ...itemDoKit };
                delete copiaDoItemDoKit.id;
                const novoItem = {
                    ...copiaDoItemDoKit,
                    uuid: uuidv4(),
                    quantidade: itemDoKit.quantidade * copiaDoItemDoKit.quantidade
                };
                return novoItem;
            });
            setItens((prevItens) => [...prevItens, ...itensDoKit]);
        } else {
            delete novoItem.kit_itens;
            const novoItemSemKit = {
                ...novoItem,
                uuid: uuidv4()
            };
            setItens((prevItens) => [...prevItens, novoItemSemKit]);
        }
    };

    const handleChangeItem = async (newValue) => {
        // Mostrar apenas as localizações disponíveis pro item
        setFiltredLocations(
            listAllLocalizacao.filter(
                (localizacao) =>
                    localizacao?.almoxarifado_id === newValue?.almoxarifado?.id ||
                    (newValue?.kit_itens && newValue.kit_itens.length > 0 && newValue.kit_itens[0]?.almoxarifado_id)
            )
        );

        atualizarCampoItem(item.uuid, 'item_id', newValue?.id);
        atualizarCampoItem(item.uuid, 'descricao', newValue?.descricao);
        atualizarCampoItem(item.uuid, 'unidade', newValue?.unidade?.descricao);
        atualizarCampoItem(item.uuid, 'almoxarifado_id', newValue?.almoxarifado?.id);
        atualizarCampoItem(item.uuid, 'localizacao_id', newValue?.localizacao?.id);
        atualizarCampoItem(item.uuid, 'psicotropico', newValue?.psicotropico);

        // Controle de Kits e lotes
        if (newValue?.kit_itens) {
            atualizarCampoItem(item.uuid, 'kit_itens', [...newValue?.kit_itens]);
        } else {
            const lotes = await handleGetLotes(newValue);
            atualizarCampoItem(item.uuid, 'disponivel', lotes?.data[0]?.quantidade_disponivel || 0);
        }

        // Controle de psicotrópico
        if (newValue.psicotropico || (newValue?.kit_itens && newValue?.kit_itens.find((item) => item?.iten?.psicotropico === true))) {
            setPsicotropico(true);
        }
    };

    return (
        <Box>
            <Dialog open={openDialog}>
                <DialogTitle>Atenção</DialogTitle>
                <DialogContent>
                    <Typography>Ao fechar todo o cadastro desse item sera cancelado, continuar?</Typography>
                </DialogContent>
                <DialogActions>
                    <Box sx={{ display: 'flex' }}>
                        <Button color="error" onClick={() => setOpenDialog(false)}>
                            Cancelar
                        </Button>
                        <Button
                            onClick={() => {
                                handleClose(), setOpenDialog(false);
                            }}
                        >
                            Confirmar
                        </Button>
                    </Box>
                </DialogActions>
            </Dialog>
            <Drawer anchor="right" open={open}>
                {open && (
                    <Box sx={{ width: '650px', padding: '.75em', height: '100%' }}>
                        <Box>
                            <Box sx={{ width: '100%', display: 'flex', gap: '1em' }}>
                                <FormControl fullWidth>
                                    <Autocomplete
                                        options={listAllItens}
                                        getOptionLabel={(item) => `${item.id} - ${item.descricao} - ${!item.apelido ? '' : item.apelido}`}
                                        value={selectedItem}
                                        onChange={(e, newValue) => {
                                            handleChangeItem(newValue), setSelectedItem(newValue);
                                        }}
                                        renderInput={(params) => <TextField {...params} label="Item" />}
                                    />
                                </FormControl>
                                <FormControl fullWidth>
                                    <TextField label="UN" value={item?.unidade} disabled InputLabelProps={{ shrink: true }} />
                                </FormControl>
                            </Box>
                            <Box sx={{ display: 'flex', gap: '1em', marginTop: '1em', marginBottom: '1em' }}>
                                <FormControl fullWidth>
                                    <TextField
                                        label="Quantidade"
                                        type="number"
                                        defaultValue={1}
                                        value={item?.quantidade}
                                        onChange={(e) => atualizarCampoItem(item?.uuid, 'quantidade', parseFloat(e.target.value))}
                                    />
                                </FormControl>
                                {item?.disponivel > 0 && !item?.lote && (
                                    <FormControl fullWidth>
                                        <TextField
                                            label="Disponível"
                                            type="number"
                                            value={item?.disponivel}
                                            disabled
                                            InputLabelProps={{ shrink: true }}
                                        />
                                    </FormControl>
                                )}
                            </Box>
                            <Box sx={{ display: 'flex', gap: '1em', marginTop: '1em', marginBottom: '1em' }}>
                                <FormControl fullWidth>
                                    <TextField
                                        label="Almoxarifado"
                                        select
                                        value={item.almoxarifado_id}
                                        disabled={selectedItem?.kit_itens}
                                        onChange={(e) => atualizarCampoItem(item?.uuid, 'almoxarifado_id', e.target.value)}
                                    >
                                        {listAllAlmoxarifado.map((almoxarifado) => (
                                            <MenuItem key={almoxarifado.id} value={almoxarifado.id}>
                                                {almoxarifado.descricao}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </FormControl>
                                <FormControl fullWidth>
                                    <TextField
                                        label="Localização"
                                        disabled={selectedItem?.kit_itens}
                                        select
                                        value={item.localizacao_id}
                                        onChange={(e) => atualizarCampoItem(item?.uuid, 'localizacao_id', e.target.value)}
                                    >
                                        {filtredLocations.map((localizacao) => (
                                            <MenuItem key={localizacao.id} value={localizacao.id}>
                                                {localizacao.descricao}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </FormControl>
                            </Box>
                            <Box sx={{ display: 'flex', gap: '1em', marginTop: '1em', marginBottom: '1em' }}>
                                <FormControl fullWidth>
                                    <TextField
                                        label="Observação"
                                        disabled={selectedItem?.kit_itens}
                                        value={item.observacao}
                                        onChange={(e) => atualizarCampoItem(item?.uuid, 'observacao', e.target.value)}
                                    />
                                </FormControl>
                            </Box>
                            {item?.psicotropico && (
                                <Box sx={{ display: 'flex', gap: '1em' }}>
                                    <FormControl fullWidth>
                                        <TextField
                                            label="Lote"
                                            select
                                            value={item.lote}
                                            onChange={(e) => atualizarCampoItem(item?.uuid, 'lote', e.target.value)}
                                        >
                                            {lotesItem.length > 0 ? (
                                                lotesItem
                                                    .filter((lote) => lote.quantidade_disponivel > 0)
                                                    .map((lote) => (
                                                        <MenuItem key={lote.lote} value={lote.lote}>
                                                            {`${lote.lote} = ${lote.quantidade_disponivel}`}
                                                        </MenuItem>
                                                    ))
                                            ) : (
                                                <MenuItem>Sem lotes disponíveis para esta localização e almoxarifado</MenuItem>
                                            )}
                                        </TextField>
                                    </FormControl>
                                </Box>
                            )}

                            <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '2em', gap: '1em' }}>
                                <Tooltip title="Fechar">
                                    <Button
                                        variant="contained"
                                        color="error"
                                        onClick={() => {
                                            handleClose(), setOpenDialog(false);
                                        }}
                                    >
                                        <CloseIcon />
                                    </Button>
                                </Tooltip>
                                <Button
                                    variant="contained"
                                    onClick={() => {
                                        adicionarItemNaTabela(item);
                                        setSelectedItem(null);
                                        adicionarCampoItem();
                                    }}
                                >
                                    Salvar
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                )}
            </Drawer>
        </Box>
    );
};

export default TesteModal;
