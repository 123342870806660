import { styled } from '@mui/material';
import { Box, Typography, Button } from '@mui/material';

export const FormBox = styled(Box)({
    width: '80%',
    padding: '1em',
    marginLeft: '1em',
    display: 'flex',
    justifySelf: 'center',
    justifyContent: 'center'
});
