import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import React from 'react';
import { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import NewItemForm from './FormNewItem/NewItemForm';
import { toast } from 'react-toastify';
import { useApi } from 'Service/axios';
import { useEffect } from 'react';

const NewItemModel = ({ open, handleClose, handleAddNewItem, handleEditItem, rows, isEdit, itemEdit }) => {
    const api = useApi();
    const [newItem, setNewItem] = useState({
        id: null,
        uuid: null,
        item_id: null,
        item: null,
        qtd: 1,
        unidade: null,
        unidade_id: null,
        observacao: null,
        psicotropico: null,
        almoxarifado_id: null,
        localizacao_id: null,
        lote: null,
        kit_itens: null,
        item_novo: true
    });
    const lastRowsItem = rows[rows.length - 1];
    const [lotesItem, setLotesItem] = useState([]);
    const [allItens, setAllItens] = useState([]);
    const [allAlmoxarifados, setAllAlmoxarifados] = useState([]);
    const [allLocalizacao, setAllLocalizacao] = useState([]);
    const [filtredLocations, setFiltredLocations] = useState([]);

    // FUNCTIONS
    function resetNewItem() {
        setNewItem({
            id: null,
            uuid: null,
            item_id: null,
            item: null,
            qtd: 1,
            unidade: null,
            unidade_id: null,
            observacao: null,
            psicotropico: null,
            almoxarifado_id: null,
            localizacao_id: null,
            lote: null,
            kit_itens: null,
            item_novo: true
        });
    }

    async function handleGetLotes(data) {
        try {
            const lotes = await api.getLoteByItemId(data);
            setLotesItem(lotes.data);
            return lotes;
        } catch (error) {
            toast.error(error?.response?.data?.message ?? error?.message);
        }
    }

    async function getAndPopulateLote(item) {
        try {
            setFiltredLocations(allLocalizacao.filter((localizacao) => localizacao.almoxarifado_id === itemEdit.almoxarifado_id));
            const payload = { id: item.item_id, almoxarifado_id: item.almoxarifado_id, localizacao_id: item.localizacao_id };
            const { data } = await handleGetLotes(payload);
            return data[0];
        } catch (error) {
            //toast.error(error?.response?.data?.message ?? error?.message);
        }
    }

    async function handleUpdateItem(campo, valor) {
        setNewItem((prevItem) => ({ ...prevItem, [campo]: valor }));

        if (campo === 'almoxarifado_id') {
            setFiltredLocations(allLocalizacao.filter((localizacao) => localizacao.almoxarifado_id === valor));
        } else if (campo === 'localizacao_id') {
            const lotes = await getAndPopulateLote(newItem);
            setNewItem((prevItem) => ({ ...prevItem, disponivel: lotes?.quantidade_disponivel || 0, lote: null }));
        }
    }

    async function getAllDataDependencies() {
        try {
            const [itens, almoxarifado, localizacao] = await Promise.all([
                api.getAllItems(),
                api.getAlmoxarifados(),
                api.getAllLocalizacoes()
            ]);

            setAllItens(itens.data);
            setAllAlmoxarifados(almoxarifado.data);
            setAllLocalizacao(localizacao.data);
        } catch (error) {
            toast.error(error?.response?.data?.message ?? error?.message);
        }
    }

    async function prepareItemForEdit() {
        const lotes = await getAndPopulateLote(itemEdit);
        setNewItem({ ...itemEdit, disponivel: lotes?.quantidade_disponivel || 0, lote: null });
    }

    function addItemController(isEdit, newItem) {
        // Caso seja Edit atualizamos os dados do item já existente
        // Caso seja item novo adicionamos na lista
        if (isEdit) {
            handleEditItem(newItem);
            resetNewItem();
            handleClose();
        } else {
            handleAddNewItem({ ...newItem, uuid: uuidv4(), id: lastRowsItem.id + 1 });
            resetNewItem();
            handleClose();
        }
    }

    useEffect(() => {
        getAllDataDependencies();
    }, []);

    useEffect(() => {
        if (isEdit) {
            prepareItemForEdit();
        }
    }, [isEdit]);

    return (
        <Dialog open={open} maxWidth={'lg'} fullWidth={true}>
            <DialogTitle>ADICIONANDO NOVO MEDICAMENTO NA CONDUTA</DialogTitle>
            <DialogContent>
                <NewItemForm
                    atualizarCampoItem={handleUpdateItem}
                    listAllItens={allItens}
                    listAllAlmoxarifado={allAlmoxarifados}
                    listAllLocalizacao={allLocalizacao}
                    item={newItem}
                    filtredLocations={filtredLocations}
                    setFiltredLocations={setFiltredLocations}
                    lotesItem={lotesItem}
                    handleGetLotes={handleGetLotes}
                    isEdit={isEdit}
                />
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    color="error"
                    onClick={() => {
                        resetNewItem();
                        handleClose();
                    }}
                >
                    Cancelar
                </Button>
                <Button
                    variant="contained"
                    disabled={newItem?.disponivel == 0 && newItem?.lote == null && !newItem.item.includes('KIT')}
                    onClick={() => {
                        addItemController(isEdit, newItem);
                    }}
                >
                    {newItem?.disponivel == 0 && newItem?.lote == null && !newItem?.kit_itens
                        ? 'Sem quantidade disponível nessa localização'
                        : 'Confirmar'}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default NewItemModel;
