import { Box, Button, Grid, IconButton, Tooltip } from '@mui/material';
import { gridSpacing } from 'store/constant';
import MainCard from 'ui-component/cards/MainCard';
import { AddExamModal } from './components/AddExamModal';
import { useApi } from 'Service/axios';
import { useEffect } from 'react';
import { useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { toast } from 'react-toastify';
import { AddTipoProcedimento } from './components/AddTipoProcedimentoModal';

export function RegisterProcedimentos() {
    const api = useApi();
    const [rows, setRows] = useState([]);
    const [currentRow, setCurrentRow] = useState(null);
    const [open, setOpen] = useState(false);
    const [openTipoModal, setOpenTipoModal] = useState(false);
    const columns = [
        { field: 'id', headerName: 'ID', flex: 0.5 },
        { field: 'descricao', headerName: 'Descrição', flex: 1 },
        { field: 'codigo', headerName: 'Código', flex: 1 },
        { field: 'tipo', headerName: 'Tipo', flex: 1, valueGetter: (params) => params.row.tipo_procedimento.descricao },
        {
            field: 'actions',
            headerName: 'Ações',
            flex: 1,
            renderCell: (params) => (
                <Box>
                    <Tooltip title="Editar">
                        <IconButton
                            onClick={() => {
                                setOpen(true);
                                setCurrentRow(params.row);
                            }}
                        >
                            <EditIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Deletar">
                        <IconButton onClick={() => handleDelete(params.row.id)}>
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                </Box>
            )
        }
    ];

    async function allProcedimentos() {
        try {
            const { data } = await api.getAllProcedimentos();
            setRows(data);
        } catch (error) {
            toast.error(error.message);
        }
    }

    async function handleDelete(id) {
        try {
            await api.deleteProcedimento(id);
            allProcedimentos();
            toast.success('Procedimento deletado com sucesso!');
        } catch (error) {
            toast.error(error.message);
        }
    }

    useEffect(() => {
        allProcedimentos();
    }, []);

    return (
        <MainCard title="CADASTRO DE PROCEDIMENTO">
            <Grid spacing={gridSpacing}>
                <Box sx={{ width: '100%', display: 'flex', gap: '1rem' }}>
                    <Button variant="contained" onClick={() => setOpen(true)} sx={{ marginBottom: '16px' }}>
                        Adicionar Procedimento
                    </Button>
                    <Button variant="contained" onClick={() => setOpenTipoModal(true)} sx={{ marginBottom: '16px' }}>
                        Tipo Procedimento
                    </Button>
                </Box>
                <Box>
                    <AddExamModal
                        get={allProcedimentos}
                        currentRow={currentRow}
                        open={open}
                        setOpen={setOpen}
                        setCurrentRow={setCurrentRow}
                    />
                    <AddTipoProcedimento get={allProcedimentos} open={openTipoModal} setOpen={setOpenTipoModal} />
                    {rows.length > 0 && <DataGrid rows={rows} columns={columns} hideFooter />}
                </Box>
            </Grid>
        </MainCard>
    );
}
