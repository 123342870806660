import { Typography, styled } from '@mui/material';
import { Box } from '@mui/material';

export const MainContainer = styled(Box)({
    width: '100%',
    height: '88vh',
    padding: '1em',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
});

export const Title = styled(Typography)({
    width: '100%',
    textAlign: 'center',
    borderBottom: '1px solid #000'
});

export const TextBox = styled(Box)({
    marginTop: 'auto',
    marginBottom: 'auto',
    textAlign: 'center'
});

export const FooterBox = styled(Box)({
    width: '100%',
    display: 'flex',
    padding: '.5em',
    justifyContent: 'center',
    alignItems: 'center',
    borderTop: '1px solid #000'
});
