import MainCard from 'ui-component/cards/MainCard';
import { Button, Grid, TextField, Typography, Box, InputLabel, Select, MenuItem, ButtonGroup, FormLabel } from '@mui/material';
import { gridSpacing } from 'store/constant';
import { Outlet } from 'react-router-dom';
import { MainBox } from './style';
import Logo2 from 'assets/images/Logos/LOGO_DO_LAC.jpg';

const LaboratorioLayout = () => {
    const usuario = localStorage.getItem('nome');

    const dataHoraAtual = new Date();

    const dia = dataHoraAtual.getDate();
    const mes = dataHoraAtual.getMonth() + 1;
    const ano = dataHoraAtual.getFullYear();

    const horas = dataHoraAtual.getHours();
    const minutos = dataHoraAtual.getMinutes();
    const segundos = dataHoraAtual.getSeconds();

    const dataFormatada = (dia < 10 ? '0' + dia : dia) + '/' + (mes < 10 ? '0' + mes : mes) + '/' + ano;
    const horaFormatada =
        (horas < 10 ? '0' + horas : horas) +
        ':' +
        (minutos < 10 ? '0' + minutos : minutos) +
        ':' +
        (segundos < 10 ? '0' + segundos : segundos);

    return (
        <MainCard>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <img src={Logo2} alt="Logo Laboratório" style={{ width: '135px', height: 'auto' }} />
                <h4>LABORATÓRIO DE ANÁLISES CLÍNICAS SANTA CASA DE MISERICÓRDIA DE JOANÓPOLIS</h4>
            </div>
            <Grid container spacing={gridSpacing}>
                <MainBox>
                    <Box
                        sx={{
                            marginRight: 'auto',
                            marginLeft: '1em',
                            marginTop: '.7em',
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%'
                        }}
                    ></Box>
                    <Outlet />
                </MainBox>
            </Grid>
        </MainCard>
    );
};

export default LaboratorioLayout;
