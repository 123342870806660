import React from 'react';
import {
    Autocomplete,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Drawer,
    FormControl,
    MenuItem,
    TextField,
    Tooltip,
    Typography
} from '@mui/material';
import { useState } from 'react';
import { Box } from '@mui/system';
import { useEffect } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { toast } from 'react-toastify';

const ActionButtonsCreateKits = ({
    open,
    handleClose,
    itens,
    setItens,
    adicionarCampoItem,
    atualizarCampoItem,
    removerCampoItem,
    indiceCampoItem,
    item,
    listAllItens,
    listAllAlmoxarifado,
    listAllLocalizacao
}) => {
    /////////////////////////////// INSTANCIAS /////////////////////////

    /////////////////////////////// STATES /////////////////////////
    const [openDial, setOpenDialog] = useState();
    const [indexItem, setIndexItem] = useState(null);
    /////////////////////////////// FUNÇÕES /////////////////////////
    const handleChangeItem = (newValue) => {
        if (newValue !== null) {
            const objeto = newValue;

            setIndexItem(objeto);
            atualizarCampoItem(0, indiceCampoItem, 'item_id', objeto.id);
            atualizarCampoItem(0, indiceCampoItem, 'descricao', objeto.descricao);
            atualizarCampoItem(0, indiceCampoItem, 'unidade', objeto.unidade.descricao);
            atualizarCampoItem(0, indiceCampoItem, 'kit_id', objeto.unidade.descricao);

            atualizarCampoItem(0, indiceCampoItem, 'unidade_id', objeto.unidade_id);
            atualizarCampoItem(0, indiceCampoItem, 'almoxarifado_id', objeto.almoxarifado.id);
            atualizarCampoItem(0, indiceCampoItem, 'localizacao_id', objeto.localizacao.id);
        }
    };
    useEffect(() => {
        atualizarCampoItem(0, indiceCampoItem, 'indiceCampoItem', indiceCampoItem);
    }, []);

    return (
        <Box>
            <Dialog open={openDial}>
                <DialogTitle>Atenção</DialogTitle>
                <DialogContent>
                    <Typography>Ao fechar todo o cadastro desse item sera cancelado, continuar?</Typography>
                </DialogContent>
                <DialogActions>
                    <Box sx={{ display: 'flex' }}>
                        <Button color="error" onClick={() => setOpenDialog(false)}>
                            Cancelar
                        </Button>
                        <Button onClick={() => removerCampoItem(0, indiceCampoItem)}>Confirmar</Button>
                    </Box>
                </DialogActions>
            </Dialog>
            <Drawer anchor="right" open={open}>
                <Box sx={{ width: '650px', padding: '.75em', height: '100%' }}>
                    <Box>
                        <Box sx={{ width: '100%', display: 'flex', gap: '1em' }}>
                            <FormControl fullWidth>
                                <Autocomplete
                                    options={listAllItens.filter((item) => !item.lote)}
                                    getOptionLabel={(item) => `${item.id} - ${item.descricao} - ${!item.apelido ? '' : item.apelido} `}
                                    value={listAllItens.find((el) => el.id === item.item_id) || null}
                                    onChange={(e, newValue) => handleChangeItem(newValue)}
                                    renderInput={(params) => <TextField {...params} label="Item" />}
                                />
                            </FormControl>
                        </Box>
                        <Box sx={{ display: 'flex', gap: '1em', marginTop: '1em', marginBottom: '1em' }}>
                            <FormControl fullWidth>
                                <TextField
                                    label="Quantidade"
                                    type="number"
                                    value={item.quantidade}
                                    onChange={(e) => {
                                        let resultado = parseFloat(e.target.value * item.valor_unitario);
                                        atualizarCampoItem(0, indiceCampoItem, 'quantidade', parseFloat(e.target.value)),
                                            atualizarCampoItem(0, indiceCampoItem, 'valor_total', resultado);
                                    }}
                                />
                            </FormControl>
                        </Box>

                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '2em', gap: '1em' }}>
                            <Tooltip title="Fechar">
                                <Button variant="contained" color="error" onClick={() => setOpenDialog(true)}>
                                    <CloseIcon />
                                </Button>
                            </Tooltip>
                            <Button
                                variant="contained"
                                onClick={(e) => {
                                    const ultimoItem = itens[itens.length - 1];
                                    const novoId = ultimoItem ? ultimoItem.id + 1 : 1;
                                    const novoItem = { ...item, id: novoId };
                                    setItens((prevItens) => [...prevItens, novoItem]);
                                    toast.success('Salvo com sucesso!');
                                }}
                            >
                                Salvar
                            </Button>
                            <Button
                                variant="contained"
                                onClick={(e) => {
                                    const ultimoItem = itens[itens.length - 1];
                                    const novoId = ultimoItem ? ultimoItem.id + 1 : 1;
                                    const novoItem = { ...item, id: novoId };
                                    setItens((prevItens) => [...prevItens, novoItem]);

                                    handleClose();
                                }}
                            >
                                Salvar e fechar
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Drawer>
        </Box>
    );
};

export default ActionButtonsCreateKits;
