import recepcao from './lobby';
import laboratorio from './laboratorio';
import estoque from './estoque';
import triagem from './triagem';
import chamadas from './chamadas';
import xray from './xray';
import procedimentos from './procedimentos';
import doctorsOffice from './doctorsOffice';
import enfermagem from './enfermagem';
// ==============================|| MENU ITEMS ||============================== //

const role = localStorage.getItem('role');

let menuItems;

if (role === 'root') {
    menuItems = {
        items: [recepcao, chamadas, triagem, laboratorio, estoque, xray, procedimentos, doctorsOffice, enfermagem]
    };
} else if (role === 'administrador') {
    menuItems = {
        items: [recepcao, chamadas, triagem, laboratorio, estoque]
    };
} else if (role === 'Laboratorio') {
    menuItems = {
        items: [laboratorio]
    };
} else if (role === 'Recepcao') {
    menuItems = {
        items: [recepcao]
    };
} else if (role === 'farmacia') {
    menuItems = {
        items: [estoque]
    };
} else if (role === 'Farm / Lab') {
    menuItems = {
        items: [estoque, laboratorio]
    };
} else if (role == 'Farm/Escuta/Lab') {
    menuItems = {
        items: [estoque, laboratorio, triagem]
    };
} else if (role === 'salaEscuta') {
    menuItems = {
        items: [triagem]
    };
} else if (role === 'chamadas') {
    menuItems = {
        items: [chamadas]
    };
}

export default menuItems;
