import { useState, useEffect } from 'react';
import { useParams } from 'react-router';
//API
import { useApi } from 'Service/axios';

import {
    Assinatura,
    Classificacao,
    ContentTriagem,
    Destino,
    ExamesPrescricao,
    HeaderBox,
    HeaderTriagem,
    PrincipaisDados,
    Rodape,
    Title
} from './style';
import { Typography } from '@mui/material';
import { Box } from '@mui/system';

import { formatDate } from 'utils/formatDate';

const Entrada = () => {
    const { id, numero } = useParams();
    const api = useApi();
    const [paciente, setPaciente] = useState(null);
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState('');
    const [idade, setIdade] = useState('');

    const getPaciente = async (id) => {
        const response = await api.getPacienteById(id);
        setPaciente(response.data);

        setTimeout(() => {
            setLoading(false);
        }, 1000);

        // ======================== DATA + IDADE ========================

        const formatDate = (inputDate) => {
            const dateParts = inputDate.split('-');
            const year = parseInt(dateParts[0]);
            const month = parseInt(dateParts[1]) - 1;
            const day = parseInt(dateParts[2]);
            return new Date(year, month, day);
        };

        const formatDateNascimento = (inputDate) => {
            const dateParts = inputDate.split('-');
            const year = parseInt(dateParts[0]);
            const month = parseInt(dateParts[1]) - 1;
            const day = parseInt(dateParts[2]);
            return day + '/' + month + '/' + year;
        };

        const dataNascimento = formatDate(response.data.data_nascimento);

        const dataAtual = new Date();

        const diffMilliseconds = dataAtual - dataNascimento;
        const diffDate = new Date(diffMilliseconds);

        const idadeAnos = diffDate.getUTCFullYear() - 1970;
        const idadeMeses = diffDate.getUTCMonth();
        const idadeDias = diffDate.getUTCDate() - 1;

        setIdade(`${idadeAnos} anos, ${idadeMeses} meses e ${idadeDias} dias`);
        setData(formatDateNascimento(response.data.data_nascimento));
    };

    if (loading === false && paciente.nome_completo) {
        window.print();
    }

    useEffect(() => {
        getPaciente(id);
    }, [id]);

    return (
        <>
            {paciente !== null && (
                <>
                    {/* MAIN HEADER */}
                    <HeaderBox>
                        <Box
                            sx={{
                                width: '25%',
                                display: 'flex',
                                flexDirection: 'column',
                                fontSize: '1em',
                                gap: '.2em'
                            }}
                        >
                            <Title>
                                CONVÊNIO: <b>{paciente.convenio.descricao}</b>
                            </Title>
                            <Title>
                                NOME: <b>{paciente.nome_completo}</b>
                            </Title>
                            <Title>
                                CÓDIGO: <b>{paciente.id}</b>
                            </Title>
                            <Title>
                                N° ATENDIMENTO: <b>{numero}</b>
                            </Title>
                        </Box>
                        <Box sx={{ width: '25%', display: 'flex', flexDirection: 'column', fontSize: '1em', gap: '.2em' }}>
                            <Title>
                                SEXO: <b>{paciente?.sexo === 3 ? 'OUTROS' : paciente?.sexo === 1 ? 'MASCULINO' : 'FEMININO'}</b>
                            </Title>
                            <Title>
                                ESTADO CIVIL: <b>{paciente.estado_civil}</b>
                            </Title>
                            <Title>
                                DATA NASC: <b>{data}</b>
                            </Title>

                            <Title>
                                Cor: <b>{paciente.cor}</b>
                            </Title>
                        </Box>
                        <Box sx={{ width: '25%', display: 'flex', flexDirection: 'column', fontSize: '1em', gap: '.2em' }}>
                            <Title>
                                NATURALIDADE: <b>{paciente.naturalidade}</b>
                            </Title>
                            <Title>
                                CNS: <b>{paciente.cartao_sus}</b>
                            </Title>
                            <Title>
                                CPF: <b>{paciente.cpf}</b>
                            </Title>
                            <Title>
                                RG: <b>{paciente.rg}</b>
                            </Title>
                        </Box>
                        <Box sx={{ width: '25%', display: 'flex', flexDirection: 'column', fontSize: '1em', gap: '.2em' }}>
                            <Title>
                                ENDEREÇO: <b>{paciente.endereco}</b>
                            </Title>
                            <Title>
                                BAIRRO: <b>{paciente.bairro}</b>
                            </Title>
                            <Title>
                                CIDADE: <b>{paciente.cidade}</b>
                            </Title>
                            <Title>
                                CEP: <b>{paciente.cep}</b>
                            </Title>
                            <Title>
                                TELEFONE: <b>{paciente.telefone}</b>
                            </Title>
                        </Box>
                        <Box
                            sx={{
                                width: '25%',
                                display: 'flex',
                                flexDirection: 'column',
                                fontSize: '1em',
                                gap: '.2em'
                            }}
                        >
                            <Title>
                                NÚMERO: <b>{paciente.numero}</b>
                            </Title>
                            <Title>
                                ESTADO: <b>{paciente.estado}</b>
                            </Title>
                            <Title>
                                TELEFONE: <b>{paciente.telefone}</b>
                            </Title>
                            <Title>
                                NOME MÃE: <b>{paciente.nome_mae}</b>
                            </Title>
                            <Title>
                                IDADE: <b>{idade}</b>
                            </Title>
                        </Box>
                    </HeaderBox>
                    {/* TRIAGEM HEADER */}
                    <HeaderTriagem>
                        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', marginTop: '.4em' }}>
                            <Title>1 - CLASSIFICAÇÃO DE RISCO</Title>
                        </Box>
                        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-start', marginTop: '.4em' }}>
                            <Title>QUEIXA E DURAÇÃO:</Title>
                            <Title>
                                _______________________________________________________________________________________________________________________________________
                            </Title>
                        </Box>
                        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                            <Title>
                                <p>
                                    <b>DE AMBULA:</b> <b>[</b> <b style={{ marginLeft: '.5em' }}>]</b> SIM <b>[</b>{' '}
                                    <b style={{ marginLeft: '.5em' }}>]</b> NÃO
                                </p>
                            </Title>
                            <Title>
                                <p>
                                    <b>CONSCIENTE:</b> <b>[</b> <b style={{ marginLeft: '.5em' }}>]</b> SIM <b>[</b>{' '}
                                    <b style={{ marginLeft: '.5em' }}>]</b> NÃO
                                </p>
                            </Title>
                            <Title>
                                <p>
                                    <b>SANGRA:</b> <b>[</b> <b style={{ marginLeft: '.5em' }}>]</b> SIM <b>[</b>{' '}
                                    <b style={{ marginLeft: '.5em' }}>]</b> NÃO
                                </p>
                            </Title>
                            <Title>
                                <p>
                                    <b>DESC.RESP:</b> <b>[</b> <b style={{ marginLeft: '.5em' }}>]</b> SIM <b>[</b>{' '}
                                    <b style={{ marginLeft: '.5em' }}>]</b> NÃO
                                </p>
                            </Title>
                        </Box>
                    </HeaderTriagem>
                    {/* CONTEÚDO TRIAGEM */}
                    <ContentTriagem>
                        <Box
                            sx={{
                                width: '25%',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start'
                            }}
                        >
                            <Title>2 - SINAIS VITAIS</Title>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '.4em' }}>
                                <Title>
                                    PA: <b>_____________</b>
                                </Title>
                                <Title>
                                    X: <b>_____________</b>
                                </Title>
                            </Box>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '.4em' }}>
                                <Title>
                                    FC: <b>_____________</b>
                                </Title>
                                <Title>
                                    FR: <b>____________</b>
                                </Title>
                            </Box>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '.4em' }}>
                                <Title>
                                    TEMP: <b>__________________________</b>
                                </Title>
                            </Box>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '.4em' }}>
                                <Title>
                                    DEXTRO: <b>________________________</b>
                                </Title>
                            </Box>
                        </Box>
                        <Box
                            sx={{
                                width: '25%',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                                borderRight: '1px solid #000'
                            }}
                        >
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '.4em' }}>
                                <Title>
                                    DOR - GRAU (0-10): <b>_____________</b>
                                </Title>
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'column', marginTop: '.4em' }}>
                                <Title>MEDICAMENTOS EM USO:</Title>
                                <Title sx={{ lineHeight: '1.45em' }}>
                                    <b>___________________________________</b>
                                </Title>
                                <Title sx={{ lineHeight: '1.45em' }}>
                                    <b>___________________________________</b>
                                </Title>
                                <Title sx={{ lineHeight: '1.45em' }}>
                                    <b>___________________________________</b>
                                </Title>
                                <Title sx={{ lineHeight: '1.45em' }}>
                                    <b>___________________________________</b>
                                </Title>
                            </Box>
                        </Box>
                        <Box
                            sx={{
                                width: '25%',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                                marginLeft: '.5em'
                            }}
                        >
                            <Typography sx={{ fontSize: '10px' }}>3 - DADOS COMPLEMENTARES</Typography>
                            <Box sx={{ display: 'flex', flexDirection: 'column', marginTop: '.4em' }}>
                                <Title>
                                    HAS: <b>__________________________</b>
                                    <b>[</b>
                                    <b style={{ marginLeft: '.7em' }}>]</b>
                                </Title>
                                <Title>
                                    DM: <b>___________________________</b>
                                    <b>[</b>
                                    <b style={{ marginLeft: '.7em' }}>]</b>
                                </Title>
                            </Box>

                            <Box sx={{ display: 'flex', flexDirection: 'column', marginTop: '.4em' }}>
                                <Title>
                                    DPOC:<b>_________________________</b>
                                    <b>[</b>
                                    <b style={{ marginLeft: '.7em' }}>]</b>
                                </Title>
                                <Title>
                                    LITÍASE URINÁRIA: <b>____________</b>
                                    <b>[</b>
                                    <b style={{ marginLeft: '.7em' }}>]</b>
                                </Title>
                            </Box>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '.4em' }}>
                                <Title sx={{ lineHeight: '1.45em' }}>
                                    OUTROS: <b>________________________</b>
                                </Title>
                            </Box>
                        </Box>
                        <Box
                            sx={{
                                width: '25%',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center'
                            }}
                        >
                            <Box sx={{ display: 'flex', flexDirection: 'column', marginTop: '.4em' }}>
                                <Title sx={{ marginBottom: '.5em' }}>ENFERMEIRO COREN:</Title>
                                <Title>
                                    <b>_______________________</b>
                                </Title>
                            </Box>

                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '.4em' }}>
                                <Title>HORÁRIO:</Title>
                                <Title sx={{ marginTop: '1em' }}>
                                    <b>_____</b> : <b>_____</b>
                                </Title>
                            </Box>
                        </Box>
                    </ContentTriagem>
                    {/* CLASIFICAÇÃO */}
                    <Classificacao>
                        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', marginTop: '.4em' }}>
                            <Title>4 - CLASIFICAÇÃO</Title>
                        </Box>
                        <Box
                            sx={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'space-between',
                                marginTop: '.4em',
                                marginBottom: '.6em'
                            }}
                        >
                            <Box>
                                <Title>NÍVEIS:</Title>
                            </Box>
                            <Box>
                                <Title>
                                    VERMELHO: <b>[</b> <b style={{ marginLeft: '1em' }}>]</b>
                                </Title>
                            </Box>
                            <Box>
                                <Title>
                                    AMARELO: <b>[</b> <b style={{ marginLeft: '1em' }}>]</b>
                                </Title>
                            </Box>
                            <Box>
                                <Title>
                                    VERDE: <b>[</b> <b style={{ marginLeft: '1em' }}>]</b>
                                </Title>
                            </Box>
                            <Box>
                                <Title>
                                    AZUL: <b>[</b> <b style={{ marginLeft: '1em' }}>]</b>
                                </Title>
                            </Box>
                        </Box>
                        <Box
                            sx={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'flex-start',
                                marginTop: '.4em',
                                marginBottom: '.6em'
                            }}
                        >
                            <Title>PACIENTE PASSOU ANTES NO POSTO DE SAÚDE? </Title>
                            <Box sx={{ marginLeft: '2em', display: 'flex' }}>
                                <Title sx={{ marginRight: '3em' }}>
                                    SIM: <b>[</b> <b style={{ marginLeft: '1em' }}>]</b>
                                </Title>
                                <Title>
                                    NÃO: <b>[</b> <b style={{ marginLeft: '1em' }}>]</b>
                                </Title>
                            </Box>
                        </Box>
                    </Classificacao>
                    {/* PRINCIPAIS DADOS */}
                    <PrincipaisDados>
                        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', marginTop: '.4em' }}>
                            <Title>5 - PRINCIPAIS DADOS DE ANAMNESE E EXAME FÍSICO</Title>
                        </Box>
                        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', marginTop: '.4em' }}>
                            <b>
                                ____________________________________________________________________________________________________________________________________________________________________________
                            </b>
                            <b>
                                ____________________________________________________________________________________________________________________________________________________________________________
                            </b>
                            <b>
                                ____________________________________________________________________________________________________________________________________________________________________________
                            </b>
                            <b>
                                ____________________________________________________________________________________________________________________________________________________________________________
                            </b>
                        </Box>
                    </PrincipaisDados>
                    {/* EXAMES E PRESCRICAO */}
                    <ExamesPrescricao>
                        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', marginTop: '.4em' }}>
                            <Title>6 - EXAMES COMPLEMENTARES E SUBSIDIÁRIOS</Title>
                        </Box>
                        <Box sx={{ display: 'flex', width: '100%' }}>
                            <Box
                                sx={{
                                    width: '100%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    marginTop: '.4em',
                                    borderRight: '1px solid #000'
                                }}
                            >
                                <Title sx={{ lineHeight: '1.45em' }}>
                                    ECG: <b>_____________________________________________________________________________________</b>
                                </Title>
                                <Title sx={{ lineHeight: '1.45em' }}>
                                    <b>_____________________________________________________________________________________</b>
                                </Title>
                                <Title sx={{ lineHeight: '1.45em' }}>
                                    IMAGEM: <b>___________________________________________________________________________</b>
                                </Title>
                                <Title sx={{ lineHeight: '1.45em' }}>
                                    <b>_____________________________________________________________________________________</b>
                                </Title>
                                <Title sx={{ lineHeight: '1.45em' }}>
                                    OUTROS: <b>___________________________________________________________________________</b>
                                </Title>
                                <Title sx={{ lineHeight: '1.45em' }}>
                                    <b>_____________________________________________________________________________________</b>
                                </Title>
                                <Title>7 - DIAGNÓSTICOS</Title>
                                <Title>
                                    A - PRINCIPAL <b sx={{ marginLeft: 'auto' }}>CID:</b>
                                    <b>___________________________________</b>
                                </Title>
                                <Title>
                                    B - SECUNDÁRIO <b sx={{ marginLeft: 'auto' }}>CID:</b>
                                    <b>_________________________________</b>
                                </Title>
                            </Box>
                            <Box
                                sx={{
                                    width: '100%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    marginTop: '.4em',
                                    marginLeft: '.4em'
                                }}
                            >
                                <Title sx={{ lineHeight: '.5em' }}>PRESCRIÇÃO MÉDICA E ANOTAÇÕES DE ENFERMAGEM: </Title>
                                <Title sx={{ lineHeight: '1.5em' }}>
                                    _____________________________________________________________________________
                                </Title>
                                <Title sx={{ lineHeight: '1.5em' }}>
                                    _____________________________________________________________________________
                                </Title>
                                <Title sx={{ lineHeight: '1.5em' }}>
                                    _____________________________________________________________________________
                                </Title>
                                <Title sx={{ lineHeight: '1.5em' }}>
                                    _____________________________________________________________________________
                                </Title>
                                <Title sx={{ lineHeight: '1.5em' }}>
                                    _____________________________________________________________________________
                                </Title>
                                <Title sx={{ lineHeight: '1.5em' }}>
                                    _____________________________________________________________________________
                                </Title>
                                <Title sx={{ lineHeight: '1.5em' }}>
                                    _____________________________________________________________________________
                                </Title>
                                <Title sx={{ lineHeight: '1.5em' }}>
                                    _____________________________________________________________________________
                                </Title>
                                <Title sx={{ lineHeight: '1.5em' }}>
                                    _____________________________________________________________________________
                                </Title>
                                <Title sx={{ lineHeight: '1.5em' }}>
                                    _____________________________________________________________________________
                                </Title>
                            </Box>
                        </Box>
                    </ExamesPrescricao>
                    {/* DESTINO */}
                    <Destino>
                        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', padding: '1em' }}>
                            <Title>9 - DESTINO</Title>
                            <Title>
                                <b>ALTA</b> <b>[</b> <b style={{ marginLeft: '.5em' }}>]</b>
                            </Title>
                            <Title>
                                <b>UBS</b> <b>[</b> <b style={{ marginLeft: '.5em' }}>]</b>
                            </Title>
                            <Title>
                                <b>OBSERVAÇÃO</b> <b>[</b> <b style={{ marginLeft: '.5em' }}>]</b>
                            </Title>
                            <Title>
                                <b>INTERNAÇÃO</b> <b>[</b> <b style={{ marginLeft: '.5em' }}>]</b>
                            </Title>
                            <Title>
                                <b>ÓBITO</b> <b>[</b> <b style={{ marginLeft: '.5em' }}>]</b>
                            </Title>
                        </Box>
                    </Destino>
                    {/* ASSINATURA */}
                    <Assinatura>
                        <Box
                            sx={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'space-between',
                                padding: '1em'
                            }}
                        >
                            <Box sx={{ display: 'flex', flexDirection: 'column', marginRight: 'auto' }}>
                                <Typography sx={{ fontSize: '10px', marginBottom: '2em' }}>
                                    10 - <b>ASSINATURA DO PACIENTE OU RESPONSÁVEL</b>
                                </Typography>
                                <Typography>
                                    <b>________________________________</b>
                                </Typography>
                            </Box>
                            <Box>
                                <Typography sx={{ fontSize: '10px', marginBottom: '2em' }}>
                                    10 - <b>CARIMBO E ASSINATURA DO MÉDICO</b>
                                </Typography>
                                <Typography>
                                    <b>________________________________</b>
                                </Typography>
                            </Box>
                        </Box>
                    </Assinatura>
                </>
            )}
        </>
    );
};

export default Entrada;
